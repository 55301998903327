export default {
    data() {
        return {
            nl: {
                header_text1: "Voorbij het stuur: ",
                header_text2: "Onze chauffeurs, de ambassadeurs van uw merk.",
                header_text3: "Ze belichamen uw waarden op elke weg, zorgen voor een veilige aankomst. Met onberispelijke service garanderen ze tevredenheid en vertrouwen bij elke reis.",
                header_text4: "Betrouwbaarheid in de farmaceutische sector:",
                header_text5: "Gecertificeerde excellentie, ISO 9001 voor nauwkeurige leveringen.",
                header_text6: "Onze beheersing van Belgische gezondheidsnetwerken zorgt voor vlekkeloze distributie, van de farmaceutische sector tot ziekenhuizen, waardoor de standaard van vertrouwen wordt verhoogd.",
                header_text7: "Van Hand tot Hand:",
                header_text8: "Absolute garantie van stiptheid en integriteit.",
                header_text9: "Uw zendingen, kostbaar en breekbaar, worden met de grootste zorg behandeld, wat zorgt voor een perfecte aankomst elke keer.",
                action1_title: "STUUR EEN VERZENDING",
                action2_title: "VRAAG EEN OFFERTE AAN",
                action3_title: "WORD EEN PARTNER",
                why_question1: "Waarom",
                why_question2: "kiezen voor CIT Express?",
                section1_text1: "CIT Express maakt van uw verwachtingen werkelijkheid door haar oplossingen aan te passen aan uw eisen",
                solution1_title: "Leveringssectoren",
                solution1_contenu: "Ontdek onze leveringsdiensten op maat van 14 sectoren, die een breed scala bestrijken van het medische veld tot de luchtvaartindustrie",
                solution2_title: "Onze Vloot",
                solution2_contenu: "Ontdek onze diverse vloot voertuigen, die zorgen voor een betrouwbare en snelle leveringsdienst om aan alle behoeften te voldoen.",
                solution3_title: "Technologie",
                solution3_contenu: "Wij combineren operationele uitmuntendheid met geavanceerde technologieën om een dienst te leveren die de verwachtingen van onze klanten overtreft",
                solution4_title: "ISO 9001-2018",
                solution4_contenu: "Bij CIT Express waarborgt onze naleving van ISO-normen een ongeëvenaarde kwaliteit en efficiëntie, wat zorgt voor totale klanttevredenheid",
                see_more: "Bekijk meer",
                citexpress_name: "CIT EXPRESS",
                citexpress_name_description: "toonaangevend in België sinds 1995",
                citexpress_presentation: "CIT Express, toonaangevend in België sinds 1995, biedt op maat gemaakte logistieke oplossingen om aan uw verzendbehoeften te voldoen, of u nu een individu bent, een e-commerce bedrijf, een klein bedrijf of een multinational...",
                satisfied_clients: "Duizenden tevreden klanten",
                satisfied_clients_description: "Velen van hen, we hebben hen gevraagd hoe tevreden ze waren met onze diensten. Hier zijn hun uitspraken.",
                give_review: "GEEF EEN BEOORDELING",
                user_name1: "Marc B. B2B",
                user_review1: "Vroeger was het volgen van zendingen ingewikkeld met slechts één vervoerder. Vandaag, dankzij CIT Express die al onze vervoerders beheert, verloopt alles soepel en efficiënt.",
                user_name2: "Amina R. B2B",
                user_review2: "Een vervoerder vinden die aan onze eisen voldoet is bijna onmogelijk, CIT Express is daarin geslaagd.",
                user_name3: "Alan D. C2C",
                user_review3: "In een delicate situatie zorgde CIT Express voor snelle en veilige bezorging van belangrijke documenten, waardoor ik werd gered. Een professionele service die ik aanbeveel.",
                user_name4: "Jean-Michel L. B2B",
                user_review4: "Samenwerken met verschillende vervoerders houdt in dat er veel contacten en verschillende facturen zijn. Met CIT Express besparen we waardevolle tijd door deze processen te consolideren.",

                // Navbar
                home: "Home",
                services: "Diensten",
                track_shipment: "Zending Volgen",
                contact_us: "Neem Contact Op",
                my_cit: "Mijn CIT",

                login_text1: "Klaar om te verzenden?",
                login_text2: "Meld u nu aan of registreer",
                form_header: "Aanmelden:",
                email_placeholder: "E-mailadres",
                password_placeholder: "Wachtwoord",
                login: "INLOGGEN",
                not_yet_subscribed: "Nog niet geabonneerd?",
                subscribe: "REGISTREER",

                // Onze Vloot
                notre_flotte_header: "Onze Vloot: Divers, Modern en Ecologisch",
                notre_flotte_desc: "Bij CIT Express is onze vloot van voertuigen het hart van onze mogelijkheid om snelle en betrouwbare bezorgdiensten te bieden. We begrijpen dat elke bezorging uniek is, daarom bestaat onze vloot uit een verscheidenheid aan voertuigen die zijn afgestemd op alle behoeften.",

                notre_flotte_title1: "Elektrische Voertuigen",
                notre_flotte_title1_soustitle1: "Elektrische Scooters",
                notre_flotte_title1_soustitle1_desc: "Perfect voor snelle navigatie in stedelijke gebieden, zijn onze elektrische scooters zowel milieuvriendelijk als efficiënt.",
                notre_flotte_title1_soustitle2: "Elektrische Fietsen",
                notre_flotte_title1_soustitle2_desc: "Ideaal voor lokale bezorgingen, combineren ze de wendbaarheid van traditionele fietsen met ondersteunde kracht, waardoor snelle emissievrije bezorgingen mogelijk zijn.",
                notre_flotte_title2: "Motorvoertuigen",
                notre_flotte_title2_soustitle1: "Bestelwagens",
                notre_flotte_title2_soustitle1_desc: "Onze bestelwagens zijn ideaal voor grotere of meerdere bezorgingen, ze bieden voldoende ruimte en zijn toch wendbaar in de stad.",
                notre_flotte_title2_soustitle2: "Bezorgtrucks",
                notre_flotte_title2_soustitle2_desc: "Voor grotere ladingen of gespecialiseerde bezorgingen zijn onze trucks uitgerust om een breed scala aan goederen veilig te transporteren.",

                notre_flotte_title3: "Gespecialiseerde Voertuigen",
                notre_flotte_title3_soustitle1: "Koelwagens",
                notre_flotte_title3_soustitle1_desc: "Deze voertuigen zijn essentieel voor het transport van producten die temperatuurcontrole vereisen, zoals bepaalde medicijnen of gevoelige technologische apparatuur.",
                notre_flotte_title3_soustitle2: "Voertuigen voor Hoog Beveiligde Bezorging",
                notre_flotte_title3_soustitle2_desc: "Gebruikt voor het transport van documenten of waardevolle voorwerpen die verhoogde beveiliging vereisen, zijn deze voertuigen uitgerust met geavanceerde beveiligingssystemen.",

                notre_flotte_title4: "Toewijding aan Duurzaamheid: Overgang naar Elektrisch",
                notre_flotte_title4_desc1: "We zijn toegewijd aan een geleidelijke overgang naar een volledig elektrische vloot, waardoor onze ecologische voetafdruk wordt verkleind en bijgedragen wordt aan een duurzamere toekomst.",
                notre_flotte_title4_desc2: "Onze vloot wordt regelmatig onderhouden en bijgewerkt om de beste prestaties en betrouwbaarheid te garanderen. Bij CIT Express zijn we trots op onze mogelijkheid om aan alle bezorgbehoeften te voldoen terwijl we ons blijven inzetten voor milieuverantwoordelijkheid en innovatie.",

                about_us_header: "OVER ONS",
                about_us_sheader: "Pioniers van Innovatieve Stedelijke Bezorging",
                about_us_section1_title: "Oorsprong in Brussel, 1996",
                about_us_section1_contenu: "Het avontuur van CIT Express begon in 1996 in Brussel, een stad waar innovatie samensmelt met een rijke culturele erfgoed. Geconfronteerd met stedelijke logistieke uitdagingen, zoals files en demonstraties, hadden we een revolutionaire visie: het transformeren van stedelijke bezorging.",
                inovation: "Innovatie:",
                inovation_desc: "Verder dan Scooter- en Fietsbezorging",
                inovation_text: "Ons oorspronkelijke concept onderscheidde zich al snel door het gebruik van scooters en fietsen voor snelle en efficiënte bezorgingen. Maar onze echte doorbraak was onze baanbrekende adoptie van innovatieve technologieën.",
                about_us_services_title: "Pioniers in Communicatietechnologie",
                about_us_service1_title: "Radio Communicatie",
                about_us_service1_contenu: "We waren een van de eersten die radiocommunicatie gebruikten om onze bezorgingen te coördineren, waardoor onze operationele efficiëntie werd verhoogd.",
                about_us_service2_title: "Introductie van SMS",
                about_us_service2_contenu: "Door de vroege adoptie van SMS konden we snellere en directere communicatie met onze teams op het veld mogelijk maken.",
                about_us_service3_title: "GPS Pioniers",
                about_us_service3_contenu: "De integratie van GPS heeft onze mogelijkheid om bezorgingen in realtime te volgen, revolutionair verbeterd, met ongekende transparantie en betrouwbaarheid.",
                inovation_numerique: "Digitale Innovatie",
                inovation_numerique1_title: "1e Online Bestelsite",
                inovation_numerique1_contenu: "We lanceerden een van de eerste online bezorgbestelsites, waardoor onze klanten gebruiksgemak en verhoogde efficiëntie kregen.",
                inovation_numerique2_title: "Online Volgen en Facturering",
                inovation_numerique2_contenu: "Door online bestelvolging en elektronische facturering in te voeren, hebben we niet alleen het proces voor onze klanten vereenvoudigd, maar ook onze ecologische voetafdruk verkleind.",
                leadership_title: "Een Erfgoed van Leiderschap en Innovatie",
                leadership_text1: "Deze innovaties hebben niet alleen CIT Express gevestigd als een leider in stedelijke bezorging, maar hebben ook de weg geëffend voor technologieën die veel grote spelers in de branche vandaag de dag gebruiken. We zijn er trots op pioniers te zijn geweest, altijd op zoek naar nieuwe manieren om onze diensten te verbeteren en te anticiperen op de behoeften van onze klanten.",
                leadership_text2: "Bij CIT Express is onze geschiedenis er een van constante innovatie en toewijding aan uitmuntendheid. We blijven evolueren, altijd op zoek naar nieuwe manieren om onze klanten efficiënt en betrouwbaar van dienst te zijn.",
                contactus_paragraphe1: "Laat ons u laten zien hoe CIT Express efficiënt en betrouwbaar aan uw stedelijke bezorgvereisten kan voldoen.",
                contactus_paragraphe2: "Neem vandaag nog contact met ons op voor een uitzonderlijke samenwerking.",
                contacus_button: "Neem Contact Op",

                iso_title: "ISO 9001-2018",
                iso_title_s1: "Kwaliteit",
                iso_title_s2: "Management",
                iso_title_s3: "Systemen",
                norme_iso: "ISO-normen:",
                norme_iso_desc: "De Basis van Onze Uitmuntendheid in Gespecialiseerde Bezorging",
                iso_icon_title: "Toewijding aan ISO Kwaliteitsnormen",
                iso_icon_desc: "Bij CIT Express staat naleving van ISO-normen centraal in onze toewijding aan ongeëvenaarde kwaliteit en efficiëntie. Onze processen zijn rigoureus ontworpen om te voldoen aan en deze internationale normen te overtreffen, wat totale klanttevredenheid garandeert.",
                iso_app_title: "Toepassing van ISO-normen in Onze Gespecialiseerde Diensten",
                iso_app1_title: "ISO 9001 voor Kwaliteitsmanagement",
                iso_app1_contenu: "Al onze processen, van operationeel management tot bezorgdiensten, voldoen aan ISO 9001, wat een consistente en betrouwbare kwaliteit garandeert.",
                iso_app2_title: "ISO 14001 voor Milieu",
                iso_app2_contenu: "We zijn toegewijd aan het minimaliseren van onze ecologische impact door praktijken die voldoen aan ISO 14001, waarbij onze ecologische verantwoordelijkheid wordt benadrukt.",
                iso_app3_title: "ISO 45001 voor Gezondheid en Veiligheid",
                iso_app3_contenu: "De veiligheid en het welzijn van ons team en onze klanten blijven een topprioriteit, voortdurend geleid door ISO 45001-normen en de inzet van allen.",
                iso_norme_title: "Sector Specialisatie en Gerelateerde Normen",
                iso_norme1_title: "Farmaceutische Sector",
                iso_norme1_contenu: "We houden ons aan strenge normen voor het hanteren en transporteren van farmaceutische producten, waaronder Good Distribution Practices (GDP) en specifieke richtlijnen voor de koudeketen.",
                iso_norme2_title: "Elektronica- en Technologiesector",
                iso_norme2_contenu: "Voor het transport van gevoelige elektronische producten passen we specifieke normen toe die bescherming bieden tegen elektrostatische ontlading en mechanische schade.",
                iso_norme3_title: "Juridische en Financiële Sector",
                iso_norme3_contenu: "We houden ons aan strikte privacy- en beveiligingsprotocollen om de veiligheid en vertrouwelijkheid van gevoelige documenten te waarborgen.",
                iso_norme4_title: "Ruimtevaartsector",
                iso_norme4_contenu: "De specifieke eisen van deze sector worden vervuld, inclusief het transport van kritische materialen en componenten, in overeenstemming met de kwaliteits- en veiligheidsnormen voor de lucht- en ruimtevaart.",
                formation_continue_title: "Continue Training en Bewustwording",
                formation_continue_desc: "Ons personeel ontvangt voortdurende training over ISO-normen en sector-specifieke eisen. Deze aanpak zorgt voor foutloze expertise en naleving in al onze activiteiten.",
                audit_amelioration_title: "Audit en Continue Verbetering",
                audit_amelioration_desc: "Regelmatige audits worden uitgevoerd om de naleving van ISO-normen te verifiëren en verbeteringsmogelijkheden te identificeren. Deze continue verbeteringsbenadering versterkt onze toewijding aan uitmuntendheid.",
                iso_certif_2018: "Door deze normen te integreren in ons operationele model, onderscheidt CIT Express zich als een betrouwbare en gespecialiseerde bezorgdienstverlener, toegewijd aan de hoogste normen voor elk van de sectoren die we bedienen.",

                secteurs_header: "Bezorgsectoren",
                secteurs_header_desc1: "Ontdek onze bezorgdiensten op maat voor 14 sectoren, van medisch tot lucht- en ruimtevaart.",
                secteurs_header_desc2: "Kies uw domein uit het dropdownmenu en ontdek hoe onze expertise veiligheid en snelheid garandeert voor uw zendingen.",
                secteurs_sheader: "CIT Express, 30 jaar ervaring, uw betrouwbare partner voor maatwerk bezorgingen!",
                secteur1_title: "Medische en Farmaceutische Sector",
                secteur1_contenu: "Dringende leveringen van medicijnen, vaccins, biologische monsters, medische apparatuur. Temperatuurgecontroleerd transport voor gevoelige items.",
                secteur2_title: "Technologie en Elektronica",
                secteur2_contenu: "Levering van gevoelige elektronische componenten, computerapparatuur. Noodzaak voor zorgvuldige behandeling en bescherming tegen schokken.",
                secteur3_title: "Detailhandel en E-commerce",
                secteur3_contenu: "Optimaliseer uw online bedrijf met snelle levering, efficiënt retourbeheer en voorraadsynchronisatie via API's. Zorg voor responsieve klantenservice.",
                secteur4_title: "Automobielindustrie",
                secteur4_contenu: "Dringend transport van reserveonderdelen, componenten voor productie. Just-in-time leveringen om productiecontinuïteit te handhaven.",
                secteur5_title: "Onderzoek en Ontwikkeling",
                secteur5_contenu: "Service zorgt voor veilig transport van uw onderzoeksmateriaal, wetenschappelijke monsters en prototypes, met bijzondere aandacht voor nauwkeurige temperatuurregeling.",
                secteur6_title: "Advies en Training",
                secteur6_contenu: "Snelle levering van belangrijke documenten, presentatie- en trainingsmateriaal, apparatuur voor audits of evaluaties. Belang van snelheid en betrouwbaarheid.",

                sous_secteur1_title: "Voedingsmiddelenindustrie",
                sous_secteur1_contenu: "Transport van bederfelijke goederen, cateringbenodigdheden, dringende leveringen voor supermarkten. Temperatuur- en versheidseisen.",
                sous_secteur2_title: "Bouw- en Ingenieurswezen",
                sous_secteur2_contenu: "Voor het transport van gevoelige elektronische producten passen we specifieke normen toe die bescherming bieden tegen elektrostatische ontlading en mechanische schade.",
                sous_secteur3_title: "Juridisch en Financieel",
                sous_secteur3_contenu: "We houden ons aan strikte privacy- en beveiligingsprotocollen om de veiligheid en vertrouwelijkheid van gevoelige documenten te waarborgen.",
                sous_secteur4_title: "Lucht- en Ruimtevaartsector",
                sous_secteur4_contenu: "De specifieke eisen van deze sector worden vervuld, inclusief het transport van kritische materialen en componenten, in overeenstemming met lucht- en ruimtevaartkwaliteits- en veiligheidsnormen.",
                sous_secteur5_title: "Openbare en Overheidssector",
                sous_secteur5_contenu: "We volgen strikte normen voor het hanteren en transporteren van farmaceutische producten, inclusief Good Distribution Practices (GDP) en specifieke richtlijnen voor de koudeketen.",
                sous_secteur6_title: "Mode en Textiel",
                sous_secteur6_contenu: "Voor het transport van gevoelige elektronische producten passen we specifieke normen toe die bescherming bieden tegen elektrostatische ontlading en mechanische schade.",
                sous_secteur7_title: "Evenementen en Entertainment",
                sous_secteur7_contenu: "We houden ons aan strikte privacy- en beveiligingsprotocollen om de veiligheid en vertrouwelijkheid van gevoelige documenten te waarborgen.",
                sous_secteur8_title: "Kunst en Antiek",
                sous_secteur8_contenu: "De specifieke eisen van deze sector worden vervuld, inclusief het transport van kritische materialen en componenten, in overeenstemming met lucht- en ruimtevaartkwaliteits- en veiligheidsnormen.",

                technologie_header: "Technologie tot Uw Dienst voor een Naadloze, Gemakkelijke en Intuïtieve Ervaring",
                technologie_sheader: "Bij CIT Express combineren we operationele uitmuntendheid met geavanceerde technologieën om een bezorgdienst aan te bieden die niet alleen voldoet, maar de verwachtingen van onze klanten overtreft.",
                technologie1_title: "Extra Technologische Kenmerken",
                technologie2_title: "Toegankelijke en Transparante Bezorgtracking",
                technologie3_title: "Intuïtieve en Efficiënte Boekhouding",
                technologie_desc: "Technologische innovatie bij CIT Express is gewijd aan het continu verbeteren van de ervaring van onze klanten, waarbij logistieke oplossingen worden geboden die niet alleen geavanceerd zijn, maar ook perfect aansluiten bij hun operationele en financiële behoeften.",

                technologie1_tech1_title: "Vereenvoudigde Bestelinterface",
                technologie1_tech1_desc: "Ons online platform is ontworpen voor een intuïtieve gebruikerservaring, waardoor snelle en moeiteloze bestellingen mogelijk zijn.",
                technologie1_tech2_title: "Automatische Meldingen en Waarschuwingen",
                technologie1_tech2_desc: "Blijf op de hoogte in elke fase van uw bezorging met automatische meldingen en updates.",
                technologie1_tech3_title: "Integratie met Bedrijfssystemen",
                technologie1_tech3_desc: "Ons platform integreert naadloos met ERP- en CRM-systemen voor gecentraliseerd en efficiënt orderbeheer.",
                technologie1_tech4_title: "Veilige Betalingsopties",
                technologie1_tech4_desc: "We bieden verschillende veilige betalingsopties om aan de behoeften van onze klanten te voldoen.",
                technologie1_tech5_title: "24/7 Klantenondersteuning",
                technologie1_tech5_desc: "Ons klantenserviceteam is 24/7 beschikbaar om eventuele vragen te beantwoorden en problemen snel op te lossen.",
                technologie2_tech1_title: "Realtime Geolocatie",
                technologie2_tech1_desc: "Onze klanten en pakketontvangers kunnen de voortgang van hun zendingen in realtime volgen via ons geavanceerde geolocatiesysteem.",
                technologie2_tech2_title: "Interactief Trackingprogramma",
                technologie2_tech2_desc: "Ontvangers zijn geïntegreerd in ons trackingprogramma, waardoor ze gemakkelijk de status en locatie van hun zending kunnen controleren voor volledige transparantie en gemoedsrust.",
                technologie3_tech1_title: "Vereiste van Baan- of Inkoopordernummer",
                technologie3_tech1_desc: "Om het werk van uw boekhoudafdeling te stroomlijnen, vereist ons systeem invoer van een baan- of inkoopordernummer (PO) voordat een bestelling wordt geplaatst. Deze praktijk zorgt voor een nauwkeurige kostenallocatie en vereenvoudigd boekhoudbeheer.",
                technologie3_tech2_title: "Aanpasbare en Exporteerbare Boekhoudrapporten",
                technologie3_tech2_desc: "Onze klanten kunnen aangepaste boekhoudrapporten genereren en exporteren, waardoor naadloze integratie met hun eigen financiële systemen mogelijk is.",

                about_us: "Over Ons",
                terms_of_use: "Gebruiksvoorwaarden",
                privacy_policy: "Privacybeleid",
                accessibility_statement: "Toegankelijkheidsverklaring",
                all_rights_resereved: "Alle Rechten Voorbehouden",

                username: "Gebruikersnaam",

                suivant: "Volgende",
                nom_entreprise: "Bedrijfsnaam?",
                secteur_activite: "Bedrijfssector?",
                contact_principale: "Primaire contactpersoon (Naam en functie)?",
                address_email: "E-mailadres?",
                phone_number: "Telefoonnummer",
                repondez_ici: "Antwoord hier...",
                question1: "Wat is de frequentie van uw behoeften op het gebied van levering?",
                question1_choice1: "Dagelijks",
                question1_choice2: "Wekelijks",
                question1_choice3: "Maandelijks",
                question1_choice4: "Anders",
                question2: "Wat is het gemiddelde volume van uw zendingen? (Aantal pakketten of geschat gewicht per zending)",
                question3: "Wat zijn de belangrijkste bestemmingen van uw zendingen?",
                question3_choice1: "Nationaal",
                question3_choice2: "Internationaal",
                question3_choice3: "Beide",
                question4: "Welk type goederen verzendt u?",
                question4_choice1: "Documenten",
                question4_choice2: "Pakketten",
                question4_choice3: "Pallets",
                question4_choice4: "Speciale Goederen",
                question5: "Zijn er specifieke eisen voor uw goederen? (Breekbaar, temperatuurgecontroleerd, gevaarlijk, etc.)",
                question5_choice1: "Ja",
                question5_choice2: "Nee",
                question6: "Naar welke bezorgdiensten bent u op zoek?",
                question6_choice1: "Standaard Levering",
                question6_choice2: "Express",
                question6_choice3: "Zelfde Dag",
                question7: "Heeft u aanvullende diensten nodig? (Verzekering, real-time tracking, levering met handtekening, etc.)",
                question7_choice1: "Ja",
                question7_choice2: "Nee",
                question8: "Heeft u ooit gebruik gemaakt van express leveringsdiensten?",
                question8_choice1: "Ja",
                question8_choice2: "Nee",
                question9: "Wat zijn uw belangrijkste criteria bij het kiezen van een bezorgdienst? (Kosten, betrouwbaarheid, snelheid, klantenservice, etc.)",
                question10: "Heeft u specifieke verwachtingen of zorgen met betrekking tot de bezorgdienst?",
                question11: "Wat is uw geschatte budget voor bezorgdiensten?",
                question12: "Wat zijn uw voorkeuren voor facturering en betaling?",
                thanks_message1: "Bedankt voor het aanvragen van een offerte.",
                thanks_message2: "Een van onze branchedeskundigen verwerkt uw aanvraag. U wordt binnenkort gecontacteerd om uw bezorgbehoeften en onze oplossingen te bespreken.",

                contactus_header: "Neem contact op",
                contactus_sheader1: "Welkom bij CIT Express, experts in snelle levering",
                contactus_sheader2: "Hulp nodig? Neem contact met ons op!",
                contactus_form_input1: "Naam",
                contactus_form_input2: "Bedrijf",
                contactus_form_input3: "Telefoon",
                contactus_form_input4: "E-mail",
                contactus_form_input5: "Onderwerp",
                contactus_form_input6: "Bericht",
                contactus_form_button: "Verzenden",
                contactus_text1: "Dedicated telefoonnummer voor onmiddellijke hulp:",
                contactus_text2: "Ons team is beschikbaar",
                contactus_text3: "Om snel op uw dringende zorgen te reageren.",

                construction_text1: "Pagina in aanbouw",
                construction_text2: "Sorry, maar deze pagina is momenteel in aanbouw.",
                construction_text3: "Kom binnenkort terug om updates te zien.",
                nos_service: "Onze diensten",
                contactus_message: "Bedankt, we zullen binnenkort contact met u opnemen",


                secteur_medicale_title: "Medische en Farmaceutische Sector",
                secteur_medicale_sub_title: "Uw Betrouwbare Partner voor Dringende Leveringen",
                secteur_medicale_section1_title: "Gespecialiseerde Leveringsdiensten voor de Medische en Farmaceutische Sector",
                secteur_medicale_section1_contenu: "In de medische en farmaceutische sector zijn snelheid en betrouwbaarheid van leveringen cruciaal. Of u nu medicijnen, vaccins, biologische monsters of medische apparatuur moet vervoeren, wij zijn er om een leveringsdienst te bieden die is afgestemd op uw behoeften.",
                secteur_medicale_option1_title: "1- Levering van Medicijnen en Vaccins",
                secteur_medicale_option1_content: "Wij begrijpen het belang van medicijnen en vaccins voor de volksgezondheid. Onze leveringsdiensten zorgen ervoor dat uw farmaceutische producten snel en in perfecte staat op hun bestemming aankomen. Dankzij ons temperatuurgecontroleerd transportsysteem zorgen we ervoor dat uw medicijnen en vaccins tijdens het hele transport op de ideale temperatuur worden gehouden.",
                secteur_medicale_option2_title: "2- Vervoer van Biologische Monsters",
                secteur_medicale_option2_content: "Het vervoer van biologische monsters vereist speciale aandacht en strikte naleving van veiligheidsprotocollen. Ons team is opgeleid in de beste praktijken voor het hanteren en vervoeren van biologische monsters, waardoor hun integriteit en veiligheid gewaarborgd zijn. We bieden ook volledige traceerbaarheid van uw zendingen, zodat u extra gemoedsrust heeft.",
                secteur_medicale_option3_title: "3- Levering van Medische Apparatuur",
                secteur_medicale_option3_content: "Medische apparatuur is essentieel voor de gezondheidszorg. Wij bieden een snelle en veilige leveringsservice voor alle soorten medische apparatuur, van diagnostische apparaten tot chirurgische apparatuur. Onze voertuigen zijn uitgerust om artikelen van alle formaten te vervoeren, en we zetten ons in om uw apparatuur binnen de afgesproken tijd te leveren.",
                secteur_medicale_option4_title: "4- Temperatuurgecontroleerd Vervoer",
                secteur_medicale_option4_content: "Temperatuurgecontroleerd vervoer is essentieel voor gevoelige artikelen in de medische en farmaceutische sector. Onze voertuigen zijn uitgerust met koelsystemen en temperatuurvolgsystemen, waardoor uw producten tijdens de reis op de optimale temperatuur worden gehouden. Of het nu gaat om gekoelde, bevroren of op kamertemperatuur gecontroleerde producten, wij hebben de oplossing die aan uw behoeften voldoet.",
                secteur_medicale_option5_title: "5- Betrokkenheid bij Kwaliteit en Veiligheid",
                secteur_medicale_option5_content: "Wij zetten ons in om de hoogste kwaliteit leveringsservice te bieden voor de medische en farmaceutische sector. Ons team van gekwalificeerde professionals zorgt ervoor dat elke zending met de grootste zorg wordt behandeld en voldoet aan de strengste veiligheidsnormen. We werken nauw samen met onze klanten om hun specifieke behoeften te begrijpen en op maat gemaakte leveringsoplossingen te bieden.",
                secteur_medicale_option6_title: "6- Real-Time Volgen en Traceerbaarheid",
                secteur_medicale_option6_content: "Met ons geavanceerde real-time volgsysteem kunt u uw zendingen in elke fase van het transport volgen. U ontvangt regelmatige updates over de status van uw levering, waardoor u volledige zichtbaarheid en gemoedsrust heeft.",
                secteur_medicale_section3: "Om meer te weten te komen over onze leveringsdiensten voor de medische en farmaceutische sector, of om uw specifieke behoeften te bespreken, neem gerust contact met ons op. Wij zijn er om betrouwbare, snelle en veilige leveringsoplossingen te bieden die zijn afgestemd op de eisen van de medische sector.",
                secteur_medicale_section4_title: "Lijst van Vervoerde Objecten en Materialen",
                secteur_medicale_section4_op1_title: "1. Medicijnen en Vaccins",
                secteur_medicale_section4_op1_point1: "Receptmedicijnen",
                secteur_medicale_section4_op1_point2: "Vrij verkrijgbare medicijnen",
                secteur_medicale_section4_op1_point3: "Vaccins",
                secteur_medicale_section4_op1_point4: "Serums en immunoglobulinen",
                secteur_medicale_section4_op1_point5: "Gekoelde medicijnen",
                secteur_medicale_section4_op1_point6: "Temperatuurgevoelige medicijnen",
                secteur_medicale_section4_op2_title: "2. Biologische Monsters",
                secteur_medicale_section4_op2_point1: "Bloedmonsters",
                secteur_medicale_section4_op2_point2: "Urinemonsters",
                secteur_medicale_section4_op2_point3: "Weefselmonsters",
                secteur_medicale_section4_op2_point4: "Bacteriële en virale culturen",
                secteur_medicale_section4_op2_point5: "DNA- en genetische monsters",
                secteur_medicale_section4_op2_point6: "Monsters voor microbiologische analyses",
                secteur_medicale_section4_op3_title: "3. Medische Apparatuur",
                secteur_medicale_section4_op3_point1: "Diagnostische apparaten (echografieapparaten, scanners, enz.)",
                secteur_medicale_section4_op3_point2: "Chirurgische apparatuur",
                secteur_medicale_section4_op3_point3: "Laboratoriumapparatuur",
                secteur_medicale_section4_op3_point4: "Wegwerp medische hulpmiddelen (handschoenen, spuiten, enz.)",
                secteur_medicale_section4_op3_point5: "Patiëntmonitors",
                secteur_medicale_section4_op3_point6: "Defibrillators",
                secteur_medicale_section4_op4_title: "4. Temperatuurgevoelige Producten",
                secteur_medicale_section4_op4_point1: "Gekoelde medicijnen",
                secteur_medicale_section4_op4_point2: "Biologische producten",
                secteur_medicale_section4_op4_point3: "Laboratoriumreagentia",
                secteur_medicale_section4_op4_point4: "Biologische medicijnen",
                secteur_medicale_section4_op4_point5: "Medische chemische stoffen",
                join_us_button: "Word Lid",
                secteur_medicale_join_us_text: "Word vandaag nog lid en ontdek hoe CIT Express uw behoeften kan vereenvoudigen door veilig en efficiënt transport van uw onderzoeksmaterialen te garanderen.",

                recherche_developpment_title: "Onderzoek en Ontwikkeling",
                recherche_developpment_sub_title: "Transportoplossingen voor uw Innovatieve Projecten",
                recherche_developpment_section1_title: "Leveringsdiensten voor Onderzoek en Ontwikkeling",
                recherche_developpment_section1_content: "Op het gebied van onderzoek en ontwikkeling is het veilig en betrouwbaar vervoeren van onderzoeksmateriaal, wetenschappelijke monsters en prototypes van cruciaal belang. Wij bieden gespecialiseerde leveringsdiensten om aan de unieke behoeften van uw innovatieve projecten te voldoen.",
                recherche_developpment_option1_title: "1- Transport van Onderzoeksmateriaal",
                recherche_developpment_option1_content: "Uw onderzoeksmateriaal is waardevol en gevoelig. Wij garanderen veilig en zorgvuldig transport voor uw onderzoeksinstrumenten en -apparatuur, zodat ze in perfecte staat en gebruiksklaar aankomen.",
                recherche_developpment_option2_title: "2- Levering van Wetenschappelijke Monsters",
                recherche_developpment_option2_content: "Wetenschappelijke monsters vereisen strikte controle over de transportomstandigheden. Onze temperatuurgecontroleerde leveringsdiensten houden uw monsters onder optimale omstandigheden, waardoor hun integriteit en levensvatbaarheid voor uw experimenten en analyses behouden blijven.",
                recherche_developpment_option3_title: "3- Transport van Prototypes",
                recherche_developpment_option3_content: "Het transport van prototypes vereist zorgvuldige behandeling en speciale aandacht. Wij bieden op maat gemaakte leveringsoplossingen voor uw prototypes, waarbij we een veilige en schadevrije aankomst garanderen.",
                recherche_developpment_option4_title: "4- Temperatuurgecontroleerd Transport",
                recherche_developpment_option4_content: "Voor de onderzoeks- en ontwikkelingssector is temperatuurgecontroleerd transport vaak noodzakelijk om de kwaliteit van gevoelige monsters en materialen te behouden. Onze voertuigen zijn uitgerust met geavanceerde koelings- en temperatuurbewakingssystemen, waardoor ideale transportomstandigheden worden gegarandeerd.",
                recherche_developpment_option5_title: "5- Real-Time Tracking en Traceerbaarheid",
                recherche_developpment_option5_content: "Ons systeem voor real-time tracking en traceerbaarheid stelt u in staat om uw zendingen in elke fase van het transport te volgen. U ontvangt real-time updates over de status van uw levering, waardoor volledige transparantie en gemoedsrust worden gegarandeerd.",
                recherche_developpment_option6_title: "6- Toewijding aan Kwaliteit en Veiligheid",
                recherche_developpment_option6_content: "Wij zetten ons in voor het leveren van hoogwaardige leveringsdiensten voor de onderzoeks- en ontwikkelingssector. Ons ervaren team zorgt ervoor dat elke zending met de grootste zorg wordt behandeld en voldoet aan de hoogste veiligheidsnormen.",
                recherche_developpment_section4_op1_title: "1. Onderzoeksmateriaal",
                recherche_developpment_section4_op1_point1: "Wetenschappelijke meetinstrumenten",
                recherche_developpment_section4_op1_point2: "Laboratoriumapparatuur",
                recherche_developpment_section4_op1_point3: "Gespecialiseerde computers en randapparatuur",
                recherche_developpment_section4_op1_point4: "Kalibratie-instrumenten",
                recherche_developpment_section4_op1_point5: "Onderzoek- en ontwikkelingkits",
                recherche_developpment_section4_op2_title: "2. Wetenschappelijke Monsters",
                recherche_developpment_section4_op2_point1: "Biologische monsters voor onderzoek",
                recherche_developpment_section4_op2_point2: "Chemische monsters",
                recherche_developpment_section4_op2_point3: "Materiaalmonsters",
                recherche_developpment_section4_op2_point4: "Milieu-monsters (grond, water, lucht)",
                recherche_developpment_section4_op2_point5: "Klinische monsters voor proeven",
                recherche_developpment_section4_op3_title: "3. Prototypes",
                recherche_developpment_section4_op3_point1: "Prototypes van medische apparatuur",
                recherche_developpment_section4_op3_point2: "Prototypes van diagnostische apparaten",
                recherche_developpment_section4_op3_point3: "Prototypes van technologische apparaten",
                recherche_developpment_section4_op3_point4: "Experimentele modellen",
                recherche_developpment_section4_op3_point5: "Elektronische componenten",
                recherche_developpment_section4_op4_title: "4. Temperatuurgevoelige Producten",
                recherche_developpment_section4_op4_point1: "Biologische monsters die koeling vereisen",
                recherche_developpment_section4_op4_point2: "Temperatuurgevoelige chemische reagentia",
                recherche_developpment_section4_op4_point3: "Cel- en weefselculturen",
                recherche_developpment_section4_op4_point4: "Biologische producten voor onderzoek",
                recherche_developpment_section4_op4_point5: "Kweekmedia en oplossingen",
                recherche_developpment_contact_us: "Om uw specifieke transportbehoeften voor onderzoek en ontwikkeling te bespreken, aarzel niet om contact met ons op te nemen. Wij staan klaar om betrouwbare, veilige leveringsoplossingen te bieden die zijn afgestemd op uw innovatieve projecten.",

                secteur_industrie_title: "Automotive Industrie Sector",
                secteur_industrie_sub_title: "Leveringsdiensten voor de Automotive Industrie",
                secteur_industrie_section1_title: "Spoedtransport van Reserveonderdelen",
                secteur_industrie_section2_title: "Levering van Componenten voor de Productie",
                secteur_industrie_section3_title: "Just-In-Time Leveringen voor de Automotive Industrie",
                secteur_industrie_section1_limit: "Transport van reserveonderdelen zoals motoren, transmissies, remsystemen, bumpers, koplampen en andere kritieke onderdelen.",
                secteur_industrie_section2_limit: "Transport van componenten zoals chassis, dashboards, stoelen, elektrische bedrading en elektronische modules.",
                secteur_industrie_section3_limit: "Levering van eindproducten, halffabricaten en grondstoffen die nodig zijn voor de autoproduktie.",
                secteur_industrie_option1_title: "1- Snelheid en Efficiëntie",
                secteur_industrie_option1_content: "Snelle levering van auto-onderdelen om stilstand te minimaliseren.",
                secteur_industrie_option2_title: "2- Veilige Transport",
                secteur_industrie_option2_content: "Gespecialiseerde handling en veilig transport om ervoor te zorgen dat auto-onderdelen in perfecte staat aankomen.",
                secteur_industrie_option3_title: "1- Snelheid en Efficiëntie",
                secteur_industrie_option3_content: "Tijdige levering van productiecomponenten voor auto's om ervoor te zorgen dat de productieprocessen soepel verlopen.",
                secteur_industrie_option4_title: "2- Coördinatie met Leveranciers",
                secteur_industrie_option4_content: "Coördinatie met leveranciers om ervoor te zorgen dat de componenten precies op het juiste moment worden geleverd, wat de efficiëntie van de toeleveringsketen van auto's verbetert.",
                secteur_industrie_option5_title: "1- Snelheid en Efficiëntie",
                secteur_industrie_option5_content: "Precieze timing van just-in-time leveringen om de continuïteit van de productie van auto's te waarborgen.",
                secteur_industrie_option6_title: "2- Real-Time Volgen van Leveringen",
                secteur_industrie_option6_content: "Real-time levering tracking en updates om ervoor te zorgen dat auto-onderdelen en componenten volgens het schema aankomen.",
                secteur_industrie_join_us: "Deze transport- en leveringsdiensten voor de automotive industrie zorgen ervoor dat de productieprocessen ononderbroken blijven, wat bijdraagt aan operationele efficiëntie en kostenbesparingen voor bedrijven in de automotive sector.",

                consultance_formation_title: "Consultancy en Training",
                consultance_formation_sub_title: "Snelle en Betrouwbare Logistieke Ondersteuning voor Uw Consultancy- en Trainingsactiviteiten",
                consultance_formation_section1_title: "Expresslevering voor Consultancy- en Trainingsprofessionals",
                consultance_formation_section1_contenu: "In de wereld van consultancy en training zijn snelheid en betrouwbaarheid van leveringen cruciaal voor het succes van uw missies. Wij zijn ons bewust van het belang van elk document, presentatiehulpmiddel en apparatuur voor audits of evaluaties, en daarom bieden wij snelle en veilige leveringsdiensten aan om aan uw professionele behoeften te voldoen.",
                consultance_formation_section2_title: "Onze diensten omvatten:",
                consultance_formation_option1_title: "1- Expresslevering van Belangrijke Documenten",
                consultance_formation_option1_content: "Of het nu gaat om contracten, auditrapporten of trainingsmateriaal, wij garanderen snelle en veilige levering zodat u geen moment verliest.",
                consultance_formation_option2_title: "2- Presentatie- en Trainingsmateriaal",
                consultance_formation_option2_content: "Snelle verzending van projectoren, laptops en andere apparatuur die nodig zijn voor uw trainings- of presentatiesessies.",
                consultance_formation_option3_title: "3- Apparatuur voor Audits en Evaluaties",
                consultance_formation_option3_content: "Betrouwbare levering van specifieke apparatuur voor uw auditmissies, waardoor u altijd het benodigde materiaal op tijd heeft.",
                consultance_formation_section3_title: "Waarom onze diensten kiezen?",
                consultance_formation_option4_title: "1- Snelheid en Efficiëntie",
                consultance_formation_option4_content: "Onze diensten zijn ontworpen om de kortst mogelijke levertijden te bieden, zodat u uw strakke planningen kunt nakomen.",
                consultance_formation_option5_title: "2- Betrouwbaarheid",
                consultance_formation_option5_content: "Met onze realtime tracking kunt u altijd weten waar uw zending zich bevindt en wanneer deze aankomt. Onze chauffeurs, ware ambassadeurs van onze service, zijn opgeleid om beleefd, netjes en vriendelijk te zijn, waardoor u bij elke levering een positieve ervaring heeft.",
                consultance_formation_option6_title: "3- Veldervaring",
                consultance_formation_option6_content: "Met vele jaren ervaring kennen onze chauffeurs de eisen van de consultancy- en trainingssector door en door. Hun expertise in het veld zorgt voor een vlekkeloze levering, zelfs in de meest complexe situaties.",
                consultance_formation_option7_title: "4- Toegewijde Klantenservice",
                consultance_formation_option7_content: "Ons team staat 24/7 voor u klaar om uw vragen te beantwoorden en eventuele problemen op te lossen.",
                consultance_formation_option8_title: "5- Traceerbaarheid en Veiligheid",
                consultance_formation_option8_content: "Elke levering wordt in realtime gevolgd, zodat u uw pakket vanaf het verzendpunt tot de eindbestemming kunt volgen.",
                consultance_formation_section4_title: "Geïntegreerd Platform om aan Al Uw Behoeften te Voldoen:",
                consultance_formation_section4_content: "We bieden een gebruiksvriendelijk en volledig platform dat aan al uw wensen voldoet, of het nu gaat om:",
                consultance_formation_box_option1_title: "Bestelplaatsen",
                consultance_formation_box_option1_contenu: "Vereenvoudigd proces voor het plaatsen van uw leveringsbestellingen, met aanpasbare opties afhankelijk van uw specifieke behoeften.",
                consultance_formation_box_option2_title: "Leveringstracking",
                consultance_formation_box_option2_contenu: "Realtime tracking van uw zendingen, met meldingen bij elke stap van het leveringsproces.",
                consultance_formation_box_option3_title: "Transparante Prijzen",
                consultance_formation_box_option3_contenu: "Kies tussen verschillende soorten diensten (economisch of snel) op basis van uw prioriteiten en budget.",
                consultance_formation_box_option4_title: "Gepersonaliseerde Facturering",
                consultance_formation_box_option4_contenu: "Voor afdelingen die bestelbonnen (PO) of jobnummers nodig hebben voor boekhoudkundige splitsing, is ons factureringssysteem ontworpen om zich aan te passen aan uw administratieve en financiële behoeften.",
                consultance_formation_join_us_text: "Vertrouw uw leveringen aan ons toe en concentreer u op wat het belangrijkst is: het succes van uw consultancy- en trainingsprojecten.",

                technologie_electronique_title: "Technologie en Elektronica",
                technologie_electronique_sub_title: "Levering van Gevoelige Elektronische Componenten en IT-apparatuur",
                technologie_electronique_section1_title: "Uw Betrouwbare Leveringspartner voor de Technologiesector Sinds 1996",
                technologie_electronique_section1_contenu: "Sinds 1996 zet Cit Express zich in om snelle, veilige en betrouwbare leveringsoplossingen te bieden voor de technologie- en elektronicasector. We begrijpen de unieke eisen van deze sector en zijn toegewijd aan het bieden van eersteklas leveringsdiensten voor uw elektronische componenten en IT-apparatuur.",
                technologie_electronique_section2_title: "Onze Leveringsdiensten voor de Technologie- en Elektronicasector",
                technologie_electronique_section2_title: "Waarom Kiezen voor CIT Express?",
                technologie_electronique_box_option1_title: "Voorzichtige Hantering",
                technologie_electronique_box_option1_contenu: "Bij Cit Express zorgen we voor een zorgvuldige behandeling van kwetsbare elektronische componenten en IT-apparatuur door een opgeleid team en speciaal ontworpen verpakkingen om ze tijdens het transport te beschermen.",
                technologie_electronique_box_option2_title: "Veilig Transport",
                technologie_electronique_box_option2_contenu: "De veiligheid van uw zendingen is onze prioriteit. Onze voertuigen met geavanceerde vering minimaliseren het risico op schade, en onze realtime tracking stelt u in staat elke stap van het transport te volgen.",
                technologie_electronique_box_option3_title: "Snelheid en Betrouwbaarheid",
                technologie_electronique_box_option3_contenu: "In de technologiesector is tijd cruciaal. Cit Express biedt expressleveringen, dezelfde dag of de volgende dag, om ervoor te zorgen dat uw apparatuur op tijd aankomt.",
                technologie_electronique_box_option4_title: "Gepersonaliseerde Diensten",
                technologie_electronique_box_option4_contenu: "Elke zending is uniek, daarom bieden wij gepersonaliseerde diensten. Of het nu gaat om regelmatige of eenmalige leveringen, ons team zal een op maat gemaakte leveringsoplossing voor uw bedrijf creëren.",
                technologie_electronique_option1_title: "1- Expertise",
                technologie_electronique_option1_content: "Een ervaren team, speciaal opgeleid voor het omgaan met gevoelige elektronische componenten.",
                technologie_electronique_option2_title: "2- Veiligheid",
                technologie_electronique_option2_content: "Gebruik van voertuigen en verpakkingen die speciaal zijn ontworpen om uw zendingen te beschermen.",
                technologie_electronique_option3_title: "3- Realtime Tracking",
                technologie_electronique_option3_content: "Volledige tracking van uw leveringen voor totale gemoedsrust.",
                technologie_electronique_option4_title: "4- Snelheid",
                technologie_electronique_option4_content: "Expressleveringsopties om te voldoen aan de dringende behoeften van uw bedrijf.",
                technologie_electronique_option5_title: "5- Flexibiliteit",
                technologie_electronique_option5_content: "Op maat gemaakte oplossingen aangepast aan de unieke eisen van uw bedrijf.",
                technologie_electronique_join_us_text1: "Voor meer informatie over onze leveringsdiensten in de technologie- en elektronicasector, of om een op maat gemaakte offerte aan te vragen, neem gerust contact met ons op. We zijn er om ervoor te zorgen dat uw elektronische componenten en IT-apparatuur veilig en op tijd op hun bestemming aankomen.",
                technologie_electronique_join_us_text2: "Neem vandaag nog contact met ons op en ontdek hoe Cit Express uw betrouwbare partner kan worden voor al uw technologie- en elektronicabehoeften op het gebied van leveringen.",

                commerce_title: "Detailhandel en E-commerce",
                commerce_section1_title: "Optimaliseer Uw Online Bedrijf met Cit Express",
                commerce_option1_title: "1- Snelle en Betaalbare Levering",
                commerce_option1_content: "Zorg ervoor dat uw klanten snelle en betaalbare levering krijgen om hun winkelervaring te verbeteren en de klanttevredenheid te verhogen. Onze leveringsoplossingen zijn ontworpen om efficiënt en kosteneffectief te zijn, waardoor u concurrerend blijft in de online markt.",
                commerce_option2_title: "2- API Synchronisatie",
                commerce_option2_content: "Integreer onze diensten eenvoudig met uw programma via onze robuuste en flexibele API's. Deze integratie zorgt voor soepel en geautomatiseerd beheer van voorraden, bestellingen en realtime leveringen, minimaliseert fouten en maximaliseert de efficiëntie.",
                commerce_option3_title: "3- Retourbeheer met Terugbetalingen",
                commerce_option3_content: "Bied uw klanten een retourmogelijkheid met terugbetalingen, waarmee het retourproces wordt vereenvoudigd en hun vertrouwen in uw service wordt versterkt. Ons efficiënte retourbeheer verkort de wachttijden en verlaagt de bijbehorende kosten, terwijl het klanttevredenheidsniveau hoog blijft.",
                commerce_option4_title: "4- Responsieve Klantenservice",
                commerce_option4_content: "Profiteer van een efficiënte klantenservice die snel antwoord geeft op vragen en problemen oplost. Een responsieve klantenservice is essentieel voor het behouden van klanten en het versterken van hun vertrouwen in uw merk.",
                commerce_join_us_text: "Met Cit Express kunt u uw detailhandel- en e-commerceactiviteiten transformeren door geoptimaliseerde, kosteneffectieve en klantgerichte leverings- en beheerdiensten aan te bieden. Onze op maat gemaakte oplossingen zijn ontworpen om te voldoen aan de specifieke behoeften van uw bedrijf, wat zorgt voor een uitzonderlijke winkelervaring voor uw klanten. Vertrouw op Cit Express om uw online bedrijf te stimuleren en uw concurrentievermogen op de markt te vergroten."







            }
        }
    }
}
