<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section">
        <section class="background-container" style="margin-top: 50px; padding-bottom: 50px; height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">


            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5" style="margin-left: 120px; margin-top: 150px;">
                    <div class="col-md-6">
                        <p>
                            <span style="color: white; font-size: 2.2em; font-weight: bold;">
                                {{ translate('secteurs_header') }}
                            </span>
                            <br>
                            <span style="color: white; font-size: 1.2em; font-weight: 400;">
                                {{ translate('secteurs_header_desc1') }}
                            </span>
                            <br>
                            <span style="color: white; font-size: 1.2em; font-weight: 400;">
                                {{ translate('secteurs_header_desc2') }}
                            </span>
                        </p>
                        <p style="margin-top: 150px;">
                            <span style="color: white; font-size: 1.5em; font-weight: 400;">
                                {{ translate('secteurs_sheader') }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 30px; padding-top: 20px; padding-bottom: 20px;">
            <div class="container">
                <div class="row text-center">
                    <div class="col-md-4 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur1.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur1_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur1_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/secteur_medicale">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur5.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur5_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur5_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/recherche_developpment">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur6.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur6_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur6_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/consultance_formation">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center">
                    <div class="col-md-4 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur4.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur4_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur4_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/secteur_industrie">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur2.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur2_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur2_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/technologie_electronique">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur3.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur3_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur3_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/commerce">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 20px; margin-bottom: 100px;">

            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur1.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur1_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur1_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur2.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur2_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur2_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur3.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur3_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur3_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur4.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur4_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur4_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur5.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur5_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur5_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur6.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur6_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur6_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur7.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur7_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur7_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur8.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur8_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur8_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <div class="laptop-header" style="margin-top: 50px;">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/secteurs_header_mobile.jpg" class="d-block w-100" alt="Background Image">
                </transition>

                <!-- Navbar -->
                <MainNav />

            </header>
        </div>

        <section style="padding-top: 0px; padding-bottom: 40px; background-color: #B42247; margin-top: -20px;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-11">
                        <p style="color: white; font-size: x-large; font-weight: bold;">{{ translate('secteurs_header')
                            }}</p>
                        <p style="color: white; font-size: medium;">{{ translate('secteurs_header_desc1') }} <br> {{
                            translate('secteurs_header_desc2') }}</p>
                        <p style="color: white; font-size: large; margin-top: 30px;">{{ translate('secteurs_sheader') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>


        <section style="margin-top: 30px; padding-top: 20px; padding-bottom: 20px;">
            <div class="container">
                <div class="row text-center">
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur1.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur1_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur1_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/secteur_medicale">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex" style="margin-top: 20px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur5.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur5_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur5_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/recherche_developpment">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex" style="margin-top: 20px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur6.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur6_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur6_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/consultance_formation">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="margin-top: 20px;">
                <div class="row text-center">
                    <div class="col-md-4 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur4.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur4_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur4_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/secteur_industrie">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex" style="margin-top: 20px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur2.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur2_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur2_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/technologie_electronique">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex" style="margin-top: 20px;">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur3.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur3_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur3_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/commerce">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 20px; padding-bottom: 20px; margin-top: 20px; margin-bottom: 60px;">

            <div class="container" style="height: 300px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel1" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 30px; padding-right: 30px;">
                                        <div class="d-flex align-items-center">
                                            <img src="../assets/img/sous_secteur1.svg" style="max-width: 60px;">
                                            <span
                                                style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                                translate('sous_secteur1_title') }}</span>
                                        </div>
                                        <div
                                            style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                            <span>{{ translate('sous_secteur1_contenu') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 30px; padding-right: 30px;">
                                        <div class="d-flex align-items-center">
                                            <img src="../assets/img/sous_secteur2.svg" style="max-width: 60px;">
                                            <span
                                                style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                                translate('sous_secteur2_title') }}</span>
                                        </div>
                                        <div
                                            style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                            <span>{{ translate('sous_secteur2_contenu') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 30px; padding-right: 30px;">
                                        <div class="d-flex align-items-center">
                                            <img src="../assets/img/sous_secteur3.svg" style="max-width: 60px;">
                                            <span
                                                style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                                translate('sous_secteur3_title') }}</span>
                                        </div>
                                        <div
                                            style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                            <span>{{ translate('sous_secteur3_contenu') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 30px; padding-right: 30px;">
                                        <div class="d-flex align-items-center">
                                            <img src="../assets/img/sous_secteur4.svg" style="max-width: 60px;">
                                            <span
                                                style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                                translate('sous_secteur4_title') }}</span>
                                        </div>
                                        <div
                                            style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                            <span>{{ translate('sous_secteur4_contenu') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container" style="margin-top: 30px; height: 300px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel2" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 30px; padding-right: 30px;">
                                        <div class="d-flex align-items-center">
                                            <img src="../assets/img/sous_secteur5.svg" style="max-width: 60px;">
                                            <span
                                                style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                                translate('sous_secteur5_title') }}</span>
                                        </div>
                                        <div
                                            style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                            <span>{{ translate('sous_secteur5_contenu') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 30px; padding-right: 30px;">
                                        <div class="d-flex align-items-center">
                                            <img src="../assets/img/sous_secteur6.svg" style="max-width: 60px;">
                                            <span
                                                style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                                translate('sous_secteur6_title') }}</span>
                                        </div>
                                        <div
                                            style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                            <span>{{ translate('sous_secteur6_contenu') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 30px; padding-right: 30px;">
                                        <div class="d-flex align-items-center">
                                            <img src="../assets/img/sous_secteur7.svg" style="max-width: 60px;">
                                            <span
                                                style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                                translate('sous_secteur7_title') }}</span>
                                        </div>
                                        <div
                                            style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                            <span>{{ translate('sous_secteur7_contenu') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="d-flex flex-column"
                                        style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 30px; padding-right: 30px;">
                                        <div class="d-flex align-items-center">
                                            <img src="../assets/img/sous_secteur8.svg" style="max-width: 60px;">
                                            <span
                                                style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                                translate('sous_secteur8_title') }}</span>
                                        </div>
                                        <div
                                            style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                            <span>{{ translate('sous_secteur8_contenu') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="ipad-section">
        <div class="laptop-header" style="margin-top: 50px;">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img src="../assets/img/secteurs_header.jpg" class="d-block w-100" alt="Background Image">
                </transition>

                <!-- Navbar -->
                <MainNav />

                <div class="position-absolute bottom-0 text-white mobile-prices"
                    style="width: 50%; margin-bottom: 15px; padding-left: 20px; padding-right: 20px;">
                    <p style="font-weight: bold; font-size: x-large; color: white; margin-bottom: 30px;">{{
                        translate('secteurs_header') }}</p>
                </div>
            </header>
        </div>

        <section style="padding-top: 20px; padding-bottom: 10px;">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12" style="margin-top: 0px; text-align: left;">
                        <p style="font-size: medium; color: black;">{{ translate('secteurs_header_desc1') }} <br> {{
                            translate('secteurs_header_desc2') }} </p>
                        <p style="font-weight: 400; font-size: large; color: black; margin-top: 20px;">{{
                            translate('secteurs_sheader') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 10px; padding-bottom: 20px;">
            <div class="container">
                <div class="row text-center">
                    <div class="col-6 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur1.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur1_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur1_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/secteur_medicale">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur2.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur2_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur2_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/technologie_electronique">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center">
                    <div class="col-6 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur5.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur5_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur5_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/recherche_developpment">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur6.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur6_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur6_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/consultance_formation">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center">
                    <div class="col-6 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur3.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur3_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur3_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/commerce">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7;">
                            <div>
                                <img src="../assets/img/secteur4.jpg">
                            </div>
                            <div style="padding: 20px; text-align: left; flex-grow: 1;">
                                <p style="color: #CF0020; font-weight: 400; font-size: larger;">
                                    {{ translate('secteur4_title') }}</p>
                                <p style="margin-top: 12px; color: #000;">{{ translate('secteur4_contenu') }}</p>
                            </div>
                            <div style="text-align: right; padding: 20px;" class="mt-auto">
                                <router-link to="/secteur_industrie">
                                    <button class="btn btn-primary secteurs-btn-more">{{ translate('see_more') }} <img
                                            style="margin-left: 8px;" src="../assets/img/right_row.svg"> </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 20px; padding-bottom: 20px; margin-bottom: 100px;">

            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur1.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur1_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur1_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur2.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur2_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur2_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur3.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur3_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur3_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur4.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur4_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur4_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur5.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur5_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur5_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur6.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur6_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur6_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div class="col-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur7.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur7_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur7_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-flex">
                        <div class="d-flex flex-column"
                            style="border-radius: 10px; height: 100%; background-color: #F7F7F7; padding-top: 20px; text-align: left; padding-left: 20px; padding-right: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/sous_secteur8.svg" style="max-width: 60px;">
                                <span style="color: #CF0020; font-weight: 400; font-size: large; margin-left: 15px;">{{
                                    translate('sous_secteur8_title') }}</span>
                            </div>
                            <div style="text-align: left; flex-grow: 1; padding-top: 20px; padding-bottom: 30px;">
                                <span>{{ translate('sous_secteur8_contenu') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>





<script>
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/secteurs_header.jpg';
import BackgroundImage2 from '../assets/img/iso_background.jpg';
import BackgroundImage3 from '../assets/img/iso_mobile_back.jpg';





export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            backgroundImage3: BackgroundImage3,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'CITExpress | Secteurs';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel1'), {
                ride: 'carousel',
                interval: 3000,
                pause: false
            });
        });

        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel2'), {
                ride: 'carousel',
                interval: 3000,
                pause: false
            });
        });
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
    }
}
</script>

<style>
.service-box {
    background-color: rgba(207, 0, 32, 0.5);
    color: white;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    height: 100%;
    cursor: pointer;
}

.service-box:hover {
    background-color: rgba(82, 79, 80, 0.5);
    border: 2px solid #efefef;
}

.service-box-mobile {
    border: 1px solid #CF0020;
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 250px;
    border-radius: 20px;
}

.laptop-header header .navbar {
    top: 0;
    left: 0;
}

.custom-btn {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn:hover {
    background-color: #CF0020;
    color: white;
}

.solution-box {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 1px solid white;
    text-align: center;
    color: black;
    padding: 25px;
    height: 100%;
}

.custom-btn2 {
    background-color: rgba(247, 243, 243, 0.1);
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn2:hover {
    background-color: #CF0020;
    color: white;
}

.custom-btn3 {
    background-color: #CF0020;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 150px;
}

.custom-btn3:hover {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
}

.review-box {
    background-color: white;
    border-radius: 20px;
    border: 1px solid white;
    color: black;
    padding: 25px;
    margin-left: 15px;
}

.laptop-section {
    display: block;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: block;
    }

    .ipad-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}

.secteurs-btn-more {
    background-color: #F7F7F7;
    color: #CF0020;
    border: 1px solid #CF0020;
    margin-right: 20px;
}

.secteurs-btn-more:hover {
    background-color: white;
    color: #CF0020;
    border: 1px solid #CF0020;
    font-weight: 600;
}
</style>