export default {
    data() {
        return {
            en: {
                header_text1: "Beyond the Wheel: ",
                header_text2: "Our drivers, the ambassadors of your brand.",
                header_text3: "They embody your values on every road, ensuring a safe arrival. With impeccable service, they guarantee satisfaction and trust with every journey.",
                header_text4: "Pharma Reliability:",
                header_text5: "Certified Excellence, ISO 9001 for precise deliveries.",
                header_text6: "Our mastery of Belgian healthcare networks ensures flawless distribution, from the pharma sector to hospitals, raising the standard of trust.",
                header_text7: "Hand to Hand:",
                header_text8: "Absolute guarantee of punctuality and integrity.",
                header_text9: "Your shipments, precious and fragile, are handled with the utmost care, ensuring a perfect arrival every time.",
                action1_title: "SEND A SHIPMENT",
                action2_title: "REQUEST A QUOTE",
                action3_title: "BECOME A PARTNER",
                why_question1: "Why",
                why_question2: "choose to use CIT Express?",
                section1_text1: "CIT Express turns your expectations into reality, adapting its solutions to your requirements",
                solution1_title: "Delivery Sectors",
                solution1_contenu: "Explore our delivery services tailored to 14 sectors, covering a wide range from the medical field to the aerospace industry",
                solution2_title: "Our Fleet",
                solution2_contenu: "Discover our diverse fleet of vehicles, ensuring reliable and fast delivery service to meet all needs.",
                solution3_title: "Technology",
                solution3_contenu: "We combine operational excellence with cutting-edge technologies to deliver a service that exceeds our customers' expectations",
                solution4_title: "ISO 9001-2018",
                solution4_contenu: "At CIT Express, our adherence to ISO standards ensures unparalleled quality and efficiency, ensuring total customer satisfaction",
                see_more: "See more",
                citexpress_name: "CIT EXPRESS",
                citexpress_name_description: "leading in Belgium since 1995",
                citexpress_presentation: "CIT Express, leading in Belgium since 1995, offers tailor-made logistic solutions to meet your shipping needs, whether you are an individual, an e-commerce business, a small business, or a multinational...",
                satisfied_clients: "Thousands of satisfied customers",
                satisfied_clients_description: "Many of them, we asked them how satisfied they were with our services. Here are their statements.",
                give_review: "GIVE A REVIEW",
                user_name1: "Marc B. B2B",
                user_review1: "Previously, tracking was complicated with a single carrier. Today, thanks to CIT Express managing all of our carriers, everything is handled smoothly and efficiently.",
                user_name2: "Amina R. B2B",
                user_review2: "Finding a carrier that meets our requirements is almost impossible, CIT Express has succeeded.",
                user_name3: "Alan D. C2C",
                user_review3: "In a delicate situation, CIT Express ensured fast and secure delivery of important documents, saving me. A professional service that I recommend.",
                user_name4: "Jean-Michel L. B2B",
                user_review4: "Collaborating with several carriers involves many contacts and various invoices. With CIT Express, we save valuable time by consolidating these processes.",

                // Navbar
                home: "Home",
                services: "Services",
                track_shipment: "Track Shipment",
                contact_us: "Contact Us",
                my_cit: "My CIT",

                login_text1: "Ready to send?",
                login_text2: "Sign in or register now",
                form_header: "Sign in:",
                email_placeholder: "Email address",
                password_placeholder: "Password",
                login: "LOG IN",
                not_yet_subscribed: "Not yet subscribed?",
                subscribe: "SIGN UP",

                // Our Fleet
                notre_flotte_header: "Our Fleet: Diverse, Modern, and Ecological",
                notre_flotte_desc: "At CIT Express, our fleet of vehicles is the heart of our ability to provide fast and reliable delivery service. We understand that each delivery is unique, which is why our fleet consists of a variety of vehicles tailored to all needs.",

                notre_flotte_title1: "Electric Vehicles",
                notre_flotte_title1_soustitle1: "Electric Scooters",
                notre_flotte_title1_soustitle1_desc: "Perfect for quick navigation in urban areas, our electric scooters are both environmentally friendly and efficient.",
                notre_flotte_title1_soustitle2: "Electric Bikes",
                notre_flotte_title1_soustitle2_desc: "Ideal for local deliveries, they combine the maneuverability of traditional bicycles with assisted power, allowing for fast emissions-free deliveries.",
                notre_flotte_title2: "Motor Vehicles",
                notre_flotte_title2_soustitle1: "Vans",
                notre_flotte_title2_soustitle1_desc: "Our vans are ideal for larger or multiple deliveries, offering enough space while being agile in the city.",
                notre_flotte_title2_soustitle2: "Delivery Trucks",
                notre_flotte_title2_soustitle2_desc: "For larger cargoes or specialized deliveries, our trucks are equipped to safely transport a wide variety of goods.",

                notre_flotte_title3: "Specialized Vehicles",
                notre_flotte_title3_soustitle1: "Refrigerated Vehicles",
                notre_flotte_title3_soustitle1_desc: "These vehicles are essential for transporting products requiring temperature control, such as certain medicines or sensitive technological equipment.",
                notre_flotte_title3_soustitle2: "High Security Delivery Vehicles",
                notre_flotte_title3_soustitle2_desc: "Used for transporting documents or valuables requiring increased security, these vehicles are equipped with advanced security systems.",

                notre_flotte_title4: "Commitment to Sustainability: Transition to Electric",
                notre_flotte_title4_desc1: "We are committed to a gradual transition to an all-electric fleet, thus reducing our carbon footprint and contributing to a more sustainable future.",
                notre_flotte_title4_desc2: "Our fleet is regularly maintained and updated to ensure the best performance and reliability. At CIT Express, we pride ourselves on our ability to meet all delivery needs while remaining committed to environmental responsibility and innovation.",

                about_us_header: "ABOUT US",
                about_us_sheader: "Pioneers of Innovative Urban Delivery",
                about_us_section1_title: "Origins in Brussels, 1996",
                about_us_section1_contenu: "The adventure of CIT Express began in 1996 in Brussels, a city where innovation blends with a rich cultural heritage. Faced with urban logistic challenges, such as traffic jams and demonstrations, we had a revolutionary vision: transforming urban delivery.",
                inovation: "Innovation:",
                inovation_desc: "Beyond Scooter and Bike Delivery",
                inovation_text: "Our initial concept quickly distinguished itself by using scooters and bikes for fast and efficient deliveries. But our real breakthrough was our pioneering adoption of innovative technologies.",
                about_us_services_title: "Pioneers in Communication Technology",
                about_us_service1_title: "Radio Communication",
                about_us_service1_contenu: "We were among the first to use radio communication to coordinate our deliveries, thus increasing our operational efficiency.",
                about_us_service2_title: "Introduction of SMS",
                about_us_service2_contenu: "Early adoption of SMS enabled faster and more direct communication with our teams in the field.",
                about_us_service3_title: "GPS Pioneers",
                about_us_service3_contenu: "The integration of GPS revolutionized our ability to track deliveries in real-time, offering unprecedented transparency and reliability.",
                inovation_numerique: "Digital Innovation",
                inovation_numerique1_title: "1st Online Ordering Site",
                inovation_numerique1_contenu: "We launched one of the first online delivery ordering sites, offering our customers ease of use and increased efficiency.",
                inovation_numerique2_title: "Online Tracking and Billing",
                inovation_numerique2_contenu: "By introducing online order tracking and electronic billing, we not only simplified the process for our customers but also reduced our ecological footprint.",
                leadership_title: "A Legacy of Leadership and Innovation",
                leadership_text1: "These innovations not only established CIT Express as a leader in urban delivery but also paved the way for technologies that many major players in the industry use today. We are proud to have been pioneers, constantly looking for new ways to improve our services and anticipate the needs of our customers.",
                leadership_text2: "At CIT Express, our history is one of constant innovation and commitment to excellence. We continue to evolve, always seeking new ways to serve our customers efficiently and reliably.",
                contactus_paragraphe1: "Let us show you how CIT Express can meet your urban delivery requirements efficiently and reliably.",
                contactus_paragraphe2: "Contact us today for an exceptional collaboration.",
                contacus_button: "Contact Us",

                iso_title: "ISO 9001-2018",
                iso_title_s1: "Quality",
                iso_title_s2: "Management",
                iso_title_s3: "Systems",
                norme_iso: "ISO Standards:",
                norme_iso_desc: "The Foundation of Our Excellence in Specialized Delivery",
                iso_icon_title: "Commitment to ISO Quality Standards",
                iso_icon_desc: "At CIT Express, adherence to ISO standards is at the core of our commitment to unparalleled quality and efficiency. Our processes are rigorously designed to meet and exceed these international standards, ensuring total customer satisfaction.",
                iso_app_title: "Application of ISO Standards in Our Specialized Services",
                iso_app1_title: "ISO 9001 for Quality Management",
                iso_app1_contenu: "All our processes, from operations management to delivery services, comply with ISO 9001, ensuring consistent and reliable quality.",
                iso_app2_title: "ISO 14001 for Environmental",
                iso_app2_contenu: "We are committed to minimizing our environmental impact through practices compliant with ISO 14001, underscoring our ecological responsibility.",
                iso_app3_title: "ISO 45001 for Health and Safety",
                iso_app3_contenu: "The safety and well-being of our team and customers remain a top priority, constantly guided by ISO 45001 standards and the commitment of all.",
                iso_norme_title: "Sector Specialization and Associated Standards",
                iso_norme1_title: "Pharmaceutical Sector",
                iso_norme1_contenu: "We adhere to strict standards for handling and transporting pharmaceutical products, including Good Distribution Practices (GDP) and specific cold chain guidelines.",
                iso_norme2_title: "Electronics and Technology Sector",
                iso_norme2_contenu: "For the transport of sensitive electronic products, we apply specific standards that ensure protection against electrostatic discharge and mechanical damage.",
                iso_norme3_title: "Legal and Financial Sector",
                iso_norme3_contenu: "We adhere to rigorous privacy and security protocols to ensure the safety and confidentiality of sensitive documents.",
                iso_norme4_title: "Aerospace Sector",
                iso_norme4_contenu: "The specific requirements of this sector are met, including the transport of critical materials and components, in compliance with aerospace quality and safety standards.",
                formation_continue_title: "Continuous Training and Awareness",
                formation_continue_desc: "Our staff receives ongoing training on ISO standards and sector-specific requirements. This approach ensures flawless expertise and compliance in all our operations.",
                audit_amelioration_title: "Audit and Continuous Improvement",
                audit_amelioration_desc: "Regular audits are conducted to verify compliance with ISO standards and identify improvement opportunities. This continuous improvement approach reinforces our commitment to excellence.",
                iso_certif_2018: "By integrating these standards into our operational model, CIT Express stands out as a reliable and specialized delivery service provider, committed to the highest standards for each of the sectors we serve.",

                secteurs_header: "Delivery Sectors",
                secteurs_header_desc1: "Explore our delivery services tailored to 14 sectors, from medical to aerospace.",
                secteurs_header_desc2: "Choose your domain from the dropdown menu and discover how our expertise ensures safety and speed for your shipments.",
                secteurs_sheader: "CIT Express, 30 years of expertise, your trusted partner for custom deliveries!",
                secteur1_title: "Medical and Pharmaceutical Sector",
                secteur1_contenu: "Urgent deliveries of medicines, vaccines, biological samples, medical equipment. Temperature-controlled transport for sensitive items.",
                secteur2_title: "Technology and Electronics",
                secteur2_contenu: "Delivery of sensitive electronic components, computer equipment. Need for careful handling and protection against shocks.",
                secteur3_title: "Retail and E-commerce",
                secteur3_contenu: "Optimize your online business with fast delivery, efficient return management, and stock synchronization via APIs. Ensure responsive customer service.",
                secteur4_title: "Automotive Industry",
                secteur4_contenu: "Urgent transport of spare parts, components for production. Just-in-time deliveries to maintain production continuity.",
                secteur5_title: "Research and Development",
                secteur5_contenu: "Service ensures secure transport for your research equipment, scientific samples, and prototypes, with particular attention to meticulous temperature control.",
                secteur6_title: "Consulting and Training",
                secteur6_contenu: "Quick delivery of important documents, presentation and training materials, equipment for audits or evaluations. Importance of speed and reliability.",

                sous_secteur1_title: "Food Industry",
                sous_secteur1_contenu: "Transport of perishable goods, catering supplies, urgent deliveries for supermarkets. Temperature and freshness requirements.",
                sous_secteur2_title: "Construction and Engineering",
                sous_secteur2_contenu: "For the transport of sensitive electronic products, we apply specific standards that ensure protection against electrostatic discharge and mechanical damage.",
                sous_secteur3_title: "Legal and Financial",
                sous_secteur3_contenu: "We adhere to rigorous privacy and security protocols to ensure the safety and confidentiality of sensitive documents.",
                sous_secteur4_title: "Aerospace Sector",
                sous_secteur4_contenu: "The specific requirements of this sector are met, including the transport of critical materials and components, in compliance with aerospace quality and safety standards.",
                sous_secteur5_title: "Public and Government Sector",
                sous_secteur5_contenu: "We follow strict standards for handling and transporting pharmaceutical products, including Good Distribution Practices (GDP) and specific cold chain guidelines.",
                sous_secteur6_title: "Fashion and Textile",
                sous_secteur6_contenu: "For the transport of sensitive electronic products, we apply specific standards that ensure protection against electrostatic discharge and mechanical damage.",
                sous_secteur7_title: "Events and Entertainment",
                sous_secteur7_contenu: "We adhere to rigorous privacy and security protocols to ensure the safety and confidentiality of sensitive documents.",
                sous_secteur8_title: "Arts and Antiques",
                sous_secteur8_contenu: "The specific requirements of this sector are met, including the transport of critical materials and components, in compliance with aerospace quality and safety standards.",

                technologie_header: "Technology at Your Service for a Seamless, Easy, and Intuitive Experience",
                technologie_sheader: "At CIT Express, we combine operational excellence with cutting-edge technologies to offer a delivery service that not only meets but exceeds our customers' expectations.",
                technologie1_title: "Additional Technological Features",
                technologie2_title: "Accessible and Transparent Delivery Tracking",
                technologie3_title: "Intuitive and Efficient Accounting",
                technologie_desc: "Technological innovation at CIT Express is dedicated to continuously improving our customers' experience, offering logistics solutions that are not only advanced but also perfectly aligned with their operational and financial needs.",

                technologie1_tech1_title: "Simplified Ordering Interface",
                technologie1_tech1_desc: "Our online platform is designed for an intuitive user experience, allowing for quick and effortless order placement.",
                technologie1_tech2_title: "Automatic Notifications and Alerts",
                technologie1_tech2_desc: "Stay informed at every stage of your delivery with automatic notifications and updates.",
                technologie1_tech3_title: "Integration with Enterprise Systems",
                technologie1_tech3_desc: "Our platform seamlessly integrates with ERP and CRM systems for centralized and efficient order management.",
                technologie1_tech4_title: "Secure Payment Options",
                technologie1_tech4_desc: "We offer various secure payment options to meet our customers' needs.",
                technologie1_tech5_title: "24/7 Customer Support",
                technologie1_tech5_desc: "Our customer support team is available 24/7 to address any questions and quickly resolve any issues.",
                technologie2_tech1_title: "Real-Time Geolocation",
                technologie2_tech1_desc: "Our customers and parcel recipients can track the progress of their shipments in real-time through our advanced geolocation system.",
                technologie2_tech2_title: "Interactive Tracking Program",
                technologie2_tech2_desc: "Recipients are integrated into our tracking program, allowing them to easily check the status and location of their shipment for complete transparency and peace of mind.",
                technologie3_tech1_title: "Job or PO Number Requirement",
                technologie3_tech1_desc: "To streamline your accounting department's work, our system requires entry of a job or purchase order (PO) number before any order placement. This practice ensures precise cost allocation and simplified accounting management.",
                technologie3_tech2_title: "Customizable and Exportable Accounting Reports",
                technologie3_tech2_desc: "Our customers can generate and export customized accounting reports, offering seamless integration with their own financial systems.",

                about_us: "About Us",
                terms_of_use: "Terms of Use",
                privacy_policy: "Privacy Policy",
                accessibility_statement: "Accessibility Statement",
                all_rights_resereved: "All Rights Reserved",

                username: "Username",

                suivant: "Next",
                nom_entreprise: "Company Name?",
                secteur_activite: "Business Sector?",
                contact_principale: "Primary Contact (Name and Position)?",
                address_email: "Email Address?",
                phone_number: "Phone Number",
                repondez_ici: "Reply here...",
                question1: "What is the frequency of your delivery needs?",
                question1_choice1: "Daily",
                question1_choice2: "Weekly",
                question1_choice3: "Monthly",
                question1_choice4: "Other",
                question2: "What is the average volume of your shipments? (Number of packages or approximate weight per shipment)",
                question3: "What are the main destinations of your shipments?",
                question3_choice1: "National",
                question3_choice2: "International",
                question3_choice3: "Both",
                question4: "What type of goods do you ship?",
                question4_choice1: "Documents",
                question4_choice2: "Packages",
                question4_choice3: "Pallets",
                question4_choice4: "Special Goods",
                question5: "Are there any specific requirements for your goods? (Fragile, temperature-controlled, hazardous, etc.)",
                question5_choice1: "Yes",
                question5_choice2: "No",
                question6: "What delivery services are you looking for?",
                question6_choice1: "Standard Delivery",
                question6_choice2: "Express",
                question6_choice3: "Same Day",
                question7: "Do you need additional services? (Insurance, real-time tracking, delivery with signature, etc.)",
                question7_choice1: "Yes",
                question7_choice2: "No",
                question8: "Have you ever used express delivery services?",
                question8_choice1: "Yes",
                question8_choice2: "No",
                question9: "What are your main criteria for choosing a delivery service? (Cost, reliability, speed, customer service, etc.)",
                question10: "Do you have any specific expectations or concerns regarding the delivery service?",
                question11: "What is your approximate budget for delivery services?",
                question12: "What are your preferences for billing and payment?",
                thanks_message1: "Thank you for requesting a quote.",
                thanks_message2: "One of our industry experts is processing your request. You will be contacted shortly to discuss your delivery needs and our solutions.",

                contactus_header: "Contact us",
                contactus_sheader1: "Welcome to CIT Express, experts in express delivery",
                contactus_sheader2: "Need help? Contact us!",
                contactus_form_input1: "Name",
                contactus_form_input2: "Company",
                contactus_form_input3: "Phone",
                contactus_form_input4: "Email",
                contactus_form_input5: "Subject",
                contactus_form_input6: "Message",
                contactus_form_button: "Send",
                contactus_text1: "Dedicated phone number for immediate assistance:",
                contactus_text2: "Our team is available",
                contactus_text3: "To quickly address your urgent concerns.",

                construction_text1: "Page under construction",
                construction_text2: "We're sorry, but this page is currently under construction.",
                construction_text3: "Please come back soon to see updates.",
                nos_service: "Our services",
                contactus_message: "Thank you, we will contact you soon",


                secteur_medicale_title: "Medical and Pharmaceutical Sector",
                secteur_medicale_sub_title: "Your Trusted Partner for Urgent Deliveries",
                secteur_medicale_section1_title: "Specialized Delivery Services for the Medical and Pharmaceutical Sector",
                secteur_medicale_section1_contenu: "In the medical and pharmaceutical sector, speed and reliability of deliveries are crucial. Whether you need to transport medications, vaccines, biological samples, or medical equipment, we are here to provide a delivery service tailored to your needs.",
                secteur_medicale_option1_title: "1- Delivery of Medications and Vaccines",
                secteur_medicale_option1_content: "We understand the importance of medications and vaccines for public health. Our delivery services ensure that your pharmaceutical products reach their destination quickly and in perfect condition. With our temperature-controlled transport system, we ensure that your medications and vaccines are kept at the ideal temperature throughout the journey.",
                secteur_medicale_option2_title: "2- Transport of Biological Samples",
                secteur_medicale_option2_content: "The transport of biological samples requires special attention and strict adherence to safety protocols. Our team is trained in the best practices for handling and transporting biological samples, ensuring their integrity and safety. We also provide full traceability of your shipments, offering you added peace of mind.",
                secteur_medicale_option3_title: "3- Delivery of Medical Equipment",
                secteur_medicale_option3_content: "Medical equipment is essential for healthcare. We offer fast and secure delivery service for all types of medical equipment, from diagnostic devices to surgical equipment. Our vehicles are equipped to transport items of all sizes, and we are committed to delivering your equipment within the agreed-upon timeframe.",
                secteur_medicale_option4_title: "4- Temperature-Controlled Transport",
                secteur_medicale_option4_content: "Temperature-controlled transport is essential for sensitive items in the medical and pharmaceutical sector. Our vehicles are equipped with refrigeration systems and temperature tracking, ensuring that your products are kept at the optimal temperature throughout the journey. Whether it's for refrigerated, frozen, or ambient temperature-controlled products, we have the solution to meet your needs.",
                secteur_medicale_option5_title: "5- Commitment to Quality and Safety",
                secteur_medicale_option5_content: "We are committed to providing the highest quality delivery service for the medical and pharmaceutical sector. Our team of qualified professionals ensures that every shipment is handled with the utmost care and in compliance with the strictest safety standards. We work closely with our clients to understand their specific needs and provide tailored delivery solutions.",
                secteur_medicale_option6_title: "6- Real-Time Tracking and Traceability",
                secteur_medicale_option6_content: "With our advanced real-time tracking and traceability system, you can track your shipments at every stage of the transport. You will receive regular updates on the status of your delivery, ensuring complete visibility and peace of mind.",
                secteur_medicale_section3: "To learn more about our delivery services for the medical and pharmaceutical sector, or to discuss your specific needs, feel free to contact us. We are here to provide reliable, fast, and secure delivery solutions tailored to the demands of the medical sector.",
                secteur_medicale_section4_title: "List of Transported Items and Materials",
                secteur_medicale_section4_op1_title: "1. Medications and Vaccines",
                secteur_medicale_section4_op1_point1: "Prescription medications",
                secteur_medicale_section4_op1_point2: "Over-the-counter medications",
                secteur_medicale_section4_op1_point3: "Vaccines",
                secteur_medicale_section4_op1_point4: "Serums and immunoglobulins",
                secteur_medicale_section4_op1_point5: "Refrigerated medications",
                secteur_medicale_section4_op1_point6: "Temperature-sensitive medications",
                secteur_medicale_section4_op2_title: "2. Biological Samples",
                secteur_medicale_section4_op2_point1: "Blood samples",
                secteur_medicale_section4_op2_point2: "Urine samples",
                secteur_medicale_section4_op2_point3: "Tissue samples",
                secteur_medicale_section4_op2_point4: "Bacterial and viral cultures",
                secteur_medicale_section4_op2_point5: "DNA and genetic samples",
                secteur_medicale_section4_op2_point6: "Samples for microbiological analysis",
                secteur_medicale_section4_op3_title: "3. Medical Equipment",
                secteur_medicale_section4_op3_point1: "Diagnostic devices (ultrasound machines, scanners, etc.)",
                secteur_medicale_section4_op3_point2: "Surgical equipment",
                secteur_medicale_section4_op3_point3: "Laboratory equipment",
                secteur_medicale_section4_op3_point4: "Disposable medical devices (gloves, syringes, etc.)",
                secteur_medicale_section4_op3_point5: "Patient monitors",
                secteur_medicale_section4_op3_point6: "Defibrillators",
                secteur_medicale_section4_op4_title: "4. Temperature-Sensitive Products",
                secteur_medicale_section4_op4_point1: "Refrigerated medications",
                secteur_medicale_section4_op4_point2: "Biological products",
                secteur_medicale_section4_op4_point3: "Laboratory reagents",
                secteur_medicale_section4_op4_point4: "Biological medications",
                secteur_medicale_section4_op4_point5: "Medical chemicals",
                join_us_button: "Join Us",
                secteur_medicale_join_us_text: "Join us today and discover how CIT Express can simplify your needs by ensuring safe and efficient transport of your research materials.",

                recherche_developpment_title: "Research and Development",
                recherche_developpment_sub_title: "Transport Solutions for Your Innovative Projects",
                recherche_developpment_section1_title: "Delivery Services for Research and Development",
                recherche_developpment_section1_content: "In the field of research and development, the secure and reliable transport of research materials, scientific samples, and prototypes is crucial. We offer specialized delivery services to meet the unique needs of your innovative projects.",
                recherche_developpment_option1_title: "1- Transport of Research Materials",
                recherche_developpment_option1_content: "Your research materials are valuable and sensitive. We guarantee secure and careful transport for your research instruments and equipment, ensuring they arrive in perfect condition and ready for use.",
                recherche_developpment_option2_title: "2- Delivery of Scientific Samples",
                recherche_developpment_option2_content: "Scientific samples require strict control of transport conditions. Our temperature-controlled delivery services keep your samples in optimal conditions, preserving their integrity and viability for your experiments and analyses.",
                recherche_developpment_option3_title: "3- Transport of Prototypes",
                recherche_developpment_option3_content: "The transport of prototypes requires careful handling and special attention. We offer customized delivery solutions for your prototypes, ensuring a secure and damage-free arrival.",
                recherche_developpment_option4_title: "4- Temperature-Controlled Transport",
                recherche_developpment_option4_content: "For the research and development sector, temperature-controlled transport is often necessary to maintain the quality of sensitive samples and materials. Our vehicles are equipped with advanced refrigeration and temperature monitoring systems, ensuring ideal transport conditions.",
                recherche_developpment_option5_title: "5- Real-Time Tracking and Traceability",
                recherche_developpment_option5_content: "Our real-time tracking and traceability system allows you to monitor your shipments at every stage of the transport. You will receive real-time updates on the status of your delivery, ensuring full transparency and peace of mind.",
                recherche_developpment_option6_title: "6- Commitment to Quality and Safety",
                recherche_developpment_option6_content: "We are committed to providing high-quality delivery services for the research and development sector. Our experienced team ensures that each shipment is handled with the utmost care and in compliance with the highest safety standards.",
                recherche_developpment_section4_op1_title: "1. Research Materials",
                recherche_developpment_section4_op1_point1: "Scientific measuring instruments",
                recherche_developpment_section4_op1_point2: "Laboratory equipment",
                recherche_developpment_section4_op1_point3: "Specialized computers and peripherals",
                recherche_developpment_section4_op1_point4: "Calibration tools",
                recherche_developpment_section4_op1_point5: "Research and development kits",
                recherche_developpment_section4_op2_title: "2. Scientific Samples",
                recherche_developpment_section4_op2_point1: "Biological samples for research",
                recherche_developpment_section4_op2_point2: "Chemical samples",
                recherche_developpment_section4_op2_point3: "Material samples",
                recherche_developpment_section4_op2_point4: "Environmental samples (soil, water, air)",
                recherche_developpment_section4_op2_point5: "Clinical samples for trials",
                recherche_developpment_section4_op3_title: "3. Prototypes",
                recherche_developpment_section4_op3_point1: "Medical equipment prototypes",
                recherche_developpment_section4_op3_point2: "Diagnostic device prototypes",
                recherche_developpment_section4_op3_point3: "Technological device prototypes",
                recherche_developpment_section4_op3_point4: "Experimental models",
                recherche_developpment_section4_op3_point5: "Electronic components",
                recherche_developpment_section4_op4_title: "4. Temperature-Sensitive Products",
                recherche_developpment_section4_op4_point1: "Biological samples requiring refrigeration",
                recherche_developpment_section4_op4_point2: "Temperature-sensitive chemical reagents",
                recherche_developpment_section4_op4_point3: "Cell and tissue cultures",
                recherche_developpment_section4_op4_point4: "Biological products for research",
                recherche_developpment_section4_op4_point5: "Culture media and solutions",
                recherche_developpment_contact_us: "To discuss your specific transport needs for research and development, don't hesitate to contact us. We are here to offer reliable, secure delivery solutions tailored to your innovative projects.",

                secteur_industrie_title: "Automotive Industry Sector",
                secteur_industrie_sub_title: "Delivery Services for the Automotive Industry",
                secteur_industrie_section1_title: "Urgent Transport of Spare Parts",
                secteur_industrie_section2_title: "Delivery of Components for Production",
                secteur_industrie_section3_title: "Just-In-Time Deliveries for the Automotive Industry",
                secteur_industrie_section1_limit: "Transport of spare parts such as engines, transmissions, brake systems, bumpers, headlights, and other critical parts.",
                secteur_industrie_section2_limit: "Transport of components such as chassis, dashboards, seats, electrical wiring, and electronic modules.",
                secteur_industrie_section3_limit: "Delivery of finished products, semi-finished products, and raw materials required for automotive production.",
                secteur_industrie_option1_title: "1- Speed and Efficiency",
                secteur_industrie_option1_content: "Fast delivery of automotive spare parts to minimize downtime.",
                secteur_industrie_option2_title: "2- Secure Transport",
                secteur_industrie_option2_content: "Specialized handling and secure transport to ensure that automotive parts arrive in perfect condition.",
                secteur_industrie_option3_title: "1- Speed and Efficiency",
                secteur_industrie_option3_content: "Timely delivery of automotive production components to keep manufacturing processes running smoothly.",
                secteur_industrie_option4_title: "2- Coordination with Suppliers",
                secteur_industrie_option4_content: "Coordination with suppliers to ensure that components are delivered exactly when needed, improving the efficiency of the automotive supply chain.",
                secteur_industrie_option5_title: "1- Speed and Efficiency",
                secteur_industrie_option5_content: "Precise timing of just-in-time deliveries to maintain continuity in the automotive production chain.",
                secteur_industrie_option6_title: "2- Real-Time Delivery Tracking",
                secteur_industrie_option6_content: "Real-time delivery tracking and updates to ensure that automotive parts and components arrive on schedule.",
                secteur_industrie_join_us: "These transportation and delivery services for the automotive industry ensure that production processes remain uninterrupted, contributing to operational efficiency and cost savings for automotive companies.",

                consultance_formation_title: "Consulting and Training",
                consultance_formation_sub_title: "Fast and Reliable Logistic Support for Your Consulting and Training Activities",
                consultance_formation_section1_title: "Express Delivery for Consulting and Training Professionals",
                consultance_formation_section1_contenu: "In the field of consulting and training, speed and reliability of deliveries are crucial for the success of your missions. Aware of the importance of every document, presentation material, and equipment for audits or evaluations, we are committed to offering fast and secure delivery services to meet your professional needs.",
                consultance_formation_section2_title: "Our Services Include:",
                consultance_formation_option1_title: "1- Express Delivery of Important Documents",
                consultance_formation_option1_content: "Whether it's contracts, audit reports, or training materials, we guarantee fast and secure delivery so you never lose a moment.",
                consultance_formation_option2_title: "2- Presentation and Training Equipment",
                consultance_formation_option2_content: "Fast shipping of projectors, laptops, and other equipment needed for your training or presentation sessions.",
                consultance_formation_option3_title: "3- Equipment for Audits and Evaluations",
                consultance_formation_option3_content: "Reliable delivery of specific equipment for your audit missions, ensuring that you always have the necessary material on time.",
                consultance_formation_section3_title: "Why Choose Our Services?",
                consultance_formation_option4_title: "1- Speed and Efficiency",
                consultance_formation_option4_content: "Our services are designed to offer the shortest possible delivery times, allowing you to meet tight schedules.",
                consultance_formation_option5_title: "2- Reliability",
                consultance_formation_option5_content: "With our real-time tracking, you can always know where your shipment is and when it will arrive. Our drivers, true ambassadors of our service, are trained to be polite, clean, and friendly, ensuring a positive experience with every delivery.",
                consultance_formation_option6_title: "3- Field Experience",
                consultance_formation_option6_content: "With many years of experience, our drivers are well-versed in the demands of the consulting and training sector. Their field expertise ensures flawless delivery, even in the most complex situations.",
                consultance_formation_option7_title: "4- Dedicated Customer Service",
                consultance_formation_option7_content: "Our team is available 24/7 to answer your questions and solve any issues.",
                consultance_formation_option8_title: "5- Traceability and Security",
                consultance_formation_option8_content: "Every delivery is tracked in real-time, allowing you to follow your package from dispatch to final destination.",
                consultance_formation_section4_title: "Integrated Platform to Meet All Your Needs:",
                consultance_formation_section4_content: "We offer a user-friendly and comprehensive platform that meets all your desires, whether it is for:",
                consultance_formation_box_option1_title: "Order Placement",
                consultance_formation_box_option1_contenu: "Simplified process for placing your delivery orders, with customizable options to suit your specific needs.",
                consultance_formation_box_option2_title: "Delivery Tracking",
                consultance_formation_box_option2_contenu: "Real-time tracking of your shipments, with notifications at every stage of the delivery process.",
                consultance_formation_box_option3_title: "Transparent Pricing",
                consultance_formation_box_option3_contenu: "Choose between different types of services (economical or express) based on your priorities and budget.",
                consultance_formation_box_option4_title: "Personalized Billing",
                consultance_formation_box_option4_contenu: "For departments requiring purchase orders (PO) or job numbers for accounting distribution, our billing system is designed to adapt to your administrative and financial needs.",
                consultance_formation_join_us_text: "Entrust your deliveries to us and focus on what matters most: the success of your consulting and training projects.",

                technologie_electronique_title: "Technology and Electronics",
                technologie_electronique_sub_title: "Delivery of Sensitive Electronic Components and IT Equipment",
                technologie_electronique_section1_title: "Your Trusted Delivery Partner for the Technology Sector Since 1996",
                technologie_electronique_section1_contenu: "Since 1996, Cit Express has been committed to providing fast, secure, and reliable delivery solutions for the technology and electronics sector. We understand the unique demands of this sector and are committed to offering top-class delivery service for your electronic components and IT equipment.",
                technologie_electronique_section2_title: "Our Delivery Services for the Technology and Electronics Sector",
                technologie_electronique_section2_title: "Why Choose CIT Express?",
                technologie_electronique_box_option1_title: "Careful Handling",
                technologie_electronique_box_option1_contenu: "At Cit Express, we ensure careful handling of fragile electronic components and IT equipment through a trained team and specially designed packaging to protect them during transport.",
                technologie_electronique_box_option2_title: "Secure Transport",
                technologie_electronique_box_option2_contenu: "The safety of your shipments is our priority. Our vehicles with advanced suspension minimize the risk of damage, and our real-time tracking lets you monitor every step of the transport.",
                technologie_electronique_box_option3_title: "Speed and Reliability",
                technologie_electronique_box_option3_contenu: "In the technology sector, time is critical. Cit Express offers express deliveries, same-day or next-day, to ensure that your equipment arrives on time.",
                technologie_electronique_box_option4_title: "Personalized Services",
                technologie_electronique_box_option4_contenu: "Every shipment is unique, which is why we offer personalized services. Whether it's for regular or one-time deliveries, our team will create a custom delivery solution for your business.",
                technologie_electronique_option1_title: "1- Expertise",
                technologie_electronique_option1_content: "An experienced team specifically trained for handling sensitive electronic components.",
                technologie_electronique_option2_title: "2- Security",
                technologie_electronique_option2_content: "Use of vehicles and packaging specially designed to protect your shipments.",
                technologie_electronique_option3_title: "3- Real-Time Tracking",
                technologie_electronique_option3_content: "Complete tracking of your deliveries for total peace of mind.",
                technologie_electronique_option4_title: "4- Speed",
                technologie_electronique_option4_content: "Express delivery options to meet your company's urgent needs.",
                technologie_electronique_option5_title: "5- Flexibility",
                technologie_electronique_option5_content: "Custom solutions tailored to the unique demands of your business.",
                technologie_electronique_join_us_text1: "For more information on our delivery services in the technology and electronics sector, or to request a personalized quote, feel free to contact us. We are here to ensure that your electronic components and IT equipment reach their destination safely and on time.",
                technologie_electronique_join_us_text2: "Contact us today and discover how Cit Express can become your trusted partner for all your technology and electronics delivery needs.",

                commerce_title: "Retail and E-commerce",
                commerce_section1_title: "Optimize Your Online Business with Cit Express",
                commerce_option1_title: "1- Fast and Affordable Delivery",
                commerce_option1_content: "Ensure your customers receive fast and affordable delivery to enhance their shopping experience and boost customer satisfaction. Our delivery solutions are designed to be efficient and cost-effective, keeping you competitive in the online marketplace.",
                commerce_option2_title: "2- API Synchronization",
                commerce_option2_content: "Easily integrate our services with your program through our robust and flexible APIs. This integration ensures smooth and automated management of inventory, orders, and real-time deliveries, minimizing errors and maximizing efficiency.",
                commerce_option3_title: "3- Return Management with Refunds",
                commerce_option3_content: "Offer your customers a return option with refunds, simplifying the return process and reinforcing their confidence in your service. Our efficient return management reduces delays and associated costs while maintaining a high level of customer satisfaction.",
                commerce_option4_title: "4- Responsive Customer Service",
                commerce_option4_content: "Benefit from responsive customer support that is available to quickly answer questions and resolve issues. Responsive customer service is essential to retain your customers and strengthen their trust in your brand.",
                commerce_join_us_text: "With Cit Express, you can transform your retail and e-commerce business by ensuring optimized, cost-effective, and customer-focused delivery and management services. Our tailor-made solutions are designed to meet the specific needs of your business, guaranteeing an exceptional shopping experience for your customers. Trust Cit Express to boost your online business and increase your market competitiveness."




            }
        }
    }
}
