<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section">
        <section style="margin-top: 100px; margin-bottom: 70px; padding-top: 30px; margin-bottom: 100px;">
            <div class="container">
                <div class="row">
                    <div class="col-6 d-flex align-items-center">
                        <div style="margin-top: -120px;">
                            <p style="font-weight: bold; color: #CF0020; font-size: 3.3em;">OOOPS!</p>
                            <p style="font-weight: bold; color: #CF0020; font-size: 3.3em;">ERRUR 404</p>
                        </div>
                    </div>

                    <div class="col-6 d-flex align-items-center">
                        <img src="../assets/img/not_found.jpg">
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="ipad-section">
        <section style="margin-top: 100px; padding-top: 30px;">
            <div class="container">
                <div class="row">
                    <div class="col-6 d-flex align-items-center">
                        <div style="margin-top: -120px;">
                            <p style="font-weight: bold; color: #CF0020; font-size: 3.3em;">OOOPS!</p>
                            <p style="font-weight: bold; color: #CF0020; font-size: 3.3em;">ERRUR 404</p>
                        </div>
                    </div>
                    <div class="col-6 d-flex align-items-center">
                        <img src="../assets/img/not_found.jpg">
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <section style="margin-top: 100px; padding-top: 30px; margin-bottom: 50px;">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div style="padding: 20px;">
                            <p style="font-weight: bold; color: #CF0020; font-size: 3.3em;">OOOPS!</p>
                            <p style="font-weight: bold; color: #CF0020; font-size: 3.3em;">ERRUR 404</p>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center">
                        <img src="../assets/img/not_found.jpg">
                    </div>
                    
                </div>
            </div>
        </section>
    </section>






</template>





<script>
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';

export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = '404 Page not found | CITExpress';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
    }
}
</script>
