import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import NotreFlotte from '../views/NotreFlotte.vue'
import AboutUs from '../views/AboutUs.vue'
import Iso9001 from '@/views/Iso9001.vue'
import SecteurLivraison from '../views/SecteurLivraison.vue'
import Technologies from '../views/Technologies.vue'
import Technologie1 from '../views/Technologie1.vue'
import Technologie2 from '../views/Technologie2.vue'
import Technologie3 from '../views/Technologie3.vue'
import Quote from '../views/Quote.vue'
import ContactUS from '../views/ContactUS.vue'
import Construction from '../views/Construction.vue'
import NotFound from '../views/NotFound.vue'
import SecteurMedicale from '@/views/SecteurMedicale.vue'
import Recherche_Developpment from '@/views/Recherche_Developpment.vue'
import Secteur_Industrie from '@/views/Secteur_Industrie.vue'
import Consultance_Formation from '@/views/Consultance_Formation.vue'
import Technologie_Electronique from '@/views/Technologie_Electronique.vue'
import Commerce from '@/views/Commerce.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/notre-flotte',
    name: 'NotreFlotte',
    component: NotreFlotte
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/iso-9001-2018',
    name: 'Iso9001',
    component: Iso9001
  },
  {
    path: '/secteurs-livraison',
    name: 'SecteurLivraison',
    component: SecteurLivraison
  },
  {
    path: '/technologies',
    name: 'Technologies',
    component: Technologies
  },
  {
    path: '/technologie1',
    name: 'Technologie1',
    component: Technologie1
  },
  {
    path: '/technologie2',
    name: 'Technologie2',
    component: Technologie2
  },
  {
    path: '/technologie3',
    name: 'Technologie3',
    component: Technologie3
  },
  {
    path: '/quote',
    name: 'Quote',
    component: Quote
  },
  {
    path: '/contact',
    name: 'ContactUS',
    component: ContactUS
  },
  {
    path: '/construction',
    name: 'Construction',
    component: Construction
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/secteur_medicale',
    name: 'SecteurMedicale',
    component: SecteurMedicale
  },
  {
    path: '/recherche_developpment',
    name: 'Recherche_Developpment',
    component: Recherche_Developpment
  },
  {
    path: '/secteur_industrie',
    name: 'Secteur_Industrie',
    component: Secteur_Industrie
  },
  {
    path: '/consultance_formation',
    name: 'Consultance_Formation',
    component: Consultance_Formation
  },
  {
    path: '/technologie_electronique',
    name: 'Technologie_Electronique',
    component: Technologie_Electronique
  },
  {
    path: '/commerce',
    name: 'Commerce',
    component: Commerce
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
