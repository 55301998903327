<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section" style="margin-top: 50px; margin-bottom: 150px; padding-top: 65px">
        <div class="container" style="padding-top: 80px;">
            <div class="row">
                <div class="col-6 text-center">
                    <p style="text-align: left; padding-left: 45px; font-weight: 600; color: #CF0020; text-transform: uppercase; font-size: 1.3em;">
                        {{ translate('login_text1') }}
                    </p>
                    <p style="text-align: left; padding-left: 45px; font-weight: 600; color: #CF0020; font-size: 1.3em;">
                        {{ translate('login_text2') }}
                    </p>
                    <img src="../assets/img/login_image.jpg" style="margin-top: 15px;">
                </div>
                <div class="col-6 text-center">
                    <div class="border" style="border-radius: 15px; padding-top: 80px; padding-bottom: 80px; color: black; font-size: 1.3em;">
                        {{ translate('form_header') }}
                        <br>
                        <input style="margin-top: 40px;" type="text" class="custom-input" :placeholder="translate('username')" v-model="username">
                        <br>
                        <input style="margin-top: 30px;" type="password" class="custom-input" :placeholder="translate('password_placeholder')" v-model="password">
                        <br>
                        <button class="btn btn-danger btn-connect" @click="submitLogin">{{ translate('login') }}</button>
                        <br>
                        <p style="margin-top: 50px; font-weight: 600">{{ translate('not_yet_subscribed') }}</p>
                        <button style="margin-top: 30px;" class="btn btn-danger btn-connect">{{ translate('subscribe') }}</button>
                    </div>
                </div>
            </div>
        </div>  
    </section>

    <section class="ipad-section" style="margin-bottom: 150px; padding-top: 65px">
        <div class="container" style="padding-top: 80px;">
            <div class="row">
                <div class="col-12 text-center">
                    <p style="text-align: center; font-weight: 600; color: #CF0020; text-transform: uppercase; font-size: 1.3em;">
                        {{ translate('login_text1') }}
                    </p>
                    <p style="text-align: center; font-weight: 600; color: #CF0020; font-size: 1.3em;">
                        {{ translate('login_text2') }}
                    </p>
                    <img src="../assets/img/login_image.jpg" style="margin-top: 15px;">
                </div>
                <div class="col-12 text-center">
                    <div class="border" style="border-radius: 15px; padding-top: 80px; padding-bottom: 80px; color: black; font-size: 1.3em; margin-top: 20px;">
                        {{ translate('form_header') }}
                        <br>
                        <input style="margin-top: 40px;" type="text" class="custom-input" :placeholder="translate('email_placeholder')" v-model="username">
                        <br>
                        <input style="margin-top: 30px;" type="password" class="custom-input" :placeholder="translate('password_placeholder')" v-model="password">
                        <br>
                        <button class="btn btn-danger btn-connect" @click="submitLogin">{{ translate('login') }}</button>
                        <br>
                        <p style="margin-top: 50px; font-weight: 600">{{ translate('not_yet_subscribed') }}</p>
                        <button style="margin-top: 30px;" class="btn btn-danger btn-connect">{{ translate('subscribe') }}</button>
                    </div>
                </div>
            </div>
        </div>  
    </section>

    <section class="mobile-section" style="margin-bottom: 150px; padding-top: 65px">
        <div class="container" style="padding-top: 80px;">
            <div class="row">
                <div class="col-12 text-center">
                    <p style="text-align: center; font-weight: 600; color: #CF0020; text-transform: uppercase; font-size: 1.3em;">
                        {{ translate('login_text1') }}
                    </p>
                    <p style="text-align: center; font-weight: 600; color: #CF0020; font-size: 1.3em;">
                        {{ translate('login_text2') }}
                    </p>
                    <img src="../assets/img/login_image.jpg" style="margin-top: 15px;">
                </div>
                <div class="col-12 text-center">
                    <div class="border" style="border-radius: 15px; padding-top: 80px; padding-bottom: 80px; color: black; font-size: 1.3em; margin-top: 20px;">
                        {{ translate('form_header') }}
                        <br>
                        <input style="margin-top: 40px;" type="text" class="custom-input" :placeholder="translate('email_placeholder')" v-model="username">
                        <br>
                        <input style="margin-top: 30px;" type="password" class="custom-input" :placeholder="translate('password_placeholder')" v-model="password">
                        <br>
                        <button class="btn btn-danger btn-connect" @click="submitLogin">{{ translate('login') }}</button>
                        <br>
                        <p style="margin-top: 50px; font-weight: 600">{{ translate('not_yet_subscribed') }}</p>
                        <button style="margin-top: 30px;" class="btn btn-danger btn-connect">{{ translate('subscribe') }}</button>
                    </div>
                </div>
            </div>
        </div>  
    </section>

</template>


<script>
import axios from 'axios';
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';


export default {
    mixins: [en, fr, nl],
    components: {
        MainNav
    },
    data() {
        return {
            lang: this.$store.state.lang,
            username : "",
            password : ""
            
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        }
    },
    mounted() {
        document.title = 'CITExpress | Login';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        submitLogin(){
            const loginUrl = `https://www.citexpress.net/WOPS_CIT?uname=${encodeURIComponent(this.username)}&upass=${encodeURIComponent(this.password)}`
            window.location.href = loginUrl;
        }
    }
}
</script>



<style>
.custom-input{
    border-radius: 8px;
    border: 1px solid #9B9B9B;
    background-color: #F5F5F5;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 300px;
}
.btn-connect{
    background-color: #CF0020;
    border-radius: 8px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 50px;
}
.btn-connect:hover{
    background-color: #940B20;
}
.btn-connect:focus{
    background-color: #940B20;
}
</style>