<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section">
        <section class="background-container" style="margin-top: 50px; padding-bottom: 50px; height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">

            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5" style="margin-left: 120px; margin-top: 150px;">
                    <div class="col-md-8">
                        <p style="color: white; font-weight: bold; font-size: 3em;">{{
                            translate('secteur_industrie_title') }}</p>
                        <p style="color: white; font-weight: 400; font-size: 2em;">{{
                            translate('secteur_industrie_sub_title') }}</p>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>
        </section>

        <section class="container" style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <h1 style="text-align: center; color: black; font-weight: 600; font-size: 1.8em; margin-bottom: 50px;">{{
                translate('secteur_industrie_section1_title') }}</h1>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon1.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option1_title') }}</h5>
                    </div>
                    <button @click="toggleContent1" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon1"></i>
                    </button>
                </div>
                <div v-if="isOpen1" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option1_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon2.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option2_title') }}</h5>
                    </div>
                    <button @click="toggleContent2" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon2"></i>
                    </button>
                </div>
                <div v-if="isOpen2" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option2_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #FFECEF; border-radius: 20px;">
                <div class="box-header d-flex justify-content-center align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_section1_limit') }}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section class="container" style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <h1 style="text-align: center; color: black; font-weight: 600; font-size: 1.8em; margin-bottom: 50px;">{{
                translate('secteur_industrie_section2_title') }}</h1>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon3.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option3_title') }}</h5>
                    </div>
                    <button @click="toggleContent3" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon3"></i>
                    </button>
                </div>
                <div v-if="isOpen3" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option3_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon4.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option4_title') }}</h5>
                    </div>
                    <button @click="toggleContent4" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon4"></i>
                    </button>
                </div>
                <div v-if="isOpen4" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option4_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #FFECEF; border-radius: 20px;">
                <div class="box-header d-flex justify-content-center align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_section2_limit') }}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section class="container" style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <h1 style="text-align: center; color: black; font-weight: 600; font-size: 1.8em; margin-bottom: 50px;">{{
                translate('secteur_industrie_section3_title') }}</h1>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon5.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option5_title') }}</h5>
                    </div>
                    <button @click="toggleContent5" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon5"></i>
                    </button>
                </div>
                <div v-if="isOpen5" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option5_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon6.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option6_title') }}</h5>
                    </div>
                    <button @click="toggleContent6" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon6"></i>
                    </button>
                </div>
                <div v-if="isOpen6" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option6_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #FFECEF; border-radius: 20px;">
                <div class="box-header d-flex justify-content-center align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_section3_limit') }}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 30px; padding-top: 50px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage5 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-md-8">
                        <div class="d-flex align-items-center" style="border-radius: 10px; padding: 0px;">
                            <router-link to="/quote">
                                <button class="custom-btn2">{{ translate('join_us_button') }} </button>
                            </router-link>
                            <div style="text-align: left; margin-left: 10px; font-size: large; color: black;">
                                <span>{{ translate('secteur_industrie_join_us') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <section class="background-container" style="margin-top: 20px; padding-bottom: 20px; height: 400px;"
            :style="{ backgroundImage: 'url(' + backgroundImage6 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">

            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5" style="margin-left: 0; margin-top: 50px;">
                    <div class="col-12 text-center">
                        <p style="color: white; font-weight: bold; font-size: 2em;">{{
                            translate('secteur_industrie_title') }}</p>
                        <p style="color: white; font-weight: 400; font-size: 1.2em;">{{
                            translate('secteur_industrie_sub_title') }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="container" style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <h1 style="text-align: center; color: black; font-weight: 600; font-size: 1.2em; margin-bottom: 50px;">{{
                translate('secteur_industrie_section1_title') }}</h1>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon1.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option1_title') }}</h5>
                    </div>
                    <button @click="toggleContent1" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon1"></i>
                    </button>
                </div>
                <div v-if="isOpen1" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option1_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon2.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option2_title') }}</h5>
                    </div>
                    <button @click="toggleContent2" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon2"></i>
                    </button>
                </div>
                <div v-if="isOpen2" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option2_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #FFECEF; border-radius: 20px;">
                <div class="box-header d-flex justify-content-center align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_section1_limit') }}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section class="container" style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <h1 style="text-align: center; color: black; font-weight: 600; font-size: 1.2em; margin-bottom: 50px;">{{
                translate('secteur_industrie_section2_title') }}</h1>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon3.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option3_title') }}</h5>
                    </div>
                    <button @click="toggleContent3" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon3"></i>
                    </button>
                </div>
                <div v-if="isOpen3" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option3_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon4.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option4_title') }}</h5>
                    </div>
                    <button @click="toggleContent4" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon4"></i>
                    </button>
                </div>
                <div v-if="isOpen4" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option4_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #FFECEF; border-radius: 20px;">
                <div class="box-header d-flex justify-content-center align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_section2_limit') }}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section class="container" style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <h1 style="text-align: center; color: black; font-weight: 600; font-size: 1.2em; margin-bottom: 50px;">{{
                translate('secteur_industrie_section3_title') }}</h1>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon5.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option5_title') }}</h5>
                    </div>
                    <button @click="toggleContent5" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon5"></i>
                    </button>
                </div>
                <div v-if="isOpen5" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option5_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon6.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option6_title') }}</h5>
                    </div>
                    <button @click="toggleContent6" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon6"></i>
                    </button>
                </div>
                <div v-if="isOpen6" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option6_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #FFECEF; border-radius: 20px;">
                <div class="box-header d-flex justify-content-center align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_section3_limit') }}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 30px; padding-bottom: 30px;"
            :style="{ backgroundImage: 'url(' + backgroundImage5 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-12">
                        <div class="d-flex flex-column" style="border-radius: 10px; padding: 0px;">
                            <div style="text-align: center; flex-grow: 1; font-size: medium; color: black;">
                                <span>{{ translate('secteur_industrie_join_us') }}</span>
                                <br>
                                <br>
                                <router-link to="/quote">
                                    <button class="custom-btn2">{{ translate('join_us_button') }} <i
                                            class="fa fa-chevron-right"></i></button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>


    <section class="ipad-section">
        <section class="background-container" style="margin-top: 30px; padding-bottom: 30px; height: 400px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">

            <div class="container-fluid"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5" style="margin-left: 60px; margin-top: 100px;">
                    <div class="col-md-8">
                        <p style="color: white; font-weight: bold; font-size: 2em;">{{
                            translate('secteur_industrie_title') }}</p>
                        <p style="color: white; font-weight: 400; font-size: 1.5em;">{{
                            translate('secteur_industrie_sub_title') }}</p>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>
        </section>

        <section class="container" style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <h1 style="text-align: center; color: black; font-weight: 600; font-size: 1.8em; margin-bottom: 50px;">{{
                translate('secteur_industrie_section1_title') }}</h1>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon1.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option1_title') }}</h5>
                    </div>
                    <button @click="toggleContent1" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon1"></i>
                    </button>
                </div>
                <div v-if="isOpen1" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option1_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon2.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option2_title') }}</h5>
                    </div>
                    <button @click="toggleContent2" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon2"></i>
                    </button>
                </div>
                <div v-if="isOpen2" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option2_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #FFECEF; border-radius: 20px;">
                <div class="box-header d-flex justify-content-center align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_section1_limit') }}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section class="container" style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <h1 style="text-align: center; color: black; font-weight: 600; font-size: 1.8em; margin-bottom: 50px;">{{
                translate('secteur_industrie_section2_title') }}</h1>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon3.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option3_title') }}</h5>
                    </div>
                    <button @click="toggleContent3" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon3"></i>
                    </button>
                </div>
                <div v-if="isOpen3" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option3_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon4.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option4_title') }}</h5>
                    </div>
                    <button @click="toggleContent4" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon4"></i>
                    </button>
                </div>
                <div v-if="isOpen4" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option4_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #FFECEF; border-radius: 20px;">
                <div class="box-header d-flex justify-content-center align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_section2_limit') }}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section class="container" style="margin-top: 50px; padding-top: 0; padding-bottom: 0;">
            <h1 style="text-align: center; color: black; font-weight: 600; font-size: 1.8em; margin-bottom: 50px;">{{
                translate('secteur_industrie_section3_title') }}</h1>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon5.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option5_title') }}</h5>
                    </div>
                    <button @click="toggleContent5" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon5"></i>
                    </button>
                </div>
                <div v-if="isOpen5" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option5_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #F7F7F7; border-radius: 20px;">
                <div class="box-header d-flex justify-content-between align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <img src="../assets/img/secteur-industrie-icon6.svg" style="max-width: 60px;">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_option6_title') }}</h5>
                    </div>
                    <button @click="toggleContent6" class="btn btn-link text-decoration-none" style="color: #CF0020;">
                        <i :class="toggleIcon6"></i>
                    </button>
                </div>
                <div v-if="isOpen6" class="box-content mt-3">
                    <p style="color: black;">{{ translate('secteur_industrie_option6_content') }}</p>
                </div>
            </div>
            <div class="box" style="background-color: #FFECEF; border-radius: 20px;">
                <div class="box-header d-flex justify-content-center align-items-center">
                    <div class="box-title d-flex align-items-center">
                        <h5 class="ms-2 mb-0" style="color: #CF0020; font-size: large;">{{
                            translate('secteur_industrie_section3_limit') }}</h5>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 30px; padding-top: 50px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage5 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">
            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-md-12">
                        <div class="d-flex align-items-center" style="border-radius: 10px; padding: 0px;">
                            <router-link to="/quote">
                                <button class="custom-btn2">{{ translate('join_us_button') }} </button>
                            </router-link>
                            <div style="text-align: left; margin-left: 10px; font-size: large; color: black;">
                                <span>{{ translate('secteur_industrie_join_us') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

</template>





<script>
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/secteur-industrie-d.jpg';
import BackgroundImage2 from '../assets/img/iso_background.jpg';
import BackgroundImage3 from '../assets/img/iso_mobile_back.jpg';
import BackgroundImage4 from '../assets/img/sectipn-text-back.jpg';
import BackgroundImage5 from '../assets/img/join_us_back.jpg';
import BackgroundImage6 from '../assets/img/secteur-industrie-m.jpg';



export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            backgroundImage3: BackgroundImage3,
            backgroundImage4: BackgroundImage4,
            backgroundImage5: BackgroundImage5,
            backgroundImage6: BackgroundImage6,
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            isOpen6: false,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
        toggleIcon1() {
            return this.isOpen1 ? 'fa fa-minus' : 'fa fa-plus';
        },
        toggleIcon2() {
            return this.isOpen2 ? 'fa fa-minus' : 'fa fa-plus';
        },
        toggleIcon3() {
            return this.isOpen3 ? 'fa fa-minus' : 'fa fa-plus';
        },
        toggleIcon4() {
            return this.isOpen4 ? 'fa fa-minus' : 'fa fa-plus';
        },
        toggleIcon5() {
            return this.isOpen5 ? 'fa fa-minus' : 'fa fa-plus';
        },
        toggleIcon6() {
            return this.isOpen6 ? 'fa fa-minus' : 'fa fa-plus';
        },
    },
    mounted() {
        document.title = 'CITExpress | Secteur Médical';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        toggleContent1() {
            this.isOpen1 = !this.isOpen1;
        },
        toggleContent2() {
            this.isOpen2 = !this.isOpen2;
        },
        toggleContent3() {
            this.isOpen3 = !this.isOpen3;
        },
        toggleContent4() {
            this.isOpen4 = !this.isOpen4;
        },
        toggleContent5() {
            this.isOpen5 = !this.isOpen5;
        },
        toggleContent6() {
            this.isOpen6 = !this.isOpen6;
        },
    }
}
</script>

<style scoped>
.service-box {
    background-color: rgba(207, 0, 32, 0.5);
    color: white;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    height: 100%;
    cursor: pointer;
}

.service-box:hover {
    background-color: rgba(82, 79, 80, 0.5);
    border: 2px solid #efefef;
}

.service-box-mobile {
    border: 1px solid #CF0020;
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 250px;
    border-radius: 20px;
}

.laptop-header header .navbar {
    top: 0;
    left: 0;
}

.custom-btn {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn:hover {
    background-color: #CF0020;
    color: white;
}

.solution-box {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 1px solid white;
    text-align: center;
    color: black;
    padding: 25px;
    height: 100%;
}

.custom-btn3 {
    background-color: #CF0020;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 150px;
}

.custom-btn3:hover {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
}

.review-box {
    background-color: white;
    border-radius: 20px;
    border: 1px solid white;
    color: black;
    padding: 25px;
    margin-left: 15px;
}

.laptop-section {
    display: block;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: block;
    }

    .ipad-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}

.btn-contact {
    background-color: #CF0020;
    border: none;
    margin-right: 20px;
}

.btn-contact:hover {
    background-color: #940B20;
}

.btn-contact-mobile {
    background-color: #CF0020;
    border: none;
}

.btn-contact-mobile:hover {
    background-color: #940B20;
}

.custom-btn2 {
    background-color: #CF0020;
    border: 1px solid;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 150px;
    border-radius: 15px;
    margin-bottom: 15px;
}

.custom-btn2:hover {
    background-color: #8d0b1e;
    color: white;
}
</style>