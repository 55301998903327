<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section">
        <section style="padding-top: 100px; padding-bottom: 50px; margin-top: 70px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <div>
                            <p style="font-weight: bold; font-size: 2.3em; color: white;">{{
                translate('contactus_header') }}</p>
                            <p style="color: black; margin-top: 15px; color: white; font-size: 1.3em;">{{
                translate('contactus_sheader1') }} <br> {{ translate('contactus_sheader2') }}</p>

                            <form class="row g-3 custom-form" @submit.prevent="submitForm">
                                <div class="col-md-6">
                                    <label for="input1" class="form-label"></label>
                                    <input type="text" class="form-control"
                                        :placeholder="translate('contactus_form_input1')" id="input1" v-model="nom">
                                </div>
                                <div class="col-md-6">
                                    <label for="input2" class="form-label"></label>
                                    <input type="text" class="form-control"
                                        :placeholder="translate('contactus_form_input2')" id="input2" v-model="company">
                                </div>
                                <div class="col-md-6">
                                    <label for="input3" class="form-label"></label>
                                    <input type="text" class="form-control"
                                        :placeholder="translate('contactus_form_input3')" id="input3" v-model="phone">
                                </div>
                                <div class="col-md-6">
                                    <label for="input4" class="form-label"></label>
                                    <input type="email" class="form-control"
                                        :placeholder="translate('contactus_form_input4')" id="input4" v-model="email">
                                </div>
                                <div class="col-12">
                                    <label for="input5" class="form-label"></label>
                                    <input type="text" class="form-control"
                                        :placeholder="translate('contactus_form_input5')" id="input5" v-model="subject">
                                </div>
                                <div class="col-12">
                                    <label for="input6" class="form-label"></label>
                                    <textarea class="form-control" :placeholder="translate('contactus_form_input6')"
                                        id="input6" rows="4" v-model="message"></textarea>

                                </div>
                                <div class="col-12" style="text-align: right;">
                                    <button type="submit" class="btn btn-danger">{{ translate('contactus_form_button')
                                        }} <img style="margin-left: 8px; max-width: 22px;"
                                            src="../assets/img/send_icon.svg"> </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 50px; margin-bottom: 70px;">

            <div class="container">
                <div class="row">
                    <div class="col-6" ref="map" style="border-radius: 20px; height: 500px;">
                    </div>

                    <div class="col-6 d-flex align-items-center">
                        <div class="row justify-content-center" style="text-align: center; width: 100%;">
                            <div class="col-8">
                                <div
                                    style="text-align: center; background-color: #FAEFF0; padding: 30px; border-radius: 20px; font-size: 1.3em; color: black;">
                                    <span>{{ translate('contactus_text1') }}</span> <br>
                                    <span style="color: #CF0020;"><img src="../assets/img/phone_icon.svg"
                                            style="margin-right: 10px;"> 0032 2 533 21 41</span><br>
                                    <span>{{ translate('contactus_text2') }}</span> <br>
                                    <span style="color: #CF0020;"><img src="../assets/img/time_icon.svg"
                                            style="margin-right: 10px;"> 8:00h - 20:00h</span><br>
                                    <span>{{ translate('contactus_text3') }}</span>
                                </div>
                                <div
                                    style="color: #CF0020; font-size: 1.3em; margin-top: 20px; padding-left: 80px; text-align: left;">
                                    <span><img src="../assets/img/email_icon.svg" style="margin-right: 10px;">
                                        info@citexpress.com</span>
                                    <br>
                                    <span><img src="../assets/img/map_icon.svg" style="margin-right: 10px;"> Chaussée de
                                        Wavre 1663, 1160 Auderghem, Bruxelles, Belgium</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="ipad-section">
        <section style="padding-top: 60px; padding-bottom: 50px; margin-top: 60px;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-9">
                        <div>
                            <p style="font-weight: bold; font-size: 2.3em; color: white;">{{
                translate('contactus_header') }}</p>
                            <p style="color: black; margin-top: 15px; color: white; font-size: 1.3em;">{{
                translate('contactus_sheader1') }} <br> {{ translate('contactus_sheader2') }}</p>

                            <form class="row g-3 custom-form" @submit.prevent="submitForm">
                                <div class="col-md-6">
                                    <label for="input1" class="form-label"></label>
                                    <input type="text" class="form-control"
                                        :placeholder="translate('contactus_form_input1')" id="input1" v-model="nom">
                                </div>
                                <div class="col-md-6">
                                    <label for="input2" class="form-label"></label>
                                    <input type="text" class="form-control"
                                        :placeholder="translate('contactus_form_input2')" id="input2" v-model="company">
                                </div>
                                <div class="col-md-6">
                                    <label for="input3" class="form-label"></label>
                                    <input type="text" class="form-control"
                                        :placeholder="translate('contactus_form_input3')" id="input3" v-model="phone">
                                </div>
                                <div class="col-md-6">
                                    <label for="input4" class="form-label"></label>
                                    <input type="email" class="form-control"
                                        :placeholder="translate('contactus_form_input4')" id="input4" v-model="email">
                                </div>
                                <div class="col-12">
                                    <label for="input5" class="form-label"></label>
                                    <input type="text" class="form-control"
                                        :placeholder="translate('contactus_form_input5')" id="input5" v-model="subject">
                                </div>
                                <div class="col-12">
                                    <label for="input6" class="form-label"></label>
                                    <textarea class="form-control" :placeholder="translate('contactus_form_input6')"
                                        id="input6" rows="4" v-model="message"></textarea>

                                </div>
                                <div class="col-12" style="text-align: right;">
                                    <button type="submit" class="btn btn-danger">{{ translate('contactus_form_button')
                                        }} <img style="margin-left: 8px; max-width: 22px;"
                                            src="../assets/img/send_icon.svg"> </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 50px; margin-bottom: 70px;">

            <div class="container-fluid" style="padding-left: 30px; padding-right: 30px;">
                <div class="row">
                    <div class="col-6" ref="map2" style="border-radius: 20px;">
                    </div>

                    <div class="col-6 d-flex align-items-center">
                        <div class="row justify-content-center" style="text-align: center; width: 100%;">
                            <div class="col-12">
                                <div
                                    style="text-align: center; background-color: #FAEFF0; padding: 30px; border-radius: 20px; font-size: 1.3em; color: black;">
                                    <span>{{ translate('contactus_text1') }}</span> <br>
                                    <span style="color: #CF0020;"><img src="../assets/img/phone_icon.svg"
                                            style="margin-right: 10px;"> 0032 2 533 21 41</span><br>
                                    <span>{{ translate('contactus_text2') }}</span> <br>
                                    <span style="color: #CF0020;"><img src="../assets/img/time_icon.svg"
                                            style="margin-right: 10px;"> 8:00h - 20:00h</span><br>
                                    <span>{{ translate('contactus_text3') }}</span>
                                </div>
                                <div style="color: #CF0020; font-size: 1.3em; margin-top: 15px; text-align: center;">
                                    <span><img src="../assets/img/email_icon.svg" style="margin-right: 10px;">
                                        info@citexpress.com</span>
                                    <br>
                                    <span><img src="../assets/img/map_icon.svg" style="margin-right: 10px;"> Chaussée de
                                        Wavre 1663, 1160 Auderghem, Bruxelles, Belgium</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <div class="laptop-header" style="margin-top: 50px;">
            <header class="position-relative">
                <img src="../assets/img/contacus_header_mobile.jpg" class="d-block w-100" alt="Background Image">

                <div class="position-absolute bottom-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-bottom: 15px;">
                    <p style="font-weight: bold; font-size: 1.5em; padding-left: 20px; padding-right: 20px;">
                        {{ translate('contactus_header') }}</p>
                    <p
                        style="font-size: 1.3em; font-weight: 400; padding-left: 20px; padding-right: 20px; margin-top: 15px;">
                        {{ translate('contactus_sheader1') }} <br> {{ translate('contactus_sheader2') }}
                    </p>
                    <br>
                </div>
            </header>
        </div>

        <section style="margin-top: 0px; margin-bottom: 70px; background-color: #CF0020; padding-bottom: 30px;">
            <div class="container">
                <form class="row g-3 custom-form" @submit.prevent="submitForm">
                    <div class="col-md-6">
                        <label for="input1" class="form-label"></label>
                        <input type="text" class="form-control" :placeholder="translate('contactus_form_input1')"
                            id="input1" v-model="nom">
                    </div>
                    <div class="col-md-6">
                        <label for="input2" class="form-label"></label>
                        <input type="text" class="form-control" :placeholder="translate('contactus_form_input2')"
                            id="input2" v-model="company">
                    </div>
                    <div class="col-md-6">
                        <label for="input3" class="form-label"></label>
                        <input type="text" class="form-control" :placeholder="translate('contactus_form_input3')"
                            id="input3" v-model="phone">
                    </div>
                    <div class="col-md-6">
                        <label for="input4" class="form-label"></label>
                        <input type="email" class="form-control" :placeholder="translate('contactus_form_input4')"
                            id="input4" v-model="email">
                    </div>
                    <div class="col-12">
                        <label for="input5" class="form-label"></label>
                        <input type="text" class="form-control" :placeholder="translate('contactus_form_input5')"
                            id="input5" v-model="subject">
                    </div>
                    <div class="col-12">
                        <label for="input6" class="form-label"></label>
                        <textarea class="form-control" :placeholder="translate('contactus_form_input6')" id="input6"
                            rows="4" v-model="message"></textarea>

                    </div>
                    <div class="col-12" style="text-align: center;">
                        <button type="submit" class="btn btn-danger">{{ translate('contactus_form_button')
                            }} <img style="margin-left: 8px; max-width: 22px;" src="../assets/img/send_icon.svg">
                        </button>
                    </div>
                </form>
            </div>
        </section>

        <section style="margin-top: 50px; margin-bottom: 70px;">
            <div class="container-fluid" style="padding-left: 30px; padding-right: 30px;">
                <div class="row">
                    <div class="col-12">
                        <div class="row justify-content-center" style="text-align: center;">
                            <div class="col-12">
                                <div
                                    style="text-align: center; background-color: #FAEFF0; padding: 30px; border-radius: 20px; font-size: 1.3em; color: black;">
                                    <span>{{ translate('contactus_text1') }}</span> <br>
                                    <span style="color: #CF0020;"><img src="../assets/img/phone_icon.svg"
                                            style="margin-right: 10px;"> 0032 2 533 21 41</span><br>
                                    <span>{{ translate('contactus_text2') }}</span> <br>
                                    <span style="color: #CF0020;"><img src="../assets/img/time_icon.svg"
                                            style="margin-right: 10px;"> 8:00h - 20:00h</span><br>
                                    <span>{{ translate('contactus_text3') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="margin-top: 50px">
                        <div ref="map3" style="border-radius: 20px; height: 400px; width: 100%;"></div>

                        <div style="color: #CF0020; font-size: 1.3em; margin-top: 20px; text-align: center;">
                            <span><img src="../assets/img/email_icon.svg" style="margin-right: 10px;">
                                info@citexpress.com</span>
                            <br>
                            <span><img src="../assets/img/map_icon.svg" style="margin-right: 10px;"> Chaussée de
                                Wavre 1663, 1160 Auderghem, Bruxelles, Belgium</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>




</template>





<script>
import axios from 'axios';
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/contacus_header.jpg';
import BackgroundImage2 from '../assets/img/contacus_header_ipad.jpg';
import { Loader } from '@googlemaps/js-api-loader';
import { toast } from 'bulma-toast'



export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            nom: '',
            company: '',
            phone: '',
            email: '',
            subject: '',
            message: '',
            map: null,
            map2: null,
            map3: null
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'Contact US | CITExpress';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        const loader = new Loader({
            apiKey: 'AIzaSyCsjs1PBTuMIuGM4kKeVt3NKa6QqiD7yr0',
            version: 'weekly',
        });

        loader.load().then(() => {
            this.map = new google.maps.Map(this.$refs.map, {
                center: { lat: 50.816990, lng: 4.423000 },
                zoom: 15,
            });

            new google.maps.Marker({
                position: { lat: 50.816990, lng: 4.423000 },
                map: this.map,
                title: 'CITExpress',
            });
        });
        //map2
        loader.load().then(() => {
            this.map2 = new google.maps.Map(this.$refs.map2, {
                center: { lat: 50.816990, lng: 4.423000 },
                zoom: 15,
            });

            new google.maps.Marker({
                position: { lat: 50.816990, lng: 4.423000 },
                map: this.map2,
                title: 'CITExpress',
            });
        });
        //map3
        loader.load().then(() => {
            this.map3 = new google.maps.Map(this.$refs.map3, {
                center: { lat: 50.816990, lng: 4.423000 },
                zoom: 15,
            });

            new google.maps.Marker({
                position: { lat: 50.816990, lng: 4.423000 },
                map: this.map3,
                title: 'CITExpress',
            });
        });

    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        submitForm() {
            const formData = {
                name: this.nom,
                company: this.company,
                phone: this.phone,
                email: this.email,
                subject: this.subject,
                message: this.message
            };

            axios.post('/api/v1/contactus-cit/', formData)
                .then(response => {
                    toast({
                            message: this.translate('contactus_message'),
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 5000,
                            position: 'top-right',
                        })

                    console.log('Form submitted successfully:', response.data);
                    this.resetForm();
                })
                .catch(error => {
                    console.error('Error submitting form:', error);
                });
        },
        resetForm() {
            this.nom = '';
            this.company = '';
            this.phone = '';
            this.email = '';
            this.subject = '';
            this.message = '';
        }
    }
}
</script>

<style>
.custom-form button {
    background-color: white;
    color: #CF0020;
    border: 2px solid #CF0020;
    border-radius: 10px;
    margin-top: 20px;
}

.custom-form button:hover {
    background-color: #FCEEF0;
    color: #CF0020;
    border: 2px solid #FCEEF0;
    border-radius: 10px;
}
</style>