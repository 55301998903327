<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section">
        <section :key="animationKey" class="background-container" style="padding-bottom: 50px; height: 750px;"
            :style="{ backgroundImage: 'url(' + backgroundImage11 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover', 'animation': 'slideFromRight 1.5s forwards' }">



            <div v-if="currenImageHeaderIndex == 0" class="container" :class="{ 'slide-text-animation': animateText }"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5">
                    <div class="col-md-10">
                        <span style="color: white; font-size: 2.2em; font-weight: 600;">
                            {{ translate('header_text1') }} <br> {{ translate('header_text2') }}
                        </span>
                        <br>
                        <br>
                        <span style="color: white; font-size: 1.5em; font-weight: 400;">
                            {{ translate('header_text3') }}
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center text-center"
                    style="margin-bottom: -220px; margin-top: 120px; width: 100%;">
                    <div class="col-md-3 slide-text-animation-bottom">
                        <router-link to="/login">
                            <div class="service-box">
                                <img src="../assets/img/home_icon3.svg">
                                <p style="margin-top: 5px;">{{ translate('action1_title') }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 slide-text-animation-bottom2">
                        <router-link to="/quote">
                            <div class="service-box">
                                <img src="../assets/img/home_icon2.svg">
                                <p style="margin-top: 5px;">{{ translate('action2_title') }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 slide-text-animation-bottom3">
                        <router-link to="/construction">
                            <div class="service-box">
                                <img src="../assets/img/home_icon1.svg">
                                <p style="margin-top: 5px;">{{ translate('action3_title') }}</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>

            <div v-if="currenImageHeaderIndex == 1" class="container" :class="{ 'slide-text-animation': animateText }"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5">
                    <div class="col-md-9">
                        <span style="color: white; font-size: 2.2em; font-weight: 600;">
                            {{ translate('header_text4') }} <br> {{ translate('header_text5') }}
                        </span>
                        <br>
                        <br>
                        <span style="color: white; font-size: 1.5em; font-weight: 400;">
                            {{ translate('header_text6') }}
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center text-center"
                    style="margin-bottom: -220px; margin-top: 120px; width: 100%;">
                    <div class="col-md-3 slide-text-animation-bottom">
                        <router-link to="/login">
                            <div class="service-box">
                                <img src="../assets/img/home_icon3.svg">
                                <p style="margin-top: 5px;">{{ translate('action1_title') }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 slide-text-animation-bottom2">
                        <router-link to="/quote">
                            <div class="service-box">
                                <img src="../assets/img/home_icon2.svg">
                                <p style="margin-top: 5px;">{{ translate('action2_title') }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 slide-text-animation-bottom3">
                        <router-link to="/construction">
                            <div class="service-box">
                                <img src="../assets/img/home_icon1.svg">
                                <p style="margin-top: 5px;">{{ translate('action3_title') }}</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>

            <div v-if="currenImageHeaderIndex == 2" class="container" :class="{ 'slide-text-animation': animateText }"
                style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
                <div class="row gx-5">
                    <div class="col-md-9">
                        <span style="color: white; font-size: 2.2em; font-weight: 600;">
                            {{ translate('header_text7') }} <br> {{ translate('header_text8') }}
                        </span>
                        <br>
                        <br>
                        <span style="color: white; font-size: 1.5em; font-weight: 400;">
                            {{ translate('header_text9') }}
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center text-center"
                    style="margin-bottom: -220px; margin-top: 120px; width: 100%;">
                    <div class="col-md-3 slide-text-animation-bottom">
                        <router-link to="/login">
                            <div class="service-box">
                                <img src="../assets/img/home_icon3.svg">
                                <p style="margin-top: 5px;">{{ translate('action1_title') }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 slide-text-animation-bottom2">
                        <router-link to="/quote">
                            <div class="service-box">
                                <img src="../assets/img/home_icon2.svg">
                                <p style="margin-top: 5px;">{{ translate('action2_title') }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 slide-text-animation-bottom3">
                        <router-link to="/construction">
                            <div class="service-box">
                                <img src="../assets/img/home_icon1.svg">
                                <p style="margin-top: 5px;">{{ translate('action3_title') }}</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 0px; padding-top: 70px; padding-bottom: 70px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-position': 'center center', 'background-attachment': 'fixed', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6">
                        <img src="../assets/img/cit_video.png">
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center"
                        style="color: black; font-weight: 600; font-size: x-large;">
                        <div class="text-center">
                            {{ translate('why_question1') }}
                        </div>
                        <div class="text-center" style="height: 100px;">
                            <img :src="currentImage" style="height: 100%; width: 200px; object-fit: contain;">
                        </div>
                        <div class="text-center">
                            {{ translate('why_question2') }}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 40px; padding-top: 50px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'bottom', 'background-size': 'cover' }">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 600; width: 40%;">
                {{ translate('section1_text1') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center">
                    <div class="col-md-3 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 20px; height: 100%; background-color: white;">
                            <div>
                                <img src="../assets/img/time.png">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                    translate('solution1_title')
                                }}</span>
                                <br>
                                <br>
                                <span>{{ translate('solution1_contenu') }}</span>
                            </div>
                            <div class="mt-auto">
                                <router-link to="/secteurs-livraison">
                                    <button class="custom-btn">{{ translate('see_more') }} <i
                                            class="fa fa-chevron-right"></i></button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 20px; height: 100%; background-color: white;">
                            <div>
                                <img src="../assets/img/quality.png">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                    translate('solution2_title')
                                }}</span>
                                <br>
                                <br>
                                <span>{{ translate('solution2_contenu') }}</span>
                            </div>
                            <div class="mt-auto">
                                <router-link to="/notre-flotte">
                                    <button class="custom-btn">{{ translate('see_more') }} <i
                                            class="fa fa-chevron-right"></i></button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 20px; height: 100%; background-color: white;">
                            <div>
                                <img src="../assets/img/price.png">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                    translate('solution3_title')
                                }}</span>
                                <br>
                                <br>
                                <span>{{ translate('solution3_contenu') }}</span>
                            </div>
                            <div class="mt-auto">
                                <router-link to="technologies">
                                    <button class="custom-btn">{{ translate('see_more') }} <i
                                            class="fa fa-chevron-right"></i></button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex">
                        <div class="border d-flex flex-column"
                            style="border-radius: 20px; height: 100%; background-color: white;">
                            <div>
                                <img src="../assets/img/services.png">
                            </div>
                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                    translate('solution4_title')
                                }}</span>
                                <br>
                                <br>
                                <span>{{ translate('solution4_contenu') }}</span>
                            </div>
                            <div class="mt-auto">
                                <router-link to="/iso-9001-2018">
                                    <button class="custom-btn">{{ translate('see_more') }} <i
                                            class="fa fa-chevron-right"></i></button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 60px; padding-bottom: 60px;">
            <div class="container">
                <div class="row">
                    <div class="col-md-6"
                        style="display: flex; flex-direction: column; align-items: left; justify-content: center;">
                        <p style="color: black; font-weight: bold; font-size: x-large;">
                            {{ translate('citexpress_name') }} <br>
                            {{ translate('citexpress_name_description') }}
                        </p>
                        <p style="margin-top: 20px; margin-bottom: 15px; color: black;">
                            {{ translate('citexpress_presentation') }}
                        </p>
                        <button class="custom-btn3">{{ translate('see_more') }} <i
                                class="fa fa-chevron-right"></i></button>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                        <img src="../assets/img/eurekali_map.png">
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 80px; margin-bottom: 120px;"
            :style="{ backgroundImage: 'url(' + backgroundImage4 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-4"
                        style="display: flex; flex-direction: column; align-items: left; justify-content: center;">
                        <p style="color: black; font-weight: bold; font-size: x-large;">
                            {{ translate('satisfied_clients') }}
                        </p>
                        <p style="margin-top: 20px; margin-bottom: 15px; color: black;">
                            {{ translate('satisfied_clients_description') }}
                        </p>
                        <button class="custom-btn4">{{ translate('give_review') }}</button>
                    </div>
                    <div class="col-md-8" style="text-align: right;">
                        <div class="row text-center justify-content-end">
                            <div class="col-md-4" style="margin-top: -70px;">
                                <div class="border review-box">
                                    <div style="text-align: right;">
                                        <img src="../assets/img/review.png"
                                            style="transform: scale(0.7); margin-right: -55px; margin-top: -75px;">
                                    </div>
                                    <div style="text-align: left; color: black; margin-top: -20px;">
                                        <img src="../assets/img/user_image1.png"
                                            style="margin-left: -55px; transform: scale(0.9);"> <span
                                            style="font-weight: 600;">{{ translate('user_name1') }}</span>
                                    </div>
                                    <div style="color: gray; text-align: left;">
                                        <span>{{ translate('user_review1') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="margin-top: 100px; margin-left: 30px;">
                                <div class="border review-box">
                                    <div style="text-align: right;">
                                        <img src="../assets/img/review.png"
                                            style="transform: scale(0.7); margin-right: -55px; margin-top: -75px;">
                                    </div>
                                    <div style="text-align: left; color: black; margin-top: -20px;">
                                        <img src="../assets/img/user_image2.png"
                                            style="margin-left: -55px; transform: scale(0.9);"> <span
                                            style="font-weight: 600;">{{ translate('user_name2') }}</span>
                                    </div>
                                    <div style="color: gray; text-align: left;">
                                        <span>{{ translate('user_review2') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center justify-content-center">
                            <div class="col-md-4" style="margin-left: 60px">
                                <div class="border review-box">
                                    <div style="text-align: right;">
                                        <img src="../assets/img/review.png"
                                            style="transform: scale(0.7); margin-right: -55px; margin-top: -75px;">
                                    </div>
                                    <div style="text-align: left; color: black; margin-top: -20px;">
                                        <img src="../assets/img/user_image3.png"
                                            style="margin-left: -55px; transform: scale(0.9);"> <span
                                            style="font-weight: 600;">{{ translate('user_name3') }}</span>
                                    </div>
                                    <div style="color: gray; text-align: left;">
                                        <span>{{ translate('user_review3') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="margin-top: 100px; margin-bottom: -100px; margin-left: 30px;">
                                <div class="border review-box">
                                    <div style="text-align: right;">
                                        <img src="../assets/img/review.png"
                                            style="transform: scale(0.7); margin-right: -55px; margin-top: -75px;">
                                    </div>
                                    <div style="text-align: left; color: black; margin-top: -20px;">
                                        <img src="../assets/img/user_image4.png"
                                            style="margin-left: -55px; transform: scale(0.9);"> <span
                                            style="font-weight: 600;">{{ translate('user_name4') }}</span>
                                    </div>
                                    <div style="color: gray; text-align: left;">
                                        <span>{{ translate('user_review4') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <div class="laptop-header">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img :key="currentImageMobile" :src="currentImageMobile" class="d-block w-100"
                        alt="Background Image">
                </transition>
                <div style="display: none;">
                    <img src="../assets/img/background_home_mobile2.jpg" class="d-block w-100" alt="Background Image">
                    <img src="../assets/img/background_home_mobile3.jpg" class="d-block w-100" alt="Background Image">
                </div>

                <!-- Navbar -->
                <MainNav />

                <div v-if="currenImageHeaderIndex == 0"
                    class="position-absolute bottom-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-bottom: 20px;">
                    <p style="font-weight: bold; font-size: 1.7em; padding-left: 20px; padding-right: 20px;">{{
                        translate('header_text1') }}</p>
                    <p style="font-weight: bold; font-size: 1.5em; padding-left: 20px; padding-right: 20px;">{{
                        translate('header_text2') }}</p>
                    <br>
                    <p style="font-size: 18px; font-weight: 1.3; padding-left: 20px; padding-right: 20px;">{{
                        translate('header_text3') }}</p>
                    <br>
                </div>
                <div v-if="currenImageHeaderIndex == 1"
                    class="position-absolute bottom-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-bottom: 20px;">
                    <p style="font-weight: bold; font-size: 1.7em; padding-left: 20px; padding-right: 20px;">{{
                        translate('header_text4') }}</p>
                    <p style="font-weight: bold; font-size: 1.5em; padding-left: 20px; padding-right: 20px;">{{
                        translate('header_text5') }}</p>
                    <br>
                    <p style="font-size: 18px; font-weight: 1.3; padding-left: 20px; padding-right: 20px;">{{
                        translate('header_text6') }}</p>
                    <br>
                </div>
                <div v-if="currenImageHeaderIndex == 2"
                    class="position-absolute bottom-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-bottom: 20px;">
                    <p style="font-weight: bold; font-size: 1.7em; padding-left: 20px; padding-right: 20px;">{{
                        translate('header_text7') }}</p>
                    <p style="font-weight: bold; font-size: 1.5em; padding-left: 20px; padding-right: 20px;">{{
                        translate('header_text8') }}</p>
                    <br>
                    <p style="font-size: 18px; font-weight: 1.3; padding-left: 20px; padding-right: 20px;">{{
                        translate('header_text9') }}</p>
                    <br>
                </div>
            </header>
        </div>
        <section style="padding-top: 50px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="row text-center justify-content-center" style="margin-top: 30px;">
                <div class="col-md-4" style="width: auto;">
                    <router-link to="/login">
                        <div class="service-box-mobile">
                            <img src="../assets/img/home_icon3_pink.svg">
                            <p style="margin-top: 5px; color: black;">{{ translate('action1_title') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4" style="width: auto;">
                    <router-link to="/quote">
                        <div class="service-box-mobile">
                            <img src="../assets/img/home_icon2_pink.svg">
                            <p style="margin-top: 5px; color: black;">{{ translate('action2_title') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4" style="width: auto;">
                    <router-link to="/">
                        <div class="service-box-mobile">
                            <img src="../assets/img/home_icon1_pink.svg">
                            <p style="margin-top: 5px; color: black;">{{ translate('action3_title') }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>

        <section style="padding-top: 50px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6"
                        style="color: black; font-size: x-large; font-weight: 600; margin-bottom: 20px; height: 200px;">
                        {{ translate('why_question1') }}
                        <div style="height: 75px; padding: 15px;">
                            <img :src="currentImage" style="max-width: 180px;">
                        </div>
                        {{ translate('why_question2') }}
                    </div>
                    <div class="col-md-6">
                        <img src="../assets/img/cit_video_mobile.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage5 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: #CF0020;">
                {{ translate('section1_text1') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel1" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 20px; height: 100%; background-color: white;">
                                        <div>
                                            <img src="../assets/img/time.png">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                                translate('solution1_title')
                                            }}</span>
                                            <br>
                                            <span>{{ translate('solution1_contenu') }}</span>
                                        </div>
                                        <div class="mt-auto">
                                            <button class="custom-btn">
                                                <router-link to="/secteurs-livraison" style="color: #CF0020;">
                                                    {{ translate('see_more') }} <i class="fa fa-chevron-right"></i>
                                                </router-link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 20px; height: 100%; background-color: white;">
                                        <div>
                                            <img src="../assets/img/quality.png">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                                translate('solution2_title')
                                            }}</span>
                                            <br>
                                            <span>{{ translate('solution2_contenu') }}</span>
                                        </div>
                                        <div class="mt-auto">
                                            <button class="custom-btn">
                                                <router-link to="/notre-flotte" style="color: #CF0020;">
                                                    {{ translate('see_more') }} <i class="fa fa-chevron-right"></i>
                                                </router-link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 20px; height: 100%; background-color: white;">
                                        <div>
                                            <img src="../assets/img/price.png">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                                translate('solution3_title')
                                            }}</span>
                                            <br>
                                            <span>{{ translate('solution3_contenu') }}</span>
                                        </div>
                                        <div class="mt-auto">
                                            <button class="custom-btn">
                                                <router-link to="technologies" style="color: #CF0020;">
                                                    {{ translate('see_more') }} <i class="fa fa-chevron-right"></i>
                                                </router-link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-10 mx-auto">
                                    <div class="border d-flex flex-column"
                                        style="border-radius: 20px; height: 100%; background-color: white;">
                                        <div>
                                            <img src="../assets/img/services.png">
                                        </div>
                                        <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                            <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                                translate('solution4_title')
                                            }}</span>
                                            <br>
                                            <span>{{ translate('solution4_contenu') }}</span>
                                        </div>
                                        <div class="mt-auto">
                                            <button class="custom-btn">
                                                <router-link to="/iso-9001-2018" style="color: #CF0020;">
                                                    {{ translate('see_more') }} <i class="fa fa-chevron-right"></i>
                                                </router-link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 30px;">
            <div class="container" style="text-align: center;">
                <img src="../assets/img/eurekali_map.png">
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 20px;"
            :style="{ backgroundImage: 'url(' + backgroundImage7 + ')', 'background-repeat': 'no-repeat', 'background-position': 'bottom', 'background-size': 'cover' }">
            <div class="container" style="text-align: center;">
                <p
                    style="padding-left: 80px; padding-right: 80px; color: #CF0020; font-size: x-large; font-weight: 600;">
                    {{ translate('citexpress_name') }}<br>
                    {{ translate('citexpress_name_description') }}
                </p>
                <br>
                <p style="color: black; padding-left: 20px; padding-right: 20px;">
                    {{ translate('citexpress_presentation') }}
                </p>
                <br>
                <button class="custom-btn3">{{ translate('see_more') }} <i class="fa fa-chevron-right"></i></button>
            </div>
        </section>

        <section style="padding-top: 40px; padding-bottom: 50px; margin-top: 40px; margin-bottom: 50px; height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage8 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'cover' }">

            <div class="container" style="color: black; text-align: center;">
                <p style="font-size: x-large; font-weight: 600; padding-left: 80px; padding-right: 80px;">
                    {{ translate('satisfied_clients') }}
                </p>
                {{ translate('satisfied_clients_description') }}
                {{ translate('satisfied_clients_description2') }}
            </div>

            <div class="container" style="margin-top: 60px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel2" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <img src="../assets/img/review.png"
                                        style="transform: scale(0.7); margin-bottom: -40px; margin-right: -260px;">
                                    <div class="border review-box">
                                        <div style="text-align: left; color: black; margin-top: -20px;">
                                            <img src="../assets/img/user_image1.png"
                                                style="margin-left: -55px; transform: scale(0.9);"> <span
                                                style="font-weight: 600;">{{ translate('user_name1') }}</span>
                                        </div>
                                        <div style="color: gray; text-align: left;">
                                            <span>{{ translate('user_review1') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <img src="../assets/img/review.png"
                                        style="transform: scale(0.7); margin-bottom: -40px; margin-right: -260px;">
                                    <div class="border review-box">
                                        <div style="text-align: left; color: black; margin-top: -20px;">
                                            <img src="../assets/img/user_image2.png"
                                                style="margin-left: -55px; transform: scale(0.9);"> <span
                                                style="font-weight: 600;">{{ translate('user_name2') }}</span>
                                        </div>
                                        <div style="color: gray; text-align: left;">
                                            <span>{{ translate('user_review2') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <img src="../assets/img/review.png"
                                        style="transform: scale(0.7); margin-bottom: -40px; margin-right: -260px;">
                                    <div class="border review-box">
                                        <div style="text-align: left; color: black; margin-top: -20px;">
                                            <img src="../assets/img/user_image3.png"
                                                style="margin-left: -55px; transform: scale(0.9);"> <span
                                                style="font-weight: 600;">{{ translate('user_name3') }}</span>
                                        </div>
                                        <div style="color: gray; text-align: left;">
                                            <span>{{ translate('user_review3') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="col-9 mx-auto">
                                    <img src="../assets/img/review.png"
                                        style="transform: scale(0.7); margin-bottom: -40px; margin-right: -260px;">
                                    <div class="border review-box">
                                        <div style="text-align: left; color: black; margin-top: -20px;">
                                            <img src="../assets/img/user_image4.png"
                                                style="margin-left: -55px; transform: scale(0.9);"> <span
                                                style="font-weight: 600;">{{ translate('user_name4') }}</span>
                                        </div>
                                        <div style="color: gray; text-align: left;">
                                            <span>{{ translate('user_review4') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="ipad-section">
        <div class="laptop-header">
            <header class="position-relative">
                <!-- Image -->
                <transition name="image-fade" mode="out-in">
                    <img :key="currentImageIpad" :src="currentImageIpad" class="d-block w-100" alt="Background Image">
                </transition>

                <div style="display: none;">
                    <img src="../assets/img/background_home_dark2.jpg" class="d-block w-100" alt="Background Image">
                    <img src="../assets/img/background_home_dark3.jpg" class="d-block w-100" alt="Background Image">
                </div>
                <!-- Navbar -->
                <MainNav />

                <div v-if="currenImageHeaderIndex == 0"
                    class="position-absolute bottom-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-bottom: 20px; padding-left: 20px; padding-right: 20px;">
                    <p style="font-weight: bold; font-size: 1.8em;">{{ translate('header_text1') }}</p>
                    <p style="font-weight: bold; font-size: 1.8em;">{{ translate('header_text2') }}</p>
                    <p style="font-size: 18px; font-weight: 400; padding: 20px; padding-top: 0;">{{
                        translate('header_text3') }}</p>
                </div>
                <div v-if="currenImageHeaderIndex == 1"
                    class="position-absolute bottom-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-bottom: 20px; padding-left: 20px; padding-right: 20px;">
                    <p style="font-weight: bold; font-size: 1.8em;">{{ translate('header_text4') }}</p>
                    <p style="font-weight: bold; font-size: 1.8em;">{{ translate('header_text5') }}</p>
                    <p style="font-size: 18px; font-weight: 400; padding: 20px; padding-top: 0;">{{
                        translate('header_text6') }}</p>
                </div>
                <div v-if="currenImageHeaderIndex == 2"
                    class="position-absolute bottom-0 text-white mobile-prices text-center"
                    style="width: 100%; margin-bottom: 20px; padding-left: 20px; padding-right: 20px;">
                    <p style="font-weight: bold; font-size: 1.8em;">{{ translate('header_text7') }}</p>
                    <p style="font-weight: bold; font-size: 1.8em;">{{ translate('header_text8') }}</p>
                    <p style="font-size: 18px; font-weight: 400; padding: 20px; padding-top: 0;">{{
                        translate('header_text9') }}</p>
                </div>
            </header>
        </div>

        <section style="padding-top: 5px; padding-bottom: 20px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-attachment': 'fixed', 'background-size': 'cover' }">
            <div class="row text-center justify-content-center"
                style="margin-top: 30px; padding-left: 15px; padding-right: 15px;">
                <div class="col-4">
                    <router-link to="/login">
                        <div class="service-box"
                            style="background-color: white; color: black; border: 1px solid #CF0020;">
                            <img src="../assets/img/home_icon3_pink.svg">
                            <p style="margin-top: 5px; color: black;">{{ translate('action1_title') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-4">
                    <router-link to="/quote">
                        <div class="service-box"
                            style="background-color: white; color: black; border: 1px solid #CF0020;">
                            <img src="../assets/img/home_icon2_pink.svg">
                            <p style="margin-top: 10px; color: black;">{{ translate('action2_title') }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="col-4">
                    <router-link to="/">
                        <div class="service-box"
                            style="background-color: white; color: black; border: 1px solid #CF0020;">
                            <img src="../assets/img/home_icon1_pink.svg">
                            <p style="margin-top: 5px; color: black;">{{ translate('action3_title') }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>

        <section style="margin-top: 20px; padding-top: 40px; padding-bottom: 50px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-12 text-center"
                        style="height: 200px; color : black; font-weight: 600; font-size: x-large;">
                        {{ translate('why_question1') }}
                        <br>
                        <img :src="currentImage"
                            style="height: 30%; width: 200px; object-fit: contain; margin-left: 15px; margin-right: 15px; margin-top: 15px; margin-bottom: 15px;">
                        <br>
                        {{ translate('why_question2') }}
                    </div>
                    <div class="col-12">
                        <img src="../assets/img/cit_video.png">
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; height: 600px;"
            :style="{ backgroundImage: 'url(' + backgroundImage5 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">
            <div class="container"
                style="color: black; text-align: center; font-size: x-large; font-weight: 600; color: #CF0020;">
                {{ translate('section1_text1') }}
            </div>

            <div class="container" style="margin-top: 30px;">
                <div class="row text-center justify-content-center">
                    <div id="headerCarousel3" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000" style="text-align: center;">
                                <div class="row">
                                    <div class="col-6 mx-auto">
                                        <div class="border d-flex flex-column"
                                            style="border-radius: 20px; height: 100%; background-color: white;">
                                            <div>
                                                <img src="../assets/img/time.png">
                                            </div>
                                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                                    translate('solution1_title')
                                                }}</span>
                                                <br>
                                                <br>
                                                <span>{{ translate('solution1_contenu') }}</span>
                                            </div>
                                            <div class="mt-auto">
                                                <router-link to="/secteurs-livraison">
                                                    <button class="custom-btn">{{ translate('see_more') }} <i
                                                            class="fa fa-chevron-right"></i></button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 mx-auto">
                                        <div class="border d-flex flex-column"
                                            style="border-radius: 20px; height: 100%; background-color: white;">
                                            <div>
                                                <img src="../assets/img/quality.png">
                                            </div>
                                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                                    translate('solution2_title')
                                                }}</span>
                                                <br>
                                                <br>
                                                <span>{{ translate('solution2_contenu') }}</span>
                                            </div>
                                            <div class="mt-auto">
                                                <router-link to="/notre-flotte">
                                                    <button class="custom-btn">{{ translate('see_more') }} <i
                                                            class="fa fa-chevron-right"></i></button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000" style="text-align: center;">
                                <div class="row">
                                    <div class="col-6 mx-auto">
                                        <div class="border d-flex flex-column"
                                            style="border-radius: 20px; height: 100%; background-color: white;">
                                            <div>
                                                <img src="../assets/img/price.png">
                                            </div>
                                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                                    translate('solution3_title')
                                                }}</span>
                                                <br>
                                                <br>
                                                <span>{{ translate('solution3_contenu') }}</span>
                                            </div>
                                            <div class="mt-auto">
                                                <router-link to="technologies">
                                                    <button class="custom-btn">{{ translate('see_more') }} <i
                                                            class="fa fa-chevron-right"></i></button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 mx-auto">
                                        <div class="border d-flex flex-column"
                                            style="border-radius: 20px; height: 100%; background-color: white;">
                                            <div>
                                                <img src="../assets/img/services.png">
                                            </div>
                                            <div style="padding: 20px; text-align: center; flex-grow: 1;">
                                                <span style="color: #CF0020; font-weight: 600; font-size: large;">{{
                                                    translate('solution4_title')
                                                }}</span>
                                                <br>
                                                <br>
                                                <span>{{ translate('solution4_contenu') }}</span>
                                            </div>
                                            <div class="mt-auto">
                                                <router-link to="/iso-9001-2018">
                                                    <button class="custom-btn">{{ translate('see_more') }} <i
                                                            class="fa fa-chevron-right"></i></button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section style="padding-top: 60px; padding-bottom: 60px;"
            :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-6"
                        style="display: flex; flex-direction: column; align-items: left; justify-content: center;">
                        <p style="color: black; font-weight: bold; font-size: x-large;">
                            {{ translate('citexpress_name') }} <br>
                            {{ translate('citexpress_name_description') }}
                        </p>
                        <p style="margin-top: 20px; margin-bottom: 15px; color: black;">
                            {{ translate('citexpress_presentation') }}
                        </p>
                        <button class="custom-btn3">{{ translate('see_more') }} <i
                                class="fa fa-chevron-right"></i></button>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                        <img src="../assets/img/eurekali_map.png">
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top: 10px; padding-bottom: 10px; margin-top: 50px; margin-bottom: 80px; height: 400px;"
            :style="{ backgroundImage: 'url(' + backgroundImage10 + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': 'cover' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-6"
                        style="display: flex; flex-direction: column; align-items: left; justify-content: center;">
                        <p style="color: black; font-weight: bold; font-size: x-large;">
                            {{ translate('satisfied_clients') }}
                        </p>
                        <p style="margin-top: 20px; margin-bottom: 15px; color: black;">
                            {{ translate('satisfied_clients_description') }} <br>
                            {{ translate('satisfied_clients_description2') }}
                        </p>
                        <button class="custom-btn4">{{ translate('give_review') }}</button>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                        <div class="row text-center justify-content-center">
                            <div id="headerCarousel4" class="carousel slide" data-bs-ride="carousel"
                                data-bs-pause="false">
                                <div class="carousel-inner">
                                    <div class="carousel-item active" data-bs-interval="3000"
                                        style="text-align: center; padding-top: 40px; padding-bottom: 20px; padding-right: 20px;">
                                        <div class="col-12 mx-auto">
                                            <div class="border review-box">
                                                <div style="text-align: right;">
                                                    <img src="../assets/img/review.png"
                                                        style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                                </div>
                                                <div style="text-align: left; color: black; margin-top: -20px;">
                                                    <img src="../assets/img/user_image1.png"
                                                        style="margin-left: -55px; transform: scale(0.9);"> <span
                                                        style="font-weight: 600;">{{ translate('user_name1') }}</span>
                                                </div>
                                                <div style="color: gray; text-align: left;">
                                                    <span>{{ translate('user_review1') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item" data-bs-interval="3000"
                                        style="text-align: center; padding-top: 40px; padding-bottom: 20px; padding-right: 20px;">
                                        <div class="ccol-12 mx-auto">
                                            <div class="border review-box">
                                                <div style="text-align: right;">
                                                    <img src="../assets/img/review.png"
                                                        style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                                </div>
                                                <div style="text-align: left; color: black; margin-top: -20px;">
                                                    <img src="../assets/img/user_image2.png"
                                                        style="margin-left: -55px; transform: scale(0.9);"> <span
                                                        style="font-weight: 600;">{{ translate('user_name2') }}</span>
                                                </div>
                                                <div style="color: gray; text-align: left;">
                                                    <span>{{ translate('user_review2') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item" data-bs-interval="3000"
                                        style="text-align: center; padding-top: 40px; padding-bottom: 20px; padding-right: 20px;">
                                        <div class="ccol-12 mx-auto">
                                            <div class="border review-box">
                                                <div style="text-align: right;">
                                                    <img src="../assets/img/review.png"
                                                        style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                                </div>
                                                <div style="text-align: left; color: black; margin-top: -20px;">
                                                    <img src="../assets/img/user_image3.png"
                                                        style="margin-left: -55px; transform: scale(0.9);"> <span
                                                        style="font-weight: 600;">{{ translate('user_name3') }}</span>
                                                </div>
                                                <div style="color: gray; text-align: left;">
                                                    <span>{{ translate('user_review3') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-item" data-bs-interval="3000"
                                        style="text-align: center; padding-top: 40px; padding-bottom: 20px; padding-right: 20px;">
                                        <div class="ccol-12 mx-auto">
                                            <div class="border review-box">
                                                <div style="text-align: right;">
                                                    <img src="../assets/img/review.png"
                                                        style="transform: scale(0.7); margin-right: -55px; margin-top: -65px;">
                                                </div>
                                                <div style="text-align: left; color: black; margin-top: -20px;">
                                                    <img src="../assets/img/user_image4.png"
                                                        style="margin-left: -55px; transform: scale(0.9);"> <span
                                                        style="font-weight: 600;">{{ translate('user_name4') }}</span>
                                                </div>
                                                <div style="color: gray; text-align: left;">
                                                    <span>{{ translate('user_review4') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    </section>
</template>





<script>
import MainNav from '@/components/MainNav.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundImage1 from '../assets/img/background_grey.png';
import BackgroundImage2 from '../assets/img/background_grey_bottom.png';
import BackgroundImage4 from '../assets/img/background_reviews.png';
import BackgroundImage5 from '../assets/img/background_mobile_section.png';
import BackgroundImage7 from '../assets/img/background_grey_mobile.png';
import BackgroundImage8 from '../assets/img/background_reviews-mobile.png';
import BackgroundImage10 from '../assets/img/background_reviews-ipad.png';
import BackgroundImage11 from '../assets/img/background_home_dark.jpg';
import BackgroundImage12 from '../assets/img/background_home_dark2.jpg';
import BackgroundImage13 from '../assets/img/background_home_dark3.jpg';
import companyImage2 from '../assets/img/2-company.png';
import companyImage3 from '../assets/img/3-company.png';
import companyImage4 from '../assets/img/4-company.png';
import companyImage5 from '../assets/img/5-company.png';
import companyImage6 from '../assets/img/6-company.png';
import companyImage8 from '../assets/img/8-company.png';
import companyImage9 from '../assets/img/9-company.png';
import MobileHeader1 from '../assets/img/background_home_mobile1.jpg';
import MobileHeader2 from '../assets/img/background_home_mobile2.jpg';
import MobileHeader3 from '../assets/img/background_home_mobile3.jpg';

export default {
    mixins: [en, fr, nl],
    components: {
        MainNav,
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2,
            backgroundImage4: BackgroundImage4,
            backgroundImage5: BackgroundImage5,
            backgroundImage7: BackgroundImage7,
            backgroundImage8: BackgroundImage8,
            backgroundImage10: BackgroundImage10,
            imageUrls: [
                companyImage2,
                companyImage3,
                companyImage4,
                companyImage5,
                companyImage6,
                companyImage8,
                companyImage9
            ],
            headerUrls: [
                BackgroundImage11,
                BackgroundImage12,
                BackgroundImage13
            ],
            MobileheaderUrls: [
                MobileHeader1,
                MobileHeader2,
                MobileHeader3,
            ],
            currentImageIndex: 0,
            currenImageHeaderIndex: 0,
            first_button_mobile: false,
            second_button_mobile: false,
            animationKey: 0,
            animateText: true
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
        currentImage() {
            return this.imageUrls[this.currentImageIndex];
        },
        backgroundImage11() {
            return this.headerUrls[this.currenImageHeaderIndex];
        },
        currentImageMobile() {
            return this.MobileheaderUrls[this.currenImageHeaderIndex];
        },
        currentImageIpad() {
            return this.headerUrls[this.currenImageHeaderIndex]
        }
    },
    mounted() {
        document.title = 'CITExpress';
        setInterval(this.changeImage, 1500);
        setInterval(this.changeImageHeader, 7000);
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel1'), {
                ride: 'carousel',
                interval: 3000,
                pause: false
            });
        });

        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel2'), {
                ride: 'carousel',
                interval: 3000,
                pause: false
            });
        });

        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel3'), {
                ride: 'carousel',
                interval: 3000,
                pause: false
            });
        });

        this.$nextTick(() => {
            new bootstrap.Carousel(document.querySelector('#headerCarousel4'), {
                ride: 'carousel',
                interval: 3000,
                pause: false
            });
        });
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        changeImage() {
            this.currentImageIndex = (this.currentImageIndex + 1) % this.imageUrls.length;
        },
        changeImageHeader() {
            this.currenImageHeaderIndex = (this.currenImageHeaderIndex + 1) % this.headerUrls.length;
            this.animationKey++;
        },
        changeFirstButton() {
            this.first_button_mobile = !this.first_button_mobile
        },
        changeSecondButton() {
            this.second_button_mobile = !this.second_button_mobile
        }
    }
}
</script>

<style>
.service-box {
    background-color: rgba(207, 0, 32, 0.5);
    color: white;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    height: 100%;
    cursor: pointer;
    border: 2px solid rgba(207, 0, 32, 0.5);
}

.service-box:hover {
    background-color: rgba(82, 79, 80, 0.5);
    border: 2px solid #efefef;
}

.service-box-mobile {
    border: 1px solid #CF0020;
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 250px;
    border-radius: 20px;
}

.laptop-header header .navbar {
    top: 0;
    left: 0;
}

.custom-btn {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn:hover {
    background-color: #CF0020;
    color: white;
}

.solution-box {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    border: 1px solid white;
    text-align: center;
    color: black;
    padding: 25px;
    height: 100%;
}

.custom-btn2 {
    background-color: rgba(247, 243, 243, 0.1);
    border: 1px solid #CF0020;
    color: #CF0020;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.custom-btn2:hover {
    background-color: #CF0020;
    color: white;
}

.custom-btn3 {
    background-color: #CF0020;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 150px;
}

.custom-btn3:hover {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
}

.custom-btn4 {
    background-color: #CF0020;
    border: 1px solid #CF0020;
    color: white;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    width: 200px;
}

.custom-btn4:hover {
    background-color: white;
    border: 1px solid #CF0020;
    color: #CF0020;
}

.review-box {
    background-color: white;
    border-radius: 20px;
    border: 1px solid white;
    color: black;
    padding: 25px;
    margin-left: 15px;
}

.laptop-section {
    display: block;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: block;
    }

    .ipad-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}



.dropdown-menu-custom {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(155, 6, 86, 0.5);
    padding: 10px;
    border: none;
    border-radius: 4px;
    z-index: 1000;
    border-radius: 20px;
    width: 95%;
}

.service-box:hover .dropdown-menu-custom {
    display: block;
}

.dropdown-menu-custom ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu-custom li {
    margin: 0;
}

.dropdown-menu-custom li:hover {
    background-color: #CF0020;
    border-radius: 15px;
}

@keyframes backgroundFade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.image-fade-enter-active,
.image-fade-leave-active {
    transition: opacity 0.5s;
}

.image-fade-enter,
.image-fade-leave-to {
    opacity: 0.7;
}

@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideFromLeftText {
    from {
        transform: translateX(-180%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-text-animation {
    animation: slideFromLeftText 1.5s ease forwards;
}

@keyframes slideFromBottomText {
    from {
        transform: translateY(200%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-text-animation-bottom {
    animation: slideFromBottomText 1s ease forwards;
}

.slide-text-animation-bottom2 {
    opacity: 0;
    animation: slideFromBottomText 1s ease forwards;
    animation-delay: 0.5s;
}

.slide-text-animation-bottom3 {
    opacity: 0;
    animation: slideFromBottomText 1s ease forwards;
    animation-delay: 1s;
}
</style>