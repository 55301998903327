export default {
    data() {
        return {
            fr: {
                header_text1: "Au-delà du volant : ",
                header_text2: "Nos chauffeurs, les ambassadeurs de votre marque.",
                header_text3: "Ils incarnent vos valeurs sur chaque route, assurant une arrivée à bon port Avec un service irréprochable garantissant ainsi satisfaction et confiance à chaque trajet.",
                header_text4: "Fiabilité Pharma :",
                header_text5: "Excellence certifiée, ISO 9001 pour vos livraisons précises.",
                header_text6: "Notre maîtrise des réseaux de santé belges garantit une distribution impeccable, du secteur pharma à l'hôpital, élevant la norme de confiance.",
                header_text7: "Main à Main :",
                header_text8: "Garantie absolue de ponctualité et d'intégrité.",
                header_text9: "Vos envois, précieux et fragiles, sont traités avec le plus grand soin, assurant une arrivée en parfait état, à chaque fois.",
                action1_title: "EFFECTUER UN ENVOI",
                action2_title: "DEMANDEZ UN DEVIS",
                action3_title: "DEVENIR UN PARTENAIRE",
                why_question1: "Pourquoi",
                why_question2: "choisissent d'utiliser CIT Express?",
                section1_text1: "CIT Express transforme vos attentes en réalité, adaptant ses solutions à vos exigences",
                solution1_title: "Les Secteurs de Livraison",
                solution1_contenu: "Explorez nos services de livraison adaptés à 14 secteurs, couvrant un large éventail allant du domaine médical à l'industrie aérospatiale",
                solution2_title: "Notre Flotte",
                solution2_contenu: "Découvrez notre flotte de véhicules variée, garantissant un service de livraison fiable et rapide pour répondre à tous les besoins.",
                solution3_title: "Technologie",
                solution3_contenu: "Nous combinons l'excellence opérationnelle avec des technologies de pointe pour offrir un service de livraison excédant attentes nos clients",
                solution4_title: "ISO 9001-2018",
                solution4_contenu: "Chez CIT Express, notre adhésion aux normes ISO garantit une qualité et une efficacité inégalées, assurant la satisfaction totale de nos clients",
                see_more: "Voir plus",
                citexpress_name: "CIT EXPRESS",
                citexpress_name_description: "leader en Belgique depuis 1995",
                citexpress_presentation: "CIT Express, leader en Belgique depuis 1995, offre des solutions logistiques sur mesure pour répondre à vos besoins d'expédition, que vous soyez particulier, e-commerçant, petite entreprise ou multinationale...",
                satisfied_clients: "Des milliers de clients satisfaits",
                satisfied_clients_description: "Plusieurs d'entre eux, nous leur avons demandé à quel point ils étaient satisfaits de nos services. Voici leurs déclarations.",
                give_review: "DONNER UN AVIS",
                user_name1: "Marc B. B2B",
                user_review1: "Auparavant, le suivi était compliqué avec un seul transporteur. Aujourd'hui, grâce à CIT Express qui gère l'ensemble de nos transporteurs, tout est pris en charge de manière fluide et efficace.",
                user_name2: "Amina R. B2B",
                user_review2: "Chercher un  transporteur à la hauteur de nos exigences c'est quasi impossible, CIT Express a reussi le par.",
                user_name3: "Alan D. C2C",
                user_review3: "Dans une situation délicate, CIT Express a assuré une livraison rapide et sécurisée de documents importants, me sauvant ainsi. Un service professionnel que je recommande.",
                user_name4: "Jean-Michel L. B2B",
                user_review4: "Collaborant avec plusieurs transporteurs, cela implique de nombreux contacts et diverses factures. Avec CIT Express, nous économisons un temps précieux en consolidant ces processus.",

                //Navbar
                home: "Accueil",
                services: "Services",
                track_shipment: "Suivi de colis",
                contact_us: "Contactez-nous",
                my_cit: "My CIT",

                login_text1: "Prêt à envoyer?",
                login_text2: "Connectez-vous ou inscrivez-vous maintenant",
                form_header: "Connectez-vous:",
                email_placeholder: "Adresse e-mail",
                password_placeholder: "Mot de passe",
                login: "SE CONNECTER",
                not_yet_subscribed: "Pas encore inscrit ?",
                subscribe: "INSCRIVEZ-VOUS",

                //Notre Flotte
                notre_flotte_header: "Notre Flotte : Diversifiée, Moderne et Écologique",
                notre_flotte_desc: "Chez CIT Express, notre flotte de véhicules est le cœur de notre capacité à fournir un service de livraison rapide et fiable. Nous comprenons que chaque livraison est unique, c'est pourquoi notre flotte est composée d'une variété de véhicules adaptés à tous les besoins.",

                notre_flotte_title1: "Véhicules Électriques",
                notre_flotte_title1_soustitle1: "Scooters Électriques",
                notre_flotte_title1_soustitle1_desc: "Parfaits pour la navigation rapide en milieu urbain, nos scooters électriques sont à la fois écologiques et efficaces.",
                notre_flotte_title1_soustitle2: "Vélos Électriques",
                notre_flotte_title1_soustitle2_desc: "Idéaux pour les livraisons de proximité, ils combinent la maniabilité des vélos traditionnels avec la puissance assistée, permettant des livraisons rapides sans émissions.",
                notre_flotte_title2: "Véhicules à Moteur",
                notre_flotte_title2_soustitle1: "Fourgonnettes",
                notre_flotte_title2_soustitle1_desc: "Nos fourgonnettes sont idéales pour des livraisons plus volumineuses ou multiples, offrant suffisamment d'espace tout en étant agiles en ville.",
                notre_flotte_title2_soustitle2: "Camions de Livraison",
                notre_flotte_title2_soustitle2_desc: "Pour les cargaisons plus importantes ou les livraisons spécialisées, nos camions sont équipés pour transporter en toute sécurité une grande variété de marchandises.",

                notre_flotte_title3: "Véhicules Spécialisés",
                notre_flotte_title3_soustitle1: "Véhicules Réfrigérés",
                notre_flotte_title3_soustitle1_desc: "Ces véhicules sont essentiels pour le transport de produits nécessitant un contrôle de température, comme certains médicaments ou équipements technologiques sensibles.",
                notre_flotte_title3_soustitle2: "Véhicules de Livraison de Haute Sécurité",
                notre_flotte_title3_soustitle2_desc: "Utilisés pour le transport de documents ou d'objets de valeur nécessitant une sécurité accrue, ces véhicules sont équipés de systèmes de sécurité avancés.",

                notre_flotte_title4: "Engagement envers la Durabilité : Transition vers l'Électrique",
                notre_flotte_title4_desc1: "Nous sommes engagés dans une transition progressive vers une flotte entièrement électrique, réduisant ainsi notre empreinte carbone et contribuant à un avenir plus durable.",
                notre_flotte_title4_desc2: "Notre flotte est régulièrement entretenue et mise à jour pour garantir la meilleure performance et fiabilité. Chez CIT Express, nous sommes fiers de notre capacité à répondre à tous les besoins de livraison, tout en restant engagés envers la responsabilité environnementale et l'innovation.",

                about_us_header: "A PROPOS DE NOUS",
                about_us_sheader: "Pionniers de la Livraison Urbaine Innovante",
                about_us_section1_title: "Les Origines à Bruxelles, 1996",
                about_us_section1_contenu: "L'aventure de CIT Express a commencé en 1996 à Bruxelles, une ville où l'innovation se mêle à un riche héritage culturel. Face aux défis logistiques urbains, comme les embouteillages et les manifestations, nous avons eu une vision révolutionnaire : transformer la livraison en milieu urbain",
                inovation: "L'Innovation :",
                inovation_desc: "Au-delà de la Livraison en Scooter et Vélo",
                inovation_text: "Notre concept initial s'est rapidement distingué par l'utilisation de scooters et de vélos pour des livraisons rapides et efficaces. Mais notre véritable avancée a été notre précurseur dans l'adoption de technologies novatrices.",
                about_us_services_title: "Précurseurs en Technologie de Communication",
                about_us_service1_title: "Communication Radio",
                about_us_service1_contenu: "Nous avons été parmi les premiers à utiliser la communication radio pour coordonner nos livraisons, augmentant ainsi notre efficacité opérationnelle.",
                about_us_service2_title: "Introduction des SMS",
                about_us_service2_contenu: "L'adoption précoce des SMS a permis une communication plus rapide et plus directe avec nos équipes sur le terrain.",
                about_us_service3_title: "Pionniers du GPS",
                about_us_service3_contenu: "L'intégration du GPS a révolutionné notre capacité à suivre les livraisons en temps réel, offrant une transparence et une fiabilité sans précédent.",
                inovation_numerique: "Innovation Numérique",
                inovation_numerique1_title: "1er Site de Prise de Commande",
                inovation_numerique1_contenu: "Nous avons lancé l'un des premiers sites de commande de livraison en ligne, offrant à nos clients une facilité d'utilisation et une efficacité accrue.",
                inovation_numerique2_title: "Suivi et Facturation Électronique",
                inovation_numerique2_contenu: "En introduisant le suivi de commande en ligne et la facturation électronique, nous avons non seulement simplifié le processus pour nos clients, mais également réduit notre empreinte écologique.",
                leadership_title: "Un Héritage de Leadership et d'Innovation",
                leadership_text1: "Ces innovations ont non seulement établi CIT Express comme un leader dans le domaine de la livraison urbaine, mais elles ont également pavé la voie pour les technologies que de nombreux acteurs majeurs du secteur utilisent aujourd'hui. Nous sommes fiers d'avoir été des précurseurs, constamment à la recherche de nouvelles façons d'améliorer nos services et d'anticiper les besoins de nos clients.",
                leadership_text2: "Chez CIT Express, notre histoire est celle d'une innovation constante et d'un engagement envers l'excellence. Nous continuons à évoluer, toujours en quête de nouvelles façons de servir nos clients avec efficacité et fiabilité.",
                contactus_paragraphe1: "Laissez-nous vous montrer comment CIT Express peut répondre à vos exigences de livraison urbaine de manière efficace et fiable.",
                contactus_paragraphe2: "Contactez-nous aujourd'hui pour une collaboration exceptionnelle.",
                contacus_button: "Contactez-nous",

                iso_title: "ISO 9001-2018",
                iso_title_s1: "Qualité",
                iso_title_s2: "Gestion",
                iso_title_s3: "Systèmes",
                norme_iso: "Normes ISO:",
                norme_iso_desc: "Le Fondement de Notre Excellence dans la Livraison Spécialisée",
                iso_icon_title: "Engagement envers les Standards de Qualité ISO",
                iso_icon_desc: "Chez CIT Express, l'adhésion aux normes ISO est au cœur de notre engagement envers une qualité et une efficacité inégalées. Nos processus sont rigoureusement conçus pour respecter et surpasser ces standards internationaux, garantissant ainsi une satisfaction totale de nos clients.",
                iso_app_title: "Application des Normes ISO dans Nos Services Spécialisés",
                iso_app1_title: "ISO 9001 pour la Gestion de la Qualité",
                iso_app1_contenu: "Tous nos processus, de la gestion des opérations à la prestation des services de livraison, sont conformes à l'ISO 9001, assurant une qualité constante et fiable.",
                iso_app2_title: "ISO 14001 pour l'Écologie",
                iso_app2_contenu: "Nous nous engageons à minimiser notre impact environnemental à travers des pratiques conformes à l'ISO 14001, soulignant notre responsabilité écologique.",
                iso_app3_title: "ISO 45001 la Santé et la Sécurité au Travail",
                iso_app3_contenu: "La sécurité et le bien-être de notre équipe et de nos clients demeurent une priorité absolue, constamment guidée par les normes de l'ISO 45001 et l'engagement de tous.",
                iso_norme_title: "Spécialisation Sectorielle et Normes Associées",
                iso_norme1_title: "Secteur Pharmaceutique",
                iso_norme1_contenu: "Nous suivons les normes strictes relatives à la manutention et au transport des produits pharmaceutiques, incluant les Bonnes Pratiques de Distribution (BPD) et les directives spécifiques à la chaîne du froid.",
                iso_norme2_title: "Secteur Électronique et Technologique",
                iso_norme2_contenu: "Pour le transport de produits électroniques sensibles, nous appliquons des normes spécifiques qui assurent la protection contre les décharges électrostatiques et les dommages mécaniques.",
                iso_norme3_title: "Secteur Juridique et Financier",
                iso_norme3_contenu: "Nous respectons des protocoles de confidentialité et de sécurité rigoureux pour garantir la sécurité et la confidentialité des documents sensibles.",
                iso_norme4_title: "Secteur Aérospatial",
                iso_norme4_contenu: "Les exigences spécifiques de ce secteur sont respectées, y compris le transport de matériaux et composants critiques, en conformité avec les standards de qualité et de sécurité aérospatiaux.",
                formation_continue_title: "Formation et Sensibilisation Continues",
                formation_continue_desc: "Notre personnel reçoit une formation continue sur les normes ISO et les exigences spécifiques à chaque secteur. Cette approche assure une expertise et une conformité sans faille dans toutes nos opérations.",
                audit_amelioration_title: "Audit et Amélioration Continue",
                audit_amelioration_desc: "Des audits réguliers sont menés pour vérifier la conformité avec les normes ISO et identifier les opportunités d'amélioration. Cette démarche d'amélioration continue renforce notre engagement envers l'excellence.",
                iso_certif_2018: "En intégrant ces normes dans notre modèle opérationnel, CIT Express se distingue comme un prestataire de services de livraison spécialisé, fiable et respectueux des normes les plus élevées pour chacun des secteurs que nous desservons.",

                secteurs_header: "Les Secteurs de Livraison",
                secteurs_header_desc1: "Explorez nos services de livraison adaptés à 14 secteurs, du médical à l'aérospatiale.",
                secteurs_header_desc2: "Choisissez votre domaine dans le menu déroulant et découvrez comment notre expertise assure sécurité et rapidité pour vos expéditions.",
                secteurs_sheader: "CIT Express, 30 ans d'expertise, votre partenaire de confiance pour des livraisons sur mesure!",
                secteur1_title: "Secteur Médical et Pharmaceutique",
                secteur1_contenu: "Livraisons urgentes de médicaments, vaccins, échantillons biologiques, équipements médicaux. Transport à température contrôlée pour les articles sensibles.",
                secteur2_title: "Technologie et Électronique",
                secteur2_contenu: "Livraison de composants électroniques sensibles, équipements informatiques. Besoin de manutention soignée et protection contre les chocs.",
                secteur3_title: "Commerce de Détail et E-commerce",
                secteur3_contenu: "Optimisez votre commerce en ligne avec une livraison rapide, une gestion de retours efficace, et une synchronisation des stocks via des API. Assurez un service client réactif.",
                secteur4_title: "Industrie Automotrice",
                secteur4_contenu: "Transport urgent de pièces détachées, composants pour la production. Livraisons just-in-time pour maintenir la continuité de la chaîne de production.",
                secteur5_title: "Recherche et Développement",
                secteur5_contenu: "Service garantit un transport sécurisé pour votre matériel de recherche, échantillons scientifiques et prototypes, avec une attention particulière portée au contrôle minutieux de la température.",
                secteur6_title: "Consultance et Formation",
                secteur6_contenu: "Livraison rapide de documents importants, matériel de présentation et de formation, équipements pour audits ou évaluations. Importance de la rapidité et de la fiabilité.",

                sous_secteur1_title: "Industrie Alimentaire",
                sous_secteur1_contenu: "Transport de denrées périssables, fournitures de restauration, livraisons urgentes pour les supermarchés. Exigences en matière de température et de fraîcheur.",
                sous_secteur2_title: "Construction et Ingénierie",
                sous_secteur2_contenu: "Pour le transport de produits électroniques sensibles, nous appliquons des normes spécifiques qui assurent la protection contre les décharges électrostatiques et les dommages mécaniques.",
                sous_secteur3_title: "Juridique et Financier",
                sous_secteur3_contenu: "Nous respectons des protocoles de confidentialité et de sécurité rigoureux pour garantir la sécurité et la confidentialité des documents sensibles.",
                sous_secteur4_title: "Secteur Aérospatial",
                sous_secteur4_contenu: "Les exigences spécifiques de ce secteur sont respectées, y compris le transport de matériaux et composants critiques, en conformité avec les standards de qualité et de sécurité aérospatiaux.",
                sous_secteur5_title: "Secteur Public et Gouvernemental",
                sous_secteur5_contenu: "Nous suivons les normes strictes relatives à la manutention et au transport des produits pharmaceutiques, incluant les Bonnes Pratiques de Distribution (BPD) et les directives spécifiques à la chaîne du froid.",
                sous_secteur6_title: "Mode et Textile",
                sous_secteur6_contenu: "Pour le transport de produits électroniques sensibles, nous appliquons des normes spécifiques qui assurent la protection contre les décharges électrostatiques et les dommages mécaniques.",
                sous_secteur7_title: "Événementiel et Spectacle",
                sous_secteur7_contenu: "Nous respectons des protocoles de confidentialité et de sécurité rigoureux pour garantir la sécurité et la confidentialité des documents sensibles.",
                sous_secteur8_title: "Arts et Antiquités",
                sous_secteur8_contenu: "Les exigences spécifiques de ce secteur sont respectées, y compris le transport de matériaux et composants critiques, en conformité avec les standards de qualité et de sécurité aérospatiaux.",

                technologie_header: "Technologie à Votre Service pour une Expérience Transparente, Facile et Intuitive",
                technologie_sheader: "Chez CIT Express, nous combinons l'excellence opérationnelle avec des technologies de pointe pour offrir un service de livraison qui non seulement répond, mais excède les attentes de nos clients.",
                technologie1_title: "Fonctionnalités Technologiques Additionnelles",
                technologie2_title: "Suivi de Livraison Accessible et Transparent",
                technologie3_title: "Comptabilité Intuitive et Efficace",
                technologie_desc: "L'innovation technologique chez CIT Express est dédiée à améliorer continuellement l'expérience de nos clients, en offrant des solutions logistiques qui sont non seulement avancées, mais aussi parfaitement alignées avec leurs besoins opérationnels et financiers.",

                technologie1_tech1_title: "Interface de Commande Simplifiée",
                technologie1_tech1_desc: "Notre plateforme en ligne est conçue pour une expérience utilisateur intuitive, permettant une prise de commande rapide et sans effort.",
                technologie1_tech2_title: "Notifications et Alertes Automatiques",
                technologie1_tech2_desc: "Restez informé à chaque étape de votre livraison grâce à des notifications et mises à jour automatiques.",
                technologie1_tech3_title: "Intégration avec les Systèmes d'Entreprise",
                technologie1_tech3_desc: "Notre plateforme s'intègre sans heurt aux systèmes ERP et CRM, pour une gestion centralisée et efficace des commandes de livraison.",
                technologie1_tech4_title: "Options de Paiement Sécurisées",
                technologie1_tech4_desc: "Nous proposons diverses options de paiement, toutes sécurisées, pour répondre aux besoins de nos clients.",
                technologie1_tech5_title: "Interface de Commande Simplifiée",
                technologie1_tech5_desc: "Notre équipe d'assistance clientèle est disponible 24/7 pour répondre à toutes vos questions et résoudre rapidement tout problème.",
                technologie2_tech1_title: "Géolocalisation en Temps Réel",
                technologie2_tech1_desc: "Nos clients et les destinataires des colis peuvent suivre l'acheminement de leurs envois en temps réel grâce à notre système de géopositionnement avancé.",
                technologie2_tech2_title: "Programme de Suivi Interactif",
                technologie2_tech2_desc: "Les destinataires sont intégrés dans notre programme de suivi, leur permettant de consulter facilement l'état et l'emplacement de leur envoi, pour une transparence et une tranquillité d'esprit totales.",
                technologie3_tech1_title: "Exigence du Numéro de Job ou de PO",
                technologie3_tech1_desc: "Pour faciliter le travail de votre département comptable, notre système exige la saisie d'un numéro de job ou de bon de commande (PO) avant toute prise de commande. Cette pratique assure une allocation précise des coûts et une gestion comptable simplifiée.",
                technologie3_tech2_title: "Rapports Comptables Personnalisés et Exportables",
                technologie3_tech2_desc: "Nos clients peuvent générer et exporter des rapports comptables personnalisés, offrant une intégration fluide avec leurs propres systèmes financiers.",

                about_us: "À propos de nous",
                terms_of_use: "Conditions d'utilisation",
                privacy_policy: "Politique de confidentialité",
                accessibility_statement: "Déclaration d'accessibilité",
                all_rights_resereved: "Tous droits réservés",

                username: "Nom d'utilisateur",

                suivant: "Suivant",
                nom_entreprise: "Nom de l'entreprise?",
                secteur_activite: "Secteur d'activité?",
                contact_principale: "Contact principal (Nom et poste)?",
                address_email: "Adresse e-mail?",
                phone_number: "Numéro de téléphone",
                repondez_ici: "Répondez ici...",
                question1: "Quelle est la fréquence de vos besoins en livraison?",
                question1_choice1: "Quotidienne",
                question1_choice2: "Hebdomadaire",
                question1_choice3: "Mensuelle",
                question1_choice4: "Autre",
                question2: "Quel est le volume moyen de vos envois? (Nombre de colis ou poids approximatif par envoi)",
                question3: "Quelles sont les destinations principales de vos envois?",
                question3_choice1: "National",
                question3_choice2: "International",
                question3_choice3: "Les deux",
                question4: "Quel type de marchandises expédiez-vous?",
                question4_choice1: "Documents",
                question4_choice2: "Colis",
                question4_choice3: "Palettes",
                question4_choice4: "Marchandises spéciales",
                question5: "Y a-t-il des exigences particulières concernant vos marchandises? (Fragilité, température contrôlée, dangereuses, etc.)",
                question5_choice1: "Oui",
                question5_choice2: "Non",
                question6: "Quels services de livraison recherchez-vous?",
                question6_choice1: "Livraison standard",
                question6_choice2: "Express",
                question6_choice3: "Jour même",
                question7: "Avez-vous besoin de services supplémentaires? (Assurance, suivi en temps réel, livraison contre signature, etc.)",
                question7_choice1: "Oui",
                question7_choice2: "Non",
                question8: "Avez-vous déjà utilisé des services de livraison express?",
                question8_choice1: "Oui",
                question8_choice2: "Non",
                question9: "Quels sont vos critères principaux pour choisir un service de livraison? (Coût, fiabilité, vitesse, service client, etc.)",
                question10: "Avez-vous des attentes ou des préoccupations spécifiques concernant le service de livraison?",
                question11: "Quel est votre budget approximatif pour les services de livraison?",
                question12: "Quelles sont vos préférences en matière de facturation et de paiement?",
                thanks_message1: "Merci d'avoir demandé un devis.",
                thanks_message2: "Un de nos experts métiers traite votre demande. Vous serez contacté dans les plus brefs délais pour discuter de vos besoins de livraison et de nos solutions.",

                contactus_header: "Contactez-nous",
                contactus_sheader1: "Bienvenue chez CIT Express,experts de la livraison express",
                contactus_sheader2: "Besoin d'aide ? Contactez-nous !",
                contactus_form_input1: "Nom",
                contactus_form_input2: "Société",
                contactus_form_input3: "Téléphone",
                contactus_form_input4: "Email",
                contactus_form_input5: "Sujet",
                contactus_form_input6: "Message",
                contactus_form_button: "Envoyer",
                contactus_text1: "Numéro de téléphone dédié, pour une assistance immédiate :",
                contactus_text2: "Notre équipe est disponible",
                contactus_text3: "Pour répondre rapidement à vos préoccupations urgentes.",

                construction_text1: "Page en cours de construction",
                construction_text2: "Nous sommes désolés, mais cette page est actuellement en cours de construction.",
                construction_text3: "Revenez bientôt pour voir les mises à jour.",
                nos_service: "Nos services",
                contactus_message: "Merci, nous vous contacterons bientôt",


                secteur_medicale_title: "Secteur Médical et Pharmaceutique",
                secteur_medicale_sub_title: "Votre Partenaire de Confiance pour les Livraisons Urgentes",
                secteur_medicale_section1_title: "Services de Livraison Spécialisée pour le Secteur Médical et Pharmaceutique",
                secteur_medicale_section1_contenu: "Dans le secteur médical et pharmaceutique, la rapidité et la fiabilité des livraisons sont cruciales. Que vous ayez besoin de transporter des médicaments, des vaccins, des échantillons biologiques ou des équipements médicaux, nous sommes là pour vous offrir un service de livraison adapté à vos exigences.",
                secteur_medicale_option1_title: "1- Livraison de Médicaments et de Vaccins",
                secteur_medicale_option1_content: "Nous comprenons l'importance des médicaments et des vaccins pour la santé publique. Nos services de livraison garantissent que vos produits pharmaceutiques arrivent à destination rapidement et en parfait état. Grâce à notre système de transport à température contrôlée, nous assurons que vos médicaments et vaccins sont maintenus à la température idéale pendant toute la durée du transport.",
                secteur_medicale_option2_title: "2- Transport d'Échantillons Biologiques",
                secteur_medicale_option2_content: "Le transport d'échantillons biologiques nécessite une attention particulière et un respect strict des protocoles de sécurité. Notre équipe est formée aux meilleures pratiques de manipulation et de transport d'échantillons biologiques, garantissant ainsi leur intégrité et leur sécurité. Nous assurons également une traçabilité complète de vos envois, vous offrant une tranquillité d'esprit supplémentaire.",
                secteur_medicale_option3_title: "3- Livraison d'Équipements Médicaux",
                secteur_medicale_option3_content: "Les équipements médicaux sont essentiels pour les soins de santé. Nous offrons un service de livraison rapide et sécurisé pour tous types d'équipements médicaux, des appareils de diagnostic aux équipements chirurgicaux. Nos véhicules sont équipés pour transporter des articles de toutes tailles et nous nous engageons à livrer vos équipements dans les délais convenus.",
                secteur_medicale_option4_title: "4- Transport à Température Contrôlée",
                secteur_medicale_option4_content: "Le transport à température contrôlée est essentiel pour les articles sensibles du secteur médical et pharmaceutique. Nos véhicules sont équipés de systèmes de réfrigération et de suivi de la température, garantissant que vos produits sont maintenus à la température optimale tout au long du trajet. Que ce soit pour des produits réfrigérés, congelés ou nécessitant une température ambiante contrôlée, nous avons la solution adaptée à vos besoins.",
                secteur_medicale_option5_title: "5- Engagement envers la Qualité et la Sécurité",
                secteur_medicale_option5_content: "Nous nous engageons à fournir un service de livraison de la plus haute qualité pour le secteur médical et pharmaceutique. Notre équipe de professionnels qualifiés veille à ce que chaque envoi soit traité avec le plus grand soin et conformément aux normes de sécurité les plus strictes. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins spécifiques et fournir des solutions de livraison sur mesure.",
                secteur_medicale_option6_title: "6- Suivi et Traçabilité en Temps Réel",
                secteur_medicale_option6_content: "Avec notre système avancé de suivi et de traçabilité en temps réel, vous pouvez suivre vos envois à chaque étape du transport. Vous recevrez des mises à jour régulières sur l'état de votre livraison, vous assurant ainsi une visibilité complète et une tranquillité d'esprit.",
                secteur_medicale_section3: "Pour en savoir plus sur nos services de livraison pour le secteur médical et pharmaceutique, ou pour discuter de vos besoins spécifiques, n'hésitez pas à nous contacter. Nous sommes là pour vous fournir des solutions de livraison fiables, rapides et sécurisées, adaptées aux exigences du secteur médical.",
                secteur_medicale_section4_title: "Liste des Objets et Matériels Transportés",
                secteur_medicale_section4_op1_title: "1. Médicaments et Vaccins",
                secteur_medicale_section4_op1_point1: "Médicaments sous ordonnance",
                secteur_medicale_section4_op1_point2: "Médicaments en vente libre",
                secteur_medicale_section4_op1_point3: "Vaccins",
                secteur_medicale_section4_op1_point4: "Sérums et immunoglobulines",
                secteur_medicale_section4_op1_point5: "Médicaments réfrigérés",
                secteur_medicale_section4_op1_point6: "Médicaments sensibles à la température",
                secteur_medicale_section4_op2_title: "2. Échantillons Biologiques",
                secteur_medicale_section4_op2_point1: "Échantillons sanguins",
                secteur_medicale_section4_op2_point2: "Échantillons urinaires",
                secteur_medicale_section4_op2_point3: "Échantillons de tissus",
                secteur_medicale_section4_op2_point4: "Cultures bactériennes et virales",
                secteur_medicale_section4_op2_point5: "ADN et échantillons génétiques",
                secteur_medicale_section4_op2_point6: "Échantillons pour analyses microbiologiques",
                secteur_medicale_section4_op3_title: "3. Équipements Médicaux",
                secteur_medicale_section4_op3_point1: "Appareils de diagnostic (échographes, scanners, etc.)",
                secteur_medicale_section4_op3_point2: "Équipements chirurgicaux",
                secteur_medicale_section4_op3_point3: "Matériel de laboratoire",
                secteur_medicale_section4_op3_point4: "Dispositifs médicaux jetables (gants, seringues, etc.)",
                secteur_medicale_section4_op3_point5: "Moniteurs de patients",
                secteur_medicale_section4_op3_point6: "Défibrillateurs",
                secteur_medicale_section4_op4_title: "4. Produits Sensibles à la Température",
                secteur_medicale_section4_op4_point1: "Médicaments réfrigérés",
                secteur_medicale_section4_op4_point2: "Produits biologiques",
                secteur_medicale_section4_op4_point3: "Réactifs de laboratoire",
                secteur_medicale_section4_op4_point4: "Médicaments biologiques",
                secteur_medicale_section4_op4_point5: "Substances chimiques médicales",
                join_us_button: "Rejoignez-nous",
                secteur_medicale_join_us_text: "Rejoignez-nous dès aujourd'hui et découvrez comment CIT Express peut simplifier vos besoins. En assurant un transport sûr et efficace de vos matériaux de recherche.",

                recherche_developpment_title: "Recherche et Développement",
                recherche_developpment_sub_title: "Solutions de Transport pour vos Projets Innovants",
                recherche_developpment_section1_title: "Services de Livraison pour la Recherche et le Développement",
                recherche_developpment_section1_content: "Dans le domaine de la recherche et du développement, le transport sécurisé et fiable de matériel de recherche, d'échantillons scientifiques et de prototypes est primordial. Nous offrons des services de livraison spécialisés pour répondre aux besoins uniques de vos projets innovants.",
                recherche_developpment_option1_title: "1- Transport de Matériel de Recherche",
                recherche_developpment_option1_content: "Votre matériel de recherche est précieux et sensible. Nous garantissons un transport sécurisé et soigneux pour vos instruments et équipements de recherche, assurant qu'ils arrivent en parfait état et prêts à l'emploi.",
                recherche_developpment_option2_title: "2- Livraison d'Échantillons Scientifiques",
                recherche_developpment_option2_content: "Les échantillons scientifiques nécessitent un contrôle strict des conditions de transport. Nos services de livraison à température contrôlée maintiennent vos échantillons dans des conditions optimales, préservant leur intégrité et leur viabilité pour vos expériences et analyses.",
                recherche_developpment_option3_title: "3- Transport de Prototypes",
                recherche_developpment_option3_content: "Le transport de prototypes nécessite une manipulation minutieuse et une attention particulière. Nous offrons des solutions de livraison personnalisées pour vos prototypes, garantissant une arrivée sécurisée et sans dommages.",
                recherche_developpment_option4_title: "4- Transport à Température Contrôlée",
                recherche_developpment_option4_content: "Pour le secteur de la recherche et du développement, le transport à température contrôlée est souvent nécessaire pour maintenir la qualité des échantillons et des matériaux sensibles. Nos véhicules sont équipés de systèmes avancés de réfrigération et de surveillance de la température, assurant des conditions de transport idéales.",
                recherche_developpment_option5_title: "5- Suivi et Traçabilité en Temps Réel",
                recherche_developpment_option5_content: "Notre système de suivi et de traçabilité en temps réel vous permet de suivre vos envois à chaque étape du transport. Vous recevrez des mises à jour en temps réel sur l'état de votre livraison, assurant une transparence et une tranquillité d'esprit totales.",
                recherche_developpment_option6_title: "6- Engagement envers la Qualité et la Sécurité",
                recherche_developpment_option6_content: "Nous nous engageons à fournir un service de livraison de haute qualité pour le secteur de la recherche et du développement. Notre équipe expérimentée veille à ce que chaque envoi soit traité avec le plus grand soin et en conformité avec les normes de sécurité les plus élevées.",
                recherche_developpment_section4_op1_title: "1. Matériel de Recherche",
                recherche_developpment_section4_op1_point1: "Instruments de mesure scientifique",
                recherche_developpment_section4_op1_point2: "Équipements de laboratoire",
                recherche_developpment_section4_op1_point3: "Ordinateurs et périphériques spécialisés",
                recherche_developpment_section4_op1_point4: "Outils de calibration",
                recherche_developpment_section4_op1_point5: "Kits de recherche et développement",
                recherche_developpment_section4_op2_title: "2. Échantillons Scientifiques",
                recherche_developpment_section4_op2_point1: "Échantillons biologiques pour la recherche",
                recherche_developpment_section4_op2_point2: "Échantillons chimiques",
                recherche_developpment_section4_op2_point3: "Échantillons de matériaux",
                recherche_developpment_section4_op2_point4: "Échantillons environnementaux (sol, eau, air)",
                recherche_developpment_section4_op2_point5: "Échantillons cliniques pour essais",
                recherche_developpment_section4_op3_title: "3. Prototypes",
                recherche_developpment_section4_op3_point1: "Prototypes d'équipements médicaux",
                recherche_developpment_section4_op3_point2: "Prototypes de dispositifs de diagnostic",
                recherche_developpment_section4_op3_point3: "Prototypes de dispositifs technologiques",
                recherche_developpment_section4_op3_point4: "Modèles expérimentaux",
                recherche_developpment_section4_op3_point5: "Composants électroniques",
                recherche_developpment_section4_op4_title: "4. Produits Sensibles à la Température",
                recherche_developpment_section4_op4_point1: "Échantillons biologiques nécessitant une réfrigération",
                recherche_developpment_section4_op4_point2: "Réactifs chimiques sensibles à la température",
                recherche_developpment_section4_op4_point3: "Cultures cellulaires et tissulaires",
                recherche_developpment_section4_op4_point4: "Produits biologiques pour recherche",
                recherche_developpment_section4_op4_point5: "Solutions et médiums de culture",
                recherche_developpment_contact_us: "Pour discuter de vos besoins spécifiques en matière de transport pour la recherche et le développement, n'hésitez pas à nous contacter. Nous sommes là pour vous offrir des solutions de livraison fiables, sécurisées et adaptées à vos projets innovants",

                secteur_industrie_title: "Secteur Industrie Automotrice",
                secteur_industrie_sub_title: "Services de Livraison pour l'Industrie Automotrice",
                secteur_industrie_section1_title: "Transport Urgent de Pièces Détachées",
                secteur_industrie_section2_title: "Livraison de Composants pour la Production",
                secteur_industrie_section3_title: "Livraisons Just-In-Time pour l'Industrie Automotrice",
                secteur_industrie_section1_limit: "Transport de pièces de rechange telles que des moteurs, transmissions, systèmes de freinage, pare-chocs, phares, et autres pièces critiques.",
                secteur_industrie_section2_limit: "Transport de composants tels que des châssis, tableaux de bord, sièges, câblage électrique, et modules électroniques.",
                secteur_industrie_section3_limit: "Livraison de produits finis, semi-finis, et matières premières nécessaires pour la production automobile.",
                secteur_industrie_option1_title: "1- Rapidité et Efficacité",
                secteur_industrie_option1_content: "Livraison rapide de pièces détachées automobiles pour minimiser les temps d'arrêt.",
                secteur_industrie_option2_title: "2- Transport Sécurisé",
                secteur_industrie_option2_content: "Manutention spécialisée et transport sécurisé pour garantir que les pièces automobiles arrivent en parfait état.",
                secteur_industrie_option3_title: "1- Rapidité et Efficacité ",
                secteur_industrie_option3_content: "Livraison en temps opportun des composants de production automobile pour maintenir le bon déroulement des processus de fabrication.",
                secteur_industrie_option4_title: "2- Coordination avec les fournisseurs",
                secteur_industrie_option4_content: "Coordination avec les fournisseurs pour garantir que les composants sont livrés exactement au moment nécessaire, améliorant ainsi l'efficacité de la chaîne d'approvisionnement automobile.",
                secteur_industrie_option5_title: "1- Rapidité et Efficacité ",
                secteur_industrie_option5_content: "Timing précis des livraisons just-in-time pour maintenir la continuité de la chaîne de production automobile.",
                secteur_industrie_option6_title: "2- Suivi en temps réel des livraisons",
                secteur_industrie_option6_content: "Suivi en temps réel des livraisons et mises à jour pour assurer que les pièces et composants automobiles arrivent selon le calendrier prévu.",
                secteur_industrie_join_us: "Ces services de transport et livraison pour l'industrie automobile garantissent que les processus de production restent ininterrompus, contribuant ainsi à l'efficacité opérationnelle et aux économies de coûts pour les entreprises du secteur automobile.",

                consultance_formation_title: "Consultance et Formation",
                consultance_formation_sub_title: "Soutien Logistique Rapide et Fiable pour vos Activités de Conseil et Formation",
                consultance_formation_section1_title: "Livraison Express pour les Professionnels du Conseil et de la Formation",
                consultance_formation_section1_contenu: "Dans le domaine de la consultance et de la formation, la rapidité et la fiabilité des livraisons sont cruciales pour le succès de vos missions. Conscients de l’importance de chaque document, matériel de présentation, et équipement pour audits ou évaluations, nous nous engageons à offrir des services de livraison rapides et sûrs pour répondre à vos besoins professionnels.",
                consultance_formation_section2_title: "Nos services incluent:",
                consultance_formation_option1_title: "1- Livraison Express de Documents Importants",
                consultance_formation_option1_content: "Que ce soit des contrats, des rapports d’audit ou des supports de formation, nous garantissons une livraison rapide et sécurisée pour que vous ne perdiez jamais un instant.",
                consultance_formation_option2_title: "2- Matériel de Présentation et de Formation",
                consultance_formation_option2_content: "Envois rapides de projecteurs, ordinateurs portables, et autres équipements nécessaires pour vos sessions de formation ou de présentation.",
                consultance_formation_option3_title: "3- Équipements pour Audits et Évaluations",
                consultance_formation_option3_content: "Livraison fiable d’équipements spécifiques pour vos missions d’audit, garantissant que vous disposez toujours du matériel nécessaire à temps.",
                consultance_formation_section3_title: "Pourquoi choisir nos services?",
                consultance_formation_option4_title: "1- Rapidité et Efficacité",
                consultance_formation_option4_content: "Nos services sont conçus pour offrir les délais de livraison les plus courts possibles, afin que vous puissiez respecter vos plannings serrés",
                consultance_formation_option5_title: "2- Fiabilité",
                consultance_formation_option5_content: "Avec notre suivi en temps réel, vous pouvez toujours savoir où se trouve votre envoi et quand il arrivera. Nos chauffeurs, véritables ambassadeurs de notre service, sont formés pour être polis, propres et amicaux, garantissant ainsi une expérience positive à chaque livraison.",
                consultance_formation_option6_title: "3- Expérience Terrain",
                consultance_formation_option6_content: "Forts de nombreuses années d'expérience, nos chauffeurs connaissent parfaitement les exigences du secteur de la consultance et de la formation. Leur expertise terrain assure une livraison sans faille, même dans les situations les plus complexes.",
                consultance_formation_option7_title: "4- Service Client Dédié",
                consultance_formation_option7_content: "Notre équipe est disponible 24/7 pour répondre à vos questions et résoudre tout problème éventuel.",
                consultance_formation_option8_title: "5- Traçabilité et Sécurité",
                consultance_formation_option8_content: "Chaque livraison est tracée en temps réel, vous permettant de suivre votre colis du point d'envoi à sa destination finale.",
                consultance_formation_section4_title: "Plateforme Intégrée pour Répondre à Tous Vos Besoins :",
                consultance_formation_section4_content: "Nous proposons une plateforme conviviale et complète qui répond à l'ensemble de vos désirs, qu'il s'agisse de :",
                consultance_formation_box_option1_title: "Prise de Commandes",
                consultance_formation_box_option1_contenu: "Processus simplifié pour passer vos commandes de livraison, avec des options personnalisables selon vos besoins spécifiques.",
                consultance_formation_box_option2_title: "Suivi des Livraisons",
                consultance_formation_box_option2_contenu: "Suivi en temps réel de vos envois, avec des notifications à chaque étape du processus de livraison.",
                consultance_formation_box_option3_title: "Tarification Transparente",
                consultance_formation_box_option3_contenu: "Choisissez entre différents types de services (économiques ou rapides) en fonction de vos priorités et de votre budget.",
                consultance_formation_box_option4_title: "Facturation Personnalisée",
                consultance_formation_box_option4_contenu: "Pour les départements nécessitant des bons de commande (PO) ou des numéros de job pour la ventilation comptable, notre système de facturation est conçu pour s'adapter à vos besoins administratifs et financiers.",
                consultance_formation_join_us_text: "Confiez-nous vos livraisons et concentrez-vous sur ce qui compte le plus : la réussite de vos projets de consultance et de formation.",

                technologie_electronique_title: "Technologie et Électronique",
                technologie_electronique_sub_title: "Livraison de Composants Électroniques Sensibles et Équipements Informatiques",
                technologie_electronique_section1_title: "Votre Partenaire de Confiance en Livraison pour le Secteur Technologique depuis 1996",
                technologie_electronique_section1_contenu: "Depuis 1996, CIt Express s'engage à fournir des solutions de livraison rapide, sécurisée et fiable pour le secteur de la technologie et de l'électronique. Nous comprenons les exigences uniques de ce secteur et nous nous engageons à offrir un service de livraison de première classe pour vos composants électroniques et équipements informatiques.",
                technologie_electronique_section2_title: "Nos Services de Livraison pour le Secteur Technologique et Électronique",
                technologie_electronique_section2_title: "Pourquoi Choisir CIT Express?",
                technologie_electronique_box_option1_title: "Manutention Soignée",
                technologie_electronique_box_option1_contenu: "Chez CIt Express, nous assurons un traitement minutieux des composants électroniques et des équipements informatiques fragiles grâce à une équipe formée et à des emballages spécialement conçus pour les protéger durant le transport.",
                technologie_electronique_box_option2_title: "Transport Sécurisé",
                technologie_electronique_box_option2_contenu: "La sécurité de vos envois est notre priorité. Nos véhicules avec suspension avancée minimisent les risques de dommages, et notre suivi en temps réel vous permet de surveiller chaque étape du transport.",
                technologie_electronique_box_option3_title: "Rapidité et Fiabilité",
                technologie_electronique_box_option3_contenu: "Dans le secteur technologique, le temps est crucial. CIt Express propose des livraisons express, le jour même ou le lendemain, pour garantir que vos équipements arrivent dans les délais.",
                technologie_electronique_box_option4_title: "Services Personnalisés",
                technologie_electronique_box_option4_contenu: "Chaque envoi est unique, c'est pourquoi nous offrons des services personnalisés. Que ce soit pour des livraisons régulières ou ponctuelles, notre équipe créera une solution de livraison sur mesure pour votre entreprise.",
                technologie_electronique_option1_title: "1- Expertise",
                technologie_electronique_option1_content: "Une équipe expérimentée et formée spécifiquement pour la manutention de composants électroniques sensibles.",
                technologie_electronique_option2_title: "2- Sécurité ",
                technologie_electronique_option2_content: "Utilisation de véhicules et d'emballages spécialement conçus pour protéger vos envois.",
                technologie_electronique_option3_title: "3- Suivi en Temps Réel ",
                technologie_electronique_option3_content: "Suivi complet de vos livraisons pour une tranquillité d'esprit totale.",
                technologie_electronique_option4_title: "4- Rapidité ",
                technologie_electronique_option4_content: "Options de livraison express pour répondre aux besoins urgents de votre entreprise.",
                technologie_electronique_option5_title: "5- Flexibilité  ",
                technologie_electronique_option5_content: "Solutions personnalisées adaptées aux exigences uniques de votre activité",
                technologie_electronique_join_us_text1: "Pour plus d'informations sur nos services de livraison dans le secteur de la technologie et de l'électronique, ou pour demander un devis personnalisé, n'hésitez pas à nous contacter. Nous sommes là pour assurer que vos composants électroniques et équipements informatiques arrivent à destination en toute sécurité et dans les délais.",
                technologie_electronique_join_us_text2: "Contactez-nous dès aujourd'hui et découvrez comment Cit Express peut devenir votre partenaire de confiance pour toutes vos nécessités de livraison technologique et électronique.",

                commerce_title: "Commerce de Détail et E-commerce",
                commerce_section1_title: "Optimisez votre commerce en ligne avec Cit Express",
                commerce_option1_title: "1- Livraison Rapide et Économique",
                commerce_option1_content: "Garantissez à vos clients une livraison rapide et abordable pour améliorer leur expérience d'achat et augmenter la satisfaction client. Nos solutions de livraison sont conçues pour être efficaces et rentables, vous permettant de rester compétitif sur le marché du commerce en ligne.",
                commerce_option2_title: "2- Possibilité de Synchronisation via API",
                commerce_option2_content: " Intégrez facilement nos services avec votre programme grâce à nos API robustes et flexibles. Cette intégration assure une gestion fluide et automatisée des stocks, des commandes et des livraisons en temps réel, minimisant les erreurs et maximisant l'efficacité.",
                commerce_option3_title: "3- Gestion de Retours avec Remboursement",
                commerce_option3_content: "Offrez à vos clients une option de retour avec remboursement, simplifiant ainsi le processus de retour et renforçant leur confiance dans votre service. Notre gestion efficace des retours réduit les délais et les coûts associés, tout en maintenant un haut niveau de satisfaction client.",
                commerce_option4_title: "4- Service Client Réactif",
                commerce_option4_content: "Profitez d'un support client performant et disponible pour répondre rapidement aux questions et résoudre les problèmes. Un service client réactif est essentiel pour fidéliser vos clients et renforcer leur confiance dans votre marque.",
                commerce_join_us_text: "Avec CIt Express, vous pouvez transformer votre activité de commerce de détail et d'e-commerce en assurant des services de livraison et de gestion optimisés, économiques et orientés client. Nos solutions sur mesure sont conçues pour répondre aux besoins spécifiques de votre entreprise, garantissant ainsi une expérience d'achat exceptionnelle pour vos clients. Faites confiance à CIt Express pour dynamiser votre commerce en ligne et augmenter votre compétitivité sur le marché."











            }
        }
    }
}
